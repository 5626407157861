/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CardAddProps } from "@kanpla/services";
import {
  callInternalApi,
  getErrorMessage,
  setNativeKeyboardOffset,
  tx,
  useT,
} from "@kanpla/system";
import { message } from "@kanpla/ui";
import Script from "next/script";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSessionstorageState, useWindowSize } from "rooks";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";

interface Props {
  children: any;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  onSuccess?: (p?: any) => void | Promise<void>;
}

const CardAddButtonWrapper = (props: Props) => {
  const {
    children,
    loading = false,
    setLoading = () => null,
    onSuccess,
  } = props;
  const t = useT();
  const { loadCards, userId, paymentGatewayId } = useContainer(OrderingContext);
  const [windowLoaded, setWindowLoaded] = useSessionstorageState(
    "reepay-window-loaded",
    false
  );
  const { innerWidth } = useWindowSize();

  // Load default card (using PensoPay subscription ID)
  // manage the old card system also
  useEffect(() => {
    if (windowLoaded) return;
    setNativeKeyboardOffset(-220);
    loadReepayWindow();
    setLoading(false);

    return () => {
      setWindowLoaded(false);
      setLoading(false);
      setNativeKeyboardOffset(-90);
    };
  }, []);

  const loadReepayWindow = async () => {
    try {
      const publicKey: string = await callInternalApi(
        "payment/getPublicToken",
        {
          paymentGatewayId,
          userId,
        }
      );

      // @ts-ignore
      window.reepayAddCardHandler = reepaytoken.configure({
        key: publicKey,
        language: tx.currentLocale,
        buttonText: t("Add the card"),
        token: ({ token }: { token: string }) => {
          submitCardToReepay(token);
        },
        close: () => setLoading(false),
      });

      setWindowLoaded(true);

      try {
        document.querySelectorAll("#reepay-token-iframe").forEach((e) => {
          (e as HTMLElement).style.visibility = "hidden";

          if (innerWidth && innerWidth < 421) {
            (e as HTMLElement).style.paddingTop =
              "calc(var(--status-bar-height) + 0.75rem)";
            (e as HTMLElement).style.backgroundColor = "white";
          }
        });
      } catch (err) {
        console.log(err);
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage);
      console.error(errorMessage);
    }
  };

  const submitCardToReepay = async (token: string) => {
    try {
      await callInternalApi<CardAddProps, null>("payment/cardAdd", {
        cardToken: token,
        userId,
        paymentGatewayId,
      });

      loadCards();

      message.success(t("Card added"));
      onSuccess && onSuccess();
    } catch (err) {
      message.error(t("Payment failed"));
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        disabled: loading,
        onClick: async () => {
          setLoading(true);
          // @ts-ignore
          window?.reepayAddCardHandler?.open();
          setNativeKeyboardOffset(-220);
          setLoading(false);
        },
      })}
      <Script
        id="reepay-token-load"
        src="https://token.reepay.com/token.js"
        onLoad={async () => {
          setLoading(true);
          await loadReepayWindow();
          setLoading(false);
        }}
      />
    </>
  );
};

export default CardAddButtonWrapper;
