import { OrderLines } from "@kanpla/types";
import { atom, useSetAtom } from "jotai";
import { sortBy } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { openBasketAtom } from "../../shared/basket/useBasket";

export interface SelectedCategoryData {
  index: number;
  trusted?: boolean;
}

export const kanplaGoReceiptOpenAtom = atom(false);
export const receiptTimeAtom = atom<number>(moment().unix());
export const checkoutItemsAtom = atom<OrderLines>([]);

export const useKanplaGoListener = () => {
  const { activePlugins, orders } = useContainer(OrderingContext);

  const setReceiptOpen = useSetAtom(kanplaGoReceiptOpenAtom);
  const setReceiptTime = useSetAtom(receiptTimeAtom);
  const setCheckoutItems = useSetAtom(checkoutItemsAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);

  // KANPLA GO LISTENER TEMP
  const retrieveAndSubmitOrder = async () => {
    if (!activePlugins["kanplaGo"]) return;
    const sorted = sortBy(orders, (o) => -o?.updatedAtSeconds || -Infinity);
    const latestOrder = sorted?.[0];
    if (!latestOrder) return;

    const secondsNow = moment().unix();
    const timeoutInSeconds = 60 * 2;
    const expired =
      latestOrder.updatedAtSeconds + timeoutInSeconds < secondsNow;
    if (expired) return;

    setOpenBasket(false);
    setReceiptTime(latestOrder.createdAtSeconds);
    setCheckoutItems(latestOrder.orderLines);
    setReceiptOpen(true);
  };
  useEffect(() => {
    retrieveAndSubmitOrder();
  }, [orders?.map((p) => p.id).join(",")]);
};
