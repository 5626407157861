import {
  TIMEZONE_DEFAULT,
  Timestamp,
  getTodayTimestamp,
  isDatePastDeadline,
} from "@kanpla/system";
import {
  CombinedOfferItem,
  LoadOfferReturn,
  Partner,
  _FrontendModule,
} from "@kanpla/types";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { uniq } from "lodash";
import { useEffect } from "react";
import { isOrderingFromBackendAtom } from "../../context";

export const deadlineInfoAtom = atom({
  deadline: 0,
  deadlineWeekRelative: false,
  deadlineExcludingWeekends: false,
  includesWeekend: false,
  defaultDateSeconds: null as number | null,
  isDateSecondsPastDeadline: (_dateSeconds: number) => false as boolean,
  isDateSecondsPastIndividualDeadline: (_dateSeconds: number) =>
    false as boolean,
  deadlineSoft: 0,
  timezone: TIMEZONE_DEFAULT,
  latestIndividualDeadline: 0,
});

export const useDeadlineInfo = ({
  offer,
  module,
  timezone: partnerTimezone,
}: {
  offer: LoadOfferReturn;
  module: _FrontendModule;
  timezone: Partner["timezone"];
}) => {
  const setDeadlineInfo = useSetAtom(deadlineInfoAtom);
  const isOrderingFromBackend = useAtomValue(isOrderingFromBackendAtom);

  // 1. Deconstruct key deadline parameters from offer and module
  const {
    deadline: originalDeadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    deadlineSoft,
    latestIndividualDeadline,
  } = offer?.deadlineInfo || {};
  const includesWeekend = module?.plugins?.sevenDayWeek?.active || false;
  const todayMidnightSeconds = getTodayTimestamp({ Timestamp })?.seconds || 0;

  // 1.1 Determine timezone
  const timezone = partnerTimezone || TIMEZONE_DEFAULT;

  // 1.2 Determine deadline based on frontend/backend
  const deadline = isOrderingFromBackend
    ? originalDeadline + 604800 // Extend deadline by 1 week for backend ordering
    : originalDeadline;

  // 2. Created derived properties
  const isDateSecondsPastDeadline = (customDateSeconds: number) =>
    isDatePastDeadline({
      date: new Timestamp(customDateSeconds, 0),
      deadline,
      deadlineWeekRelative,
      deadlineExcludingWeekends,
      includesWeekend,
      timezone,
    });

  const isDateSecondsPastIndividualDeadline = (customDateSeconds: number) =>
    isDatePastDeadline({
      date: new Timestamp(customDateSeconds, 0),
      deadline: latestIndividualDeadline,
      deadlineWeekRelative,
      deadlineExcludingWeekends,
      includesWeekend,
      timezone,
    });

  // TODO: Move to API call!
  const defaultDateSecondsStart = (
    (offer?.items as Array<CombinedOfferItem>) || []
  )
    ?.map((item) =>
      Object.keys(
        Object.fromEntries(
          Object.entries(item.dates || {}).filter(
            ([, value]) => value.available
          )
        )
      )
    )
    ?.flat()
    ?.map((d) => parseInt(d as string))
    ?.flat()
    ?.sort();

  const defaultDateSecondsArray =
    uniq(defaultDateSecondsStart)?.filter(
      (date) =>
        date >= todayMidnightSeconds &&
        (!isDateSecondsPastDeadline(date) ||
          !isDateSecondsPastIndividualDeadline(date))
    ) || null;

  const defaultDateSeconds = Math.min(...defaultDateSecondsArray);

  useEffect(() => {
    // 3. Update the atom
    setDeadlineInfo({
      deadline,
      deadlineWeekRelative,
      deadlineExcludingWeekends,
      includesWeekend,
      defaultDateSeconds:
        defaultDateSeconds === Infinity ? null : defaultDateSeconds,
      isDateSecondsPastDeadline,
      isDateSecondsPastIndividualDeadline,
      timezone,
      deadlineSoft,
      latestIndividualDeadline,
    });
  }, [
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    defaultDateSeconds,
    deadlineSoft,
    includesWeekend,
    timezone,
    latestIndividualDeadline,
  ]);
};
