import { LoadFrontendProps, LoadFrontendReturn } from "@kanpla/services";
import { Timestamp, delayPromise, useFetch } from "@kanpla/system";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type Props = {
  userId: string;
  schoolId?: string;
  childId?: string;
  url?: string;
  language?: string;
};

interface FallBackDataProps extends LoadFrontendReturn {
  loading: boolean;
}

const fallbackData: FallBackDataProps = {
  allowMobilePay: false,
  supplier: null,
  user: null,
  isSuperadmin: false,
  card: null,
  cards: [],
  children: [],
  // schools: [],
  /** Currently active */
  child: null,
  /** Currently active */
  school: null,
  /** Currently active */
  allModules: [],
  /** Currently active */
  modules: [],
  /** Currently active */
  offers: {},
  loading: true,
  paymentGatewayId: null,
};

export const useLoadFrontend = ({
  userId,
  schoolId,
  childId: selectedChildId,
  url,
  language,
}: Props) => {
  const [initialLoading, setInitialLoading] = useState(true);

  // Load frontend data only if the user is really logged in (both backend & frontend)
  const router = useRouter();
  const userLoggedIn =
    router?.asPath?.includes("/app") || router?.asPath?.includes("/dashboard");

  const [_reloader, setReloader] = useState(0);

  // Fetch
  const {
    data,
    isValidating,
    setData,
    loading: loadFrontendLoading,
  } = useFetch<LoadFrontendProps, LoadFrontendReturn>(
    "load/frontend",
    {
      userId: (userLoggedIn && userId) || null,
      selectedChildId:
        selectedChildId === "undefined" || !userLoggedIn
          ? undefined
          : selectedChildId,
      schoolId,
      url: url || "",
      _reloader,
      language,
    },
    {
      fallbackData,
      refreshInterval: 60000,
      onErrorRetry: (_, __, ___, revalidate, { retryCount }) => {
        if (retryCount > 5) {
          setTimeout(() => revalidate({ retryCount }), 15000);
          return;
        }
        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );
  const state = data || fallbackData;
  const loading =
    (isValidating && loadFrontendLoading && !data?.modules?.length) ||
    (data as FallBackDataProps)?.loading ||
    false;

  useEffect(() => {
    if (loading) return;
    setInitialLoading(false);
  }, [loading]);

  // Load offer
  const getOffer = (moduleId: string) => {
    const offer = state?.offers?.[moduleId];

    if (!offer) {
      return {
        items: [],
        holidayDates: {},
        deadlineInfo: {
          deadline: 27000,
          deadlineSoft: 27000,
          deadlineWeekRelative: false,
          deadlineExcludingWeekends: false,
          defaultDateSeconds: null,
          defaultSoftDateSeconds: null,
        },
        mealOptions: [],
      };
    }

    return {
      ...offer,
      deadlineInfo: {
        ...offer.deadlineInfo,
        defaultDate: new Timestamp(offer.deadlineInfo.defaultDateSeconds, 0),
        defaultSoftDate: new Timestamp(
          offer.deadlineInfo.defaultSoftDateSeconds,
          0
        ),
      },
    };
  };

  // Derived values
  const resultingSchoolId = state?.school?.id;
  const childId = state?.child?.id;
  const groupName = state?.child?.groupName;
  const { partnerId = null } = state?.school || {};
  const innerAppLoading = loading;

  /** Triggers "load/frontend" after given `delayMs` */
  const triggerReload = async (delayMs = 0) => {
    await delayPromise(delayMs);
    setReloader((r) => r + 1);
  };

  return {
    ...state,
    schoolId: resultingSchoolId,
    childId,
    groupName,
    partnerId,
    innerAppLoading,
    getOffer,
    loading,
    initialLoading,
    // triggerReload: reload,
    triggerReload,
    setData,
  };
};
