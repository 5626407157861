import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { PaymentProvider } from "@kanpla/types";
import React from "react";

interface RowProps {
  name: string;
  image?: string;
  imageComponent?: React.ReactChild;
  value: PaymentProvider;
  selected: PaymentProvider;
  onChange: (value: PaymentProvider) => void;
  detail?: string;
}

const PaymentRow = (props: RowProps) => {
  const {
    name,
    image,
    imageComponent,
    value,
    selected,
    onChange,
    detail = "",
  } = props;

  const isActive = selected === value;
  const t = useT();

  return (
    <div
      className={`w-full flex justify-between items-center border-b h-16 py-2 border-divider-main hover:opacity-80 ${
        !isActive ? "cursor-pointer" : ""
      }`}
      onClick={() => onChange(value)}
    >
      <div className="flex items-center">
        {image && !imageComponent && (
          <img
            src={`/images/${image}.png`}
            alt={t("Select {name}", { name: name })}
            className="w-10"
          />
        )}
        {imageComponent && (
          <span className="w-10 block text-text-primary">{imageComponent}</span>
        )}
        <div className="flex flex-col ml-4">
          <h1 className="text-lg font-semibold text-text-primary">{name}</h1>
          {detail && (
            <span className="text-text-secondary text-xs">{detail}</span>
          )}
        </div>
      </div>
      {isActive && (
        <div className="w-8 h-8 bg-background-secondary rounded-full p-2 flex justify-center items-center pop">
          <FontAwesomeIcon icon={faCheck} className="text-primary-dark" />
        </div>
      )}
    </div>
  );
};

export default PaymentRow;
