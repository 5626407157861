import { tx, txPrint } from "@kanpla/system";
import { Supplier, _FrontendSupplier } from "@kanpla/types";
import { AttributeData, attributesData } from "./attributesData";

export type GetAttributesProps = {
  supplier?: Supplier | _FrontendSupplier | null;
  /** Optional, to also get inactive values */
  getAll?: boolean;
  /** Optional to get localized name */
  language?: string;
};

/** Takes in supplier's customization, and returns a filtered list of attributes with adjusted values by the customization */

export const getAttributes = ({
  supplier,
  getAll = false,
  language,
}: GetAttributesProps) => {
  const customData = attributesData.map((defaultData) => {
    const { key, type, names } = defaultData;
    const customizationData: Partial<AttributeData> =
      supplier?.custom?.rules?.[type]?.[key];

    // Currently supporting 'da' | 'en' | 'no | 'nl'
    const strippedCode =
      language?.substring(0, 2) ||
      txPrint?.currentLocale ||
      tx.getCurrentLocale()?.substring(0, 2);
    const supportedLanguages = ["da", "en", "no", "nl", "sv"];
    const strippedCodeValid = supportedLanguages.includes(strippedCode);
    const finalCode = strippedCodeValid ? strippedCode : "da";
    const name: string = names?.[finalCode] || key;

    // Merge custom and default data
    const finalValue = {
      ...defaultData,
      ...customizationData,
      backendInputCategory: customizationData?.backendInputCategory?.length
        ? customizationData?.backendInputCategory
        : defaultData?.backendInputCategory,
      frontendDisplayMode:
        customizationData?.frontendDisplayMode ||
        defaultData?.frontendDisplayMode,
      name,
    };

    return finalValue;
  });

  // Assign parental and other relational information
  const customDataWithRelations = customData.map((data) => {
    if (!data?.relations?.backendUIParent) return data;

    const parent = customData?.find(
      (d) => d.key === data?.relations?.backendUIParent
    );

    return {
      ...parent,
      ...data,
      backendInputCategory: data?.backendInputCategory?.length
        ? data?.backendInputCategory
        : parent?.backendInputCategory,
      frontendDisplayMode:
        data?.frontendDisplayMode || parent?.frontendDisplayMode,
    };
  });

  const onlyActive = customDataWithRelations.filter((value) => value.active);

  return getAll ? customDataWithRelations : onlyActive;
};
