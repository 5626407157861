/* eslint-disable react-hooks-unreliable-deps/reference-deps */
import { useHasPublicSchools } from "apps/frontend/lib/signup/useHasPublicSchools";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { StringParam, useQueryParam } from "next-query-params";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";
import { throughInvitationAtom } from "..";
import { ViewPages, currentPageAtom } from "../lib/usePageNavigation";
import { onlyCompanySignupAtom } from "../lib/useSubmitEmail";
import AccessCode from "./partials/AccessCode";
import AccountAlreadyExists from "./partials/AccountAlreadyExists";
import AddChild from "./partials/AddChild";
import CanteenId from "./partials/CanteenId";
import ChooseAllergens from "./partials/ChooseAllergens";
import CompanyNotExisting from "./partials/CompanyNotExisting";
import Completed from "./partials/Completed";
import Email from "./partials/Email";
import EmailCode from "./partials/EmailCode";
import KanplaCard from "./partials/KanplaCard";
import Provider from "./partials/Provider";
import Reference from "./partials/Reference";
import Register from "./partials/Register";
import Salesplace from "./partials/Salesplace";
import SchoolOrCompany from "./partials/SchoolOrCompany";

type ScreenSection = {
  [key in ViewPages]: ReactNode;
};

const CurrentView = () => {
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
  const setOnlyCompanySignup = useSetAtom(onlyCompanySignupAtom);

  const throughInvitation = useAtomValue(throughInvitationAtom);

  /** Current View */
  const [queryPage, setQueryPage] = useQueryParam("step", StringParam);
  const router = useRouter();

  useEffect(() => {
    // Await the router to be ready
    if (!router.isReady) return;

    // Ignore resetting if query page is empty
    if (!queryPage) return;

    if (queryPage !== currentPage) {
      setCurrentPage(queryPage as ViewPages);
    }
  }, [queryPage, setCurrentPage, router.isReady]);

  useEffect(() => {
    // Await the router to be ready
    if (!router.isReady) return;

    // Set current if empty
    if (!queryPage) setQueryPage(currentPage);

    if (queryPage !== currentPage) {
      setQueryPage(currentPage);
    }
  }, [currentPage, setQueryPage, router.isReady]);

  const { hasPublicSchools, hasSchoolSwitch, hasSchoolList } =
    useHasPublicSchools();

  useEffect(() => {
    if (throughInvitation) return;

    if (
      hasPublicSchools === null ||
      hasSchoolSwitch === null ||
      hasSchoolList === null
    )
      return;

    setOnlyCompanySignup(!hasPublicSchools && !hasSchoolSwitch);

    const shouldChooseSchool = hasSchoolList ? "salesplace" : "email";
    const hasSchoolOrCompany = hasSchoolSwitch
      ? "schoolOrCompany"
      : shouldChooseSchool;

    setCurrentPage(hasSchoolOrCompany);
  }, [hasPublicSchools, hasSchoolSwitch, hasSchoolList]);

  const allScreens: ScreenSection = {
    schoolOrCompany: <SchoolOrCompany />,
    salesplace: <Salesplace />,
    email: <Email />,
    emailCode: <EmailCode />,
    /** Out of flow */
    accountAlreadyExists: <AccountAlreadyExists />,
    /** Out of flow */
    companyNotExisting: <CompanyNotExisting />,
    /** Out of flow */
    provider: <Provider />,
    canteenId: <CanteenId />,
    user: <Register />,
    addChild: <AddChild />,
    chooseAllergens: <ChooseAllergens />,
    accessCodes: <AccessCode />,
    kanplaCard: <KanplaCard />,
    reference: <Reference />,
    completed: <Completed />,
  };

  return <div className="h-full w-full">{allScreens?.[currentPage]}</div>;
};

export default CurrentView;
