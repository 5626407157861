import { Module, OrderLines } from "@kanpla/types";
import { calculateOrderTotal } from "./calculateOrderTotal";

/**
 * calculateOrderTotalByModule displays price inclusive/exclusive VAT based on the module payment method.
 */
export const calculateOrderTotalByModule = (
  orderLines: OrderLines | undefined = [],
  module: Pick<Module, "paymentMethod" | "type">
) => {
  /**
   * Don't show price incl. VAT in modules with billing
   * Due to data structure in subscriptions, unitPrice there is incl. VAT
   */
  const showInclusiveVat =
    module?.paymentMethod !== "billing" && module?.type !== "subscription";

  return calculateOrderTotal(orderLines, showInclusiveVat);
};
