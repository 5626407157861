import { OrderingContext } from "@kanpla/ordering";
import { useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";

const RefillNotification = () => {
  const t = useT();
  const { user, balance } = useContainer(OrderingContext);

  const [processing, setProcessing] = useState(false);
  const [wasRefilling, setWasRefilling] = useState(false);

  useEffect(() => {
    if (!user) return;

    const isRefilling = !!user.refilling;

    const now = Date.now() / 1000;
    const triggeredRecently =
      user.refillLast && now - user.refillLast.seconds < 600; // if less than 10 minutes since

    setProcessing(isRefilling && triggeredRecently);

    // When stopped refilling...
    if (wasRefilling && !isRefilling) {
      if (user["refillError"] && now - user["refillError"].seconds < 15) {
        // Display payment error
        message.error(t("Payment failed"));
      } else {
        // Display success message
        message.success(t("Recharge was successful"));
      }
    }

    setWasRefilling(isRefilling);
  }, [user, balance]);

  useEffect(() => {
    if (processing === true) {
      message.loading(t("Recharging processed"));
    } else if (processing === false) {
      message.dismiss();
    }
  }, [processing]);

  return null;
};

export default RefillNotification;
