import {
  callInternalApi,
  getErrorMessage,
  KanplaError,
  useT,
} from "@kanpla/system";
import { Scope } from "@kanpla/types";
import { Form, message } from "@kanpla/ui";
import {
  GetSchoolOptions,
  GetSchoolResponse,
} from "apps/frontend/pages/api/internal/signup/school";
import { atom, useSetAtom } from "jotai";
import dynamic from "next/dynamic";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../../contextProvider";
import { currentPageAtom } from "../../lib/usePageNavigation";
import { schoolIdAtom } from "../../lib/useSchool";
import { onlyCompanySignupAtom } from "../../lib/useSubmitEmail";
import PageWrapper from "../../PageWrapper";

const ReactCodeInput = dynamic(() =>
  import("react-code-input").catch(() => {
    return () => <p>Failed to load</p>;
  })
);

export const scopeAtom = atom<Scope | false>(false);

const CanteenId = () => {
  const t = useT();

  const { supplier } = useContainer(AppContext);

  const setSchoolId = useSetAtom(schoolIdAtom);
  const setScope = useSetAtom(scopeAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);
  const setOnlyCompanySignup = useSetAtom(onlyCompanySignupAtom);

  const [loading, setLoading] = useState(false);

  const submit = async ({ code }: { code: string }) => {
    try {
      setLoading(true);
      const partnerId = supplier?.partnerId;

      const { schoolId, scope } = await callInternalApi<
        GetSchoolOptions,
        GetSchoolResponse
      >("signup/school", {
        partnerId,
        code,
      });

      if (!schoolId)
        throw new KanplaError("kanpla/wrong-canteen-id", "Wrong canteen ID");

      if (scope) setScope(scope);

      setSchoolId(schoolId);
      setCurrentPage("email");
      setOnlyCompanySignup(false);

      message.success(t("Codes are correct"));
    } catch (e) {
      console.error(e);
      message.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title={t("Enter your Canteen ID")}
      subtitle={t(
        "You can find your Canteen ID physically in the canteen or by asking the canteen or your employer"
      )}
      buttonTitle={t("Confirm")}
      onButtonSubmit={submit}
      buttonLoading={loading}
      showBackButton
      stayOnPageAfterSubmit
    >
      <p className="text-center leading-6 pt-2">
        {t("Enter the 4-digit code here:")}
      </p>
      <div className="pb-6 pt-6 flex w-full justify-center">
        <Form.Item
          name="code"
          className="w-full"
          rules={{
            required: t("Please enter the code"),
            minLength: {
              value: 4,
              message: t("The code has to be 4 digits long"),
            },
          }}
        >
          <ReactCodeInput
            type="text"
            className="react-code-input-wrapper"
            fields={4}
            name="canteenIdCode"
            autoFocus
            inputMode="latin"
          />
        </Form.Item>
      </div>
    </PageWrapper>
  );
};

export default CanteenId;
