import {
  addWeek,
  getCurrentWeek,
  getDayIndexFromSeconds,
  getTodaySeconds,
  getWeekArray,
  getWeekSeconds,
  Timestamp,
  useCalendarFormatter,
} from "@kanpla/system";
import {
  _FrontendModule,
  DayIndex,
  Module,
  TimeNavigation,
  Timestamp as TimestampType,
  Week,
} from "@kanpla/types";
import moment, { Moment } from "moment";
import { NumberParam, useQueryParam } from "next-query-params";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

type SetDateTimestamp = {
  dateTimestamp: TimestampType;
  momentDate?: never;
  dateSeconds?: never;
  week?: never;
  dayIndex?: never;
};
type SetDateMoment = {
  momentDate: Moment;
  dateTimestamp?: never;
  dateSeconds?: never;
  week?: never;
  dayIndex?: never;
};
type SetDateSecondsType = {
  dateSeconds: number;
  dateTimestamp?: never;
  momentDate?: never;
  week?: never;
  dayIndex?: never;
};
type SetWeekAndDayIndex = {
  week?: Week;
  dayIndex?: DayIndex;
  dateTimestamp?: never;
  momentDate?: never;
  dateSeconds?: never;
};

type SetDateArgs =
  | SetDateTimestamp
  | SetDateMoment
  | SetDateSecondsType
  | SetWeekAndDayIndex;

export const UseTimeNavigation = (module: _FrontendModule | Module | null) => {
  // router and query params
  const router = useRouter();
  const [paramDateSeconds, setParamDateSeconds] = useQueryParam(
    "date",
    NumberParam
  );

  // includeWeekend plugin
  const includeWeekend = module?.plugins?.sevenDayWeek?.active;

  // state
  const [timeNavigation, setTimeNavigation] =
    useState<TimeNavigation>("todaySwitch");
  const todayIndex = (moment().day() - 1) as DayIndex;

  const currentWeek = getCurrentWeek({ Timestamp, includeWeekend });
  const isWeekend = !includeWeekend && ![0, 1, 2, 3, 4].includes(todayIndex);

  const [weekState, setWeek] = useState<Week>(
    isWeekend ? addWeek(currentWeek) : currentWeek
  );

  useEffect(() => {
    // If it's the current week and the length of it is the same, ignore
    if (
      weekState?.[0]?.seconds === currentWeek?.[0]?.seconds &&
      weekState?.length === currentWeek?.length
    )
      return;

    if (!isWeekend) setWeek(currentWeek);
  }, [includeWeekend]);

  const [dayIndexState, setDayIndex] = useState<DayIndex>(
    isWeekend ? 0 : todayIndex
  ); // Can be 0,1,2,3,4
  const date = weekState[dayIndexState] as TimestampType;
  const dateSeconds = date?.seconds;

  const setDateSeconds = (newDateSeconds: number) => {
    let newDateIndex = getDayIndexFromSeconds(newDateSeconds);
    if (typeof newDateIndex !== `number`) return;
    if (!includeWeekend && newDateIndex > 4) newDateIndex = 4;
    if (newDateIndex < 0) newDateIndex = 0;
    setDayIndex(newDateIndex);

    // Adjust week
    const newWeek = getWeekArray(
      getWeekSeconds(newDateSeconds),
      Timestamp,
      includeWeekend
    );
    setWeek(newWeek);
  };

  /**
   * set new date (support timestamp || moment || seconds || week and dayIndex) and set the date url param with the new date
   * @param args dateTimestamp: Timestamp | dateMoment: moment.Moment | dateSeconds: number | week?: Week, dayIndex?: DayIndex
   */
  const setDate = (args: SetDateArgs) => {
    let newSeconds: number | undefined;
    if (args.dateTimestamp) {
      newSeconds = args.dateTimestamp.seconds;
      setDateSeconds(newSeconds);
    }
    if (args.momentDate) {
      newSeconds = args.momentDate.unix();
      setDateSeconds(newSeconds);
    }
    if (args.dateSeconds) {
      newSeconds = args.dateSeconds;
      setDateSeconds(newSeconds);
    }

    const dayIndexExists = !!(args.dayIndex || args.dayIndex === 0);
    if (dayIndexExists || args.week) {
      const weekToSet = args.week || weekState;
      const dayIndexToSet = args.dayIndex ?? dayIndexState;

      setDayIndex(dayIndexToSet);
      setWeek(weekToSet);

      newSeconds = weekToSet[dayIndexToSet]?.seconds;
    }

    newSeconds && setParamDateSeconds(newSeconds);
  };

  const activateRouter = router.isReady && router?.asPath?.includes("/app");

  // set dateSeconds from URL param
  useEffect(() => {
    if (!activateRouter) return;
    if (paramDateSeconds === dateSeconds) return;

    const dateToSet = paramDateSeconds || getTodaySeconds();

    setDate({ dateSeconds: dateToSet });
  }, [activateRouter]);

  const calendarFormatter = useCalendarFormatter();

  const displayDate = useMemo(
    () =>
      moment
        .unix(weekState[dayIndexState]?.seconds || 0)
        .calendar(null, calendarFormatter),
    [calendarFormatter, dayIndexState, weekState]
  );

  return {
    week: weekState,
    dayIndex: dayIndexState,
    date,
    dateSeconds,
    setDate,
    calendarFormatter,
    displayDate,
    timeNavigation,
    setTimeNavigation,
  };
};
