import { OrderDiscount } from "../../../types/src/documents/base";

export const calculateOrderDiscountAmount = (
  discounts: OrderDiscount[],
  includeAlreadyDiscounted: boolean
) => {
  if (!discounts || !discounts.length) {
    return 0;
  }

  return discounts
    .filter((d) => includeAlreadyDiscounted || !d.alreadyDiscounted)
    .reduce(
      (acc, discount) =>
        acc + (discount?.discountedAmount || discount.unitDiscountedAmount),
      0
    );
};
