import { AllowanceUsage, CombinedOfferItem } from "@kanpla/types";
import { isEmpty } from "lodash";

interface AllowanceForProductArgs {
  productId: CombinedOfferItem["id"];
  allowanceUsages: AllowanceUsage[];
}

export const getAllowancesForProduct = ({
  productId,
  allowanceUsages,
}: AllowanceForProductArgs): AllowanceUsage[] => {
  const targetAllowanceProducts = allowanceUsages.filter((usage) => {
    const { numberOfProducts, unitPrice } = usage;

    if (numberOfProducts?.productIds.includes(productId)) return true;
    if (unitPrice?.productIds.includes(productId)) return true;

    return (
      isEmpty(numberOfProducts?.productIds) && isEmpty(unitPrice?.productIds)
    );
  });

  return targetAllowanceProducts;
};
