import { isEmpty, orderBy } from "lodash";

import { AllowanceUsage, Product } from "@kanpla/types";

export const findAllowanceForProductId = (
  productId: Product["id"],
  allowanceUsages: AllowanceUsage[]
) => {
  const sortedAllowances = orderBy(
    allowanceUsages,
    (allowance) => allowance.unitPrice?.amount
  );

  const productIsInAllowance = sortedAllowances.find((allowance) =>
    allowance.unitPrice?.productIds?.includes(productId)
  );

  if (!productIsInAllowance)
    return sortedAllowances.find((allowance) =>
      isEmpty(allowance.unitPrice?.productIds)
    );

  return productIsInAllowance;
};
