import React from "react";

import { useAtomValue } from "jotai";
import { authModalStateAtom } from "../modals/Anonymous/AnonymousModal";
import LoginContent from "./../login/LoginContent";
import SignUpFlow from "./../signup-flow";

type AuthenticationProps = {
  isInModal?: boolean;
  wrap?: boolean;
};

const Authentication = ({
  isInModal = false,
  wrap = false,
}: AuthenticationProps) => {
  const authModalState = useAtomValue(authModalStateAtom);

  if (authModalState === "signup") {
    return (
      <AuthenticationWrapper wrap={wrap}>
        <SignUpFlow isInModal={isInModal} />
      </AuthenticationWrapper>
    );
  }

  return (
    <AuthenticationWrapper wrap={wrap}>
      <LoginContent isInModal={isInModal} />
    </AuthenticationWrapper>
  );
};

type AuthenticationWrapperProps = {
  wrap: boolean;
  children: React.ReactElement;
};
const AuthenticationWrapper = ({
  wrap,
  children,
}: AuthenticationWrapperProps) => {
  if (wrap) {
    return (
      <div className="w-full p-3">
        <div className="text-center my-5 mx-auto p-3 rounded-sm shadow-lg w-full lg:w-3/4 xl:w-2/4">
          {children}
        </div>
      </div>
    );
  }

  return children;
};

export default Authentication;
