import { CountryCode, useT } from "@kanpla/system";
import { DrawerOrModal, PrivacyPolicy, TermsOfTrade } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { useContainer } from "unstated-next";
import { AppContext, paymentGatewayIdAtom } from "./contextProvider";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface CompanyInfoProps {
  email: string;
  phone: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
  vatNr: string;
}

const getCompanyInfo = (code?: CountryCode): CompanyInfoProps => {
  // Danish specific info
  if (!code || code === "DK")
    return {
      email: "support@kanpla.dk",
      phone: "+45 39 39 30 09",
      street: "Søndergade 44, 3",
      postCode: "8000",
      city: "Aarhus C",
      country: "Danmark",
      vatNr: "40369813",
    };

  return {
    email: "support@kanpla.io",
    phone: "+44 330 8081620",
    street: "Soendergade 44, 3",
    postCode: "DK-8000",
    city: "Aarhus C",
    country: "Denmark",
    vatNr: "DK40369813",
  };
};

const CompanyInfo = ({ open, setOpen }: Props) => {
  const paymentGatewayId = useAtomValue(paymentGatewayIdAtom);
  const t = useT();
  const { partner } = useContainer(AppContext);

  const { street, postCode, city, country, email, phone, vatNr } =
    getCompanyInfo(partner?.country);

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title="Kanpla ApS"
      className="text-center"
      containerClassName="flex"
    >
      <p className="mb-4 text-text-primary">
        {street}
        <br />
        {postCode} {city}, {country}
      </p>
      <p className="text-text-primary">
        {t("Email: {mailComponent}", {
          mailComponent: (
            <a href={`mailto:${email}`} className="font-medium">
              {email}
            </a>
          ),
        })}
      </p>
      <p className="mb-6 text-text-primary">
        {t("Phone: {phoneComponent}", {
          phoneComponent: (
            <a href={`tel:${phone}`} className="font-medium">
              {phone}
            </a>
          ),
        })}
      </p>
      <div className="flex gap-4 mb-6 justify-center">
        <PrivacyPolicy paymentGatewayId={paymentGatewayId} />
        <TermsOfTrade paymentGatewayId={paymentGatewayId} />
      </div>
      <p className="text-sm text-text-secondary">
        Copyright © 2020 - {moment().format("YYYY")} <br /> Kanpla Aps - CVR:
        {vatNr}
      </p>
    </DrawerOrModal>
  );
};

export default CompanyInfo;
