import { OrderLines } from "@kanpla/types";
import { groupBy } from "lodash";
import { stringifyOrderLine } from "../flex/hasOrderChanged";
import { calculateAmountOfOrderItems } from "./calculateAmountOfOrderItems";

/** used when:
 * - adding products to an existing order */

export const mergeOrders = <T extends OrderLines>(rawOrders: Array<T>) => {
  // 1. Merge
  const validOrders: T[] = rawOrders.filter((o) => o?.length);
  const merged = validOrders.flat();

  // 2. Refactor
  const groupedByConfig = groupBy(merged, stringifyOrderLine);
  const reduced = Object.values(groupedByConfig).map((orderLines) => ({
    ...orderLines[0],
    amount: calculateAmountOfOrderItems(orderLines),
  }));

  return reduced;
};

export default mergeOrders;
