import { AllowanceUsage, Product } from "@kanpla/types";
import { isEmpty } from "lodash";

interface AllowanceMaxAmountArgs {
  /** Product price */
  productPrice: Product["unitPrice"];
  /** The allowance usages for the current product (already filtered) @see getAllowancesForProducts */
  allowanceUsages: AllowanceUsage[];
}

/**
 * Returns the maximum amount of products that can be added to the basket
 * @interface `AllowanceMaxAmountArgs`
 * @returns `number` | `null`
 */
export const getAllowanceMaxAmount = ({
  productPrice,
  allowanceUsages,
}: AllowanceMaxAmountArgs) => {
  if (isEmpty(allowanceUsages)) return null;

  const amounts: number[] = [];

  const allowanceUsagesWithAmount = allowanceUsages.filter(
    (usage) => usage.numberOfProducts?.amount
  );

  const allowanceUsagesWithPrice = allowanceUsages.filter(
    (usage) => usage.unitPrice?.amount
  );

  if (!isEmpty(allowanceUsagesWithAmount)) {
    const maxAmountForNumberOfProducts = Math.min(
      ...allowanceUsagesWithAmount.map(
        (usage) => usage.numberOfProducts?.amount || Infinity
      )
    );

    amounts.push(maxAmountForNumberOfProducts);
  }

  if (!isEmpty(allowanceUsagesWithPrice)) {
    const maxAmountForPrice = Math.min(
      ...allowanceUsagesWithPrice.map(
        (usage) => usage.unitPrice?.amount || Infinity
      )
    );

    amounts.push(Math.floor(maxAmountForPrice / productPrice));
  }

  return Math.min(...amounts);
};
