import classnames from "classnames";
import { isArray } from "lodash";
import React, { CSSProperties, ReactNode } from "react";

type SizeType = "small" | "middle" | "large" | number;
interface SpaceProps {
  children?: ReactNode;
  align?: "start" | "end" | "center" | "baseline";
  /** Gap row and column. You can define 2 different gaps by using an array [row, column]. The size can be defined through default values or customizable with a number (e.g.: 12 -> 12px) */
  size?: SizeType | [SizeType, SizeType];
  direction?: "horizontal" | "vertical";
  className?: string;
  style?: CSSProperties;
}

const DEFAULT_SIZE = "small";

export const Space: React.FC<SpaceProps> = (props): JSX.Element => {
  const {
    children,
    align,
    size = DEFAULT_SIZE,
    direction = "horizontal",
    className = "",
    ...rest
  } = props;

  const flexDirection = { horizontal: "flex-row", vertical: "flex-col" };
  const alignItems = align
    ? `items-${align}`
    : direction === "horizontal"
    ? "items-center"
    : "items-start";

  const gap = {
    small: { all: "gap-2", row: "gap-x-2", col: "gap-y-2" },
    middle: { all: "gap-4", row: "gap-x-4", col: "gap-y-4" },
    large: { all: "gap-6", row: "gap-x-6", col: "gap-y-6" },
  };

  const getGap = (row: SizeType, col: SizeType) => {
    const targetRow =
      typeof row === "number" ? `gap-x-[${row}px]` : gap[row].row;
    const targetCol =
      typeof col === "number" ? `gap-y-[${col}px]` : gap[col].col;

    return `${targetRow} ${targetCol}`;
  };

  const row = isArray(size)
    ? ((size[0] || DEFAULT_SIZE) as SizeType)
    : DEFAULT_SIZE;
  const col = isArray(size)
    ? ((size[1] || DEFAULT_SIZE) as SizeType)
    : DEFAULT_SIZE;

  const gapClass = isArray(size)
    ? getGap(row, col)
    : typeof size === "number"
    ? `gap-[${size}px]`
    : gap[size].all;

  const classes = classnames(
    "inline-flex",
    alignItems,
    gapClass,
    flexDirection[direction],
    className
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};
