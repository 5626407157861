import { useWindowSize } from "@kanpla/system";
import { PopConfirmButton, TooltipDisabledButton } from "@kanpla/types";
import classnames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { BaseButtonProps, Lock } from "..";
import { Actions } from "../elements/BottomDrawer/Actions";
import { BottomDrawerOrModalCommonProps } from "../elements/BottomDrawer/DrawerOrModal";
import { CloseButton } from "../elements/CloseButton";
import { ModalWrapper } from "./ModalWrapper";

export interface ActionType
  extends BaseButtonProps,
    TooltipDisabledButton,
    PopConfirmButton {
  label?: string | ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  dataCy?: string;
  htmlType?: string;
  form?: string;
}

export interface ModalProps extends BottomDrawerOrModalCommonProps {
  children: any;
  className?: string;
  style?: React.CSSProperties;
  containerClassName?: string;
  actionsClassName?: string;
  closableOutside?: boolean;
  disableLock?: boolean;
  maxWidth?: string;
  extraTopContent?: ReactElement;
  preventThroughClick?: boolean;
  noOverflow?: boolean;
  stopPropagation?: boolean;
  portalContainer?: HTMLElement;
}

export const Modal = (props: ModalProps) => {
  const {
    children,
    open,
    setOpen,
    title,
    subtitle,
    className = "",
    style = {},
    containerClassName = "",
    actionsClassName = "",
    noPadding = false,
    actions,
    zMax,
    zIndex = 40,
    closableOutside = true,
    disableLock = false,
    maxWidth = "max-w-md",
    extraTopContent = null,
    stopPropagation = true,
    preventThroughClick,
    showCloseButton = false,
    noOverflow = false,
    noPaddingOnActions = false,
    portalContainer,
  } = props;

  const { width: screenWidth } = useWindowSize();

  return (
    <ModalWrapper
      containerClassName={classnames("py-16 flex px-3", containerClassName)}
      innerClassName={classnames(
        "rounded-lg bg-background-primary shadow-xl relative w-full max-h-full scrollbar-hide m-auto",
        { "overflow-auto": !noOverflow },
        maxWidth,
        className
      )}
      innerStyles={{
        transformOrigin: "50% 50% 0",
        ...style,
      }}
      open={open}
      setOpen={setOpen}
      dragToClose={true}
      zMax={zMax}
      zIndex={zIndex}
      closableOutside={closableOutside}
      preventThroughClick={preventThroughClick}
      stopPropagation={stopPropagation}
      portalContainer={portalContainer}
    >
      {!disableLock && (screenWidth || 0) >= 768 && open && <Lock />}
      <>
        <div className={classnames("h-full", noPadding ? "p-0" : "p-8")}>
          {showCloseButton && <CloseButton onClick={() => setOpen(false)} />}
          {extraTopContent}
          {title && (
            <div className="mb-4 relative z-30">
              <h2 className="title-secondary text-center text-text-primary">
                {title}
              </h2>
              {subtitle && (
                <h2 className="text-text-secondary text-center">{subtitle}</h2>
              )}
            </div>
          )}
          {children}
        </div>

        {actions && (
          <div
            className={classnames(
              "flex flex-wrap justify-center whitespace-nowrap w-full rounded-b-lg overflow-hidden sticky bottom-0 bg-gradient-to-b from-transparent to-background-primary z-20",
              actionsClassName
            )}
          >
            <Actions actions={actions} noPadding={noPaddingOnActions} />
          </div>
        )}
      </>
    </ModalWrapper>
  );
};

export default Modal;
