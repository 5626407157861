import { faCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { useT, validateSelectors } from "@kanpla/system";
import { ChildSelector } from "@kanpla/types";
import { Form, Input, SelectorsInput } from "@kanpla/ui";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";
import { nameAtom } from "../..";
import PageWrapper from "../../PageWrapper";
import { childAtom, selectorsAtom, useChild } from "../../lib/useChild";
import { useSchool } from "../../lib/useSchool";

type FormData = {
  name: string;
  selectors?: ChildSelector;
};

const AddChild = () => {
  const t = useT();
  const { updateCurrentChild } = useContainer(AppContext);

  const [selectorsFromAtom, setSelectors] = useAtom(selectorsAtom);
  const setName = useSetAtom(nameAtom);

  const { createChild } = useChild();
  const { school, childIsSalesplace } = useSchool();
  const child = useAtomValue(childAtom);

  const showSelectors =
    !isEmpty(school?.selectors) && isEmpty(selectorsFromAtom);

  const submit = async ({ name, selectors = {} }: FormData) => {
    setName(name);
    const allSelectors = selectorsFromAtom || selectors;
    setSelectors(allSelectors);

    try {
      if (child?.id)
        await updateCurrentChild({
          id: child.id,
          name,
          selectors: allSelectors,
        });
      else
        await createChild({ name, selectors: allSelectors, childIsSalesplace });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PageWrapper
      title={t("Add an eater")}
      subtitle={t(
        "Add the name and division of the person who will receive the food."
      )}
      buttonTitle={t("Add an eater")}
      onButtonSubmit={submit}
    >
      <div className="w-full h-full">
        <Form.Item
          name="name"
          rules={{ required: t("Please enter a name") }}
          required
        >
          <Input
            id="new-name-input"
            placeholder={t("John Doe")}
            dataCy="new-eater-input"
            icon={faCircleUser}
            label={t("Full name of the eater")}
            iconRight
          />
        </Form.Item>
        {showSelectors && (
          <Form.Item
            name="selectors"
            controlled
            controlledProps={{
              valueName: "selectors",
              onChangeName: "setSelectors",
            }}
            rules={{
              validate: (value: ChildSelector) => {
                // isSchoolForChildren &&
                // !validateSelectors(value, school?.selectors || []) &&
                // t("Choose a correct division")

                return validateSelectors(value, school?.selectors || []);
              },
            }}
          >
            <SelectorsInput school={school} isControlled />
          </Form.Item>
        )}

        <p className="text-text-secondary text-center text-sm my-4">
          {t("(You can add more eaters later)")}
        </p>
      </div>
    </PageWrapper>
  );
};

export default AddChild;
