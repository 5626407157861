import { SelectTypes } from "@kanpla/types";
import React from "react";
import { Loader } from "../../../antd/Loader";

const LoadingIndicator: SelectTypes.LoadingIndicator = ({
  innerProps,
}: SelectTypes.LoadingIndicatorProps) => {
  return (
    <div {...innerProps}>
      <Loader
        loaderClasses="!text-gray-400 text-sm"
        spinClasses="flex items-center h-full"
      />
    </div>
  );
};

export default LoadingIndicator;
