/* eslint-disable react-hooks-unreliable-deps/reference-deps */
import { useHasPublicSchools } from "apps/frontend/lib/signup/useHasPublicSchools";
import { atom, useAtom, useSetAtom } from "jotai";
import { entries } from "lodash";
import router from "next/router";
import { useContainer } from "unstated-next";
import { pageLoadingAtom } from "..";
import { AppContext } from "../../contextProvider";
import { useSchool } from "./useSchool";

export type ViewPages =
  | "schoolOrCompany"
  | "salesplace"
  | "email"
  | "emailCode"
  | "accountAlreadyExists"
  | "companyNotExisting"
  | "provider"
  | "canteenId"
  | "user"
  | "addChild"
  | "accessCodes"
  | "kanplaCard"
  | "reference"
  | "completed"
  | "chooseAllergens";

type ScreenSectionContent = {
  active: boolean;
};

type ScreenSection = {
  [key in ViewPages]: ScreenSectionContent;
};

export const currentPageAtom = atom<ViewPages>("schoolOrCompany");

/**
 * Hook that handles navigation between the different screens in the signup flow
 * @returns goToNextScreen - function that navigates to the next screen in the signup flow
 */
export const usePageNavigation = () => {
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom);
  const setPageLoading = useSetAtom(pageLoadingAtom);

  const { supplier } = useContainer(AppContext);

  const {
    isSchoolForChildren,
    hasAccessCodes,
    hasKanplaCard,
    hasReferencePlugin,
  } = useSchool();

  const { hasPublicSchools, hasSchoolSwitch } = useHasPublicSchools();

  const {
    shouldHideReference = false,
    shouldHideAccessCode = false,
    shouldHideAllergens = false,
  } = supplier?.screens || {};

  const allScreens: ScreenSection = {
    schoolOrCompany: {
      active: hasSchoolSwitch || false,
    },
    salesplace: {
      active: hasPublicSchools || hasSchoolSwitch || false,
    },
    email: {
      active: true,
    },
    emailCode: {
      active: false,
    },
    /** Out of flow */
    accountAlreadyExists: {
      active: false,
    },
    /** Out of flow */
    companyNotExisting: {
      active: false,
    },
    /** Out of flow */
    provider: {
      active: false,
    },
    canteenId: {
      active: false,
    },
    user: {
      active: true,
    },
    addChild: {
      active: isSchoolForChildren || false,
    },
    chooseAllergens: {
      active: !shouldHideAllergens,
    },
    accessCodes: {
      active: (!shouldHideAccessCode && hasAccessCodes) || false,
    },
    kanplaCard: {
      active: hasKanplaCard || false,
    },
    reference: {
      active: (!shouldHideReference && hasReferencePlugin) || false,
    },
    completed: {
      active: true,
    },
  };

  const pages = entries(allScreens)
    .filter(([, { active }]) => active)
    .map(([key]) => key as ViewPages);

  const currentIndex = pages.indexOf(currentPage);

  const goToNextScreen = () => {
    const nextIndex = currentIndex + 1;
    const nextPage = pages[nextIndex];

    if (nextPage) {
      setCurrentPage(nextPage);
    } else {
      setPageLoading(true);
      router.push("/app");
    }
  };

  return { goToNextScreen, allScreens, pages };
};
