import { faCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { callFunction, T, tx, useLocationHostname, useT } from "@kanpla/system";
import { Child, ChildSelector, CreateAccountProps } from "@kanpla/types";
import {
  Checkbox,
  Form,
  Input,
  PrivacyPolicy,
  SelectorsInput,
  TermsOfTrade,
} from "@kanpla/ui";
import { getUniqueCodes } from "apps/frontend/lib/signup/codes";
import { kanplaDomains } from "apps/frontend/lib/signup/domains";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isArray, isEmpty } from "lodash";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { nameAtom, throughInvitationAtom } from "../..";
import { AppContext, paymentGatewayIdAtom } from "../../../contextProvider";
import { childAtom, selectorsAtom } from "../../lib/useChild";
import { currentPageAtom } from "../../lib/usePageNavigation";
import { schoolIdAtom, useSchool } from "../../lib/useSchool";
import {
  domainsInfoAtom,
  emailAtom,
  multiSchoolEmailAtom,
} from "../../lib/useSubmitEmail";
import PageWrapper from "../../PageWrapper";
import { scopeAtom } from "./CanteenId";

type FormData = {
  password: string;
  newsletter?: boolean;
  acceptedTerms: boolean;
  name: string;
  selectors?: ChildSelector;
};

type CreateAccountResponse = {
  token: string;
  child: Child;
};

const Register = () => {
  const t = useT();

  const { school, isSchoolForChildren } = useSchool();

  const schoolId = useAtomValue(schoolIdAtom);
  const scope = useAtomValue(scopeAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);
  const domainsInfo = useAtomValue(domainsInfoAtom);
  const multiSchoolsEmail = useAtomValue(multiSchoolEmailAtom);
  const email = useAtomValue(emailAtom);
  const setName = useSetAtom(nameAtom);
  const setChild = useSetAtom(childAtom);
  const [selectorsFromAtom, setSelectorsFromAtom] = useAtom(selectorsAtom);
  const throughInvitation = useAtomValue(throughInvitationAtom);
  const paymentGatewayId = useAtomValue(paymentGatewayIdAtom);

  const { auth, isChildSalesplace } = useContainer(AppContext);

  const hostname = useLocationHostname({}) || "";
  const showNewsletter = kanplaDomains.includes(hostname);

  const targetSchoolSelectors = multiSchoolsEmail?.find(
    (s) => s.schoolId === schoolId
  )?.selectors;

  const hasOnlyOneOption = targetSchoolSelectors?.[0]?.options?.length === 1;
  const hasNoOptions = !targetSchoolSelectors?.[0]?.options?.length;

  const schoolsPublicSelectors = school?.selectors?.map((selector) =>
    selector?.options?.filter((option) => !option?.hidden)
  );

  const hasAnyPublicSelectors = schoolsPublicSelectors?.flat()?.length;

  const shouldChooseSelectors =
    targetSchoolSelectors &&
    !isEmpty(targetSchoolSelectors) &&
    !hasOnlyOneOption;

  useEffect(() => {
    if (!hasOnlyOneOption) return;
    if (isEmpty(targetSchoolSelectors)) return;

    setSelectorsFromAtom(
      targetSchoolSelectors?.reduce((acc, sel) => {
        acc[sel.name] = sel.options?.[0]?.name;
        return acc;
      }, {})
    );
  }, [hasOnlyOneOption]);

  const submit = async ({
    password,
    newsletter,
    name,
    selectors = {},
  }: FormData) => {
    try {
      if (shouldChooseSelectors) setSelectorsFromAtom(selectors);

      setName(name);

      const codes = getUniqueCodes({
        domainsInfo: isArray(domainsInfo) ? domainsInfo : [domainsInfo],
        schoolId,
      });

      const { token, child } = await callFunction<
        (params: CreateAccountProps) => Promise<CreateAccountResponse>
      >("signup-createAccount", {
        email,
        password,
        newsletter,
        partnerId: school?.partnerId,
        child: {
          name,
          schoolId,
          selectors: isEmpty(selectors) ? selectorsFromAtom : selectors,
          scope,
          codes,
        },
        lang: tx.getCurrentLocale(),
        isChildSalesplace,
      });

      setChild(child);

      await auth.signInWithToken(token);
    } catch (err: any) {
      if (err.code === "already-exists") setCurrentPage("accountAlreadyExists");
      else throw new Error(err);
    }
  };

  return (
    <PageWrapper
      title={t("You are almost there!")}
      subtitle={
        <span>
          <T
            _str="You are connected to {schoolName} ✅"
            schoolName={<span className="font-semibold">{school?.name}</span>}
          />
        </span>
      }
      buttonTitle={t("Sign up")}
      htmlType="submit"
      onButtonSubmit={submit}
      showBackButton
      defaultFormValues={{
        newsletter: false,
        selectors: selectorsFromAtom,
      }}
    >
      {!isSchoolForChildren && (
        <Form.Item
          name="name"
          rules={{ required: t("Please enter a name") }}
          required
        >
          <Input
            id="new-name-input"
            placeholder={t("John Doe")}
            dataCy="new-name-input"
            icon={faCircleUser}
            label={t("Your name")}
            iconRight
          />
        </Form.Item>
      )}

      <Form.Item
        required
        name="password"
        rules={{
          required: t("Please enter a password"),
          minLength: {
            value: 6,
            message: t("Password should be at least 6 characters long"),
          },
        }}
      >
        <Input.Password
          id="password"
          placeholder={t("Password")}
          dataCy="new-password-input"
          required
        />
      </Form.Item>

      {/* Public selectors */}
      {((hasAnyPublicSelectors && hasNoOptions) || shouldChooseSelectors) && (
        <div className="mt-4 w-full">
          <Form.Item
            name="selectors"
            controlled
            controlledProps={{
              valueName: "selectors",
              onChangeName: "setSelectors",
            }}
            rules={{
              required: shouldChooseSelectors,
              validate: (value: ChildSelector) =>
                shouldChooseSelectors && isEmpty(value)
                  ? t("Choose a division")
                  : true,
            }}
            required={shouldChooseSelectors}
          >
            <SelectorsInput
              school={school}
              useExtraSelectors={shouldChooseSelectors}
              extraSelectors={targetSchoolSelectors || []}
              isControlled
            />
          </Form.Item>
        </div>
      )}

      <Form.Item
        rules={{
          required: t(
            "You have to accept our Terms and Conditions and Privacy Policy"
          ),
        }}
        name="acceptedTerms"
        className="mt-4 mb-0"
        controlled
        controlledProps={{ valueName: "checked" }}
      >
        <Checkbox id="signupLegalConsent" dataCy="signupLegalConsent">
          <T
            _str="I accept {termsOfUse} and {privacyPolicy}"
            termsOfUse={<TermsOfTrade paymentGatewayId={paymentGatewayId} />}
            privacyPolicy={
              <PrivacyPolicy paymentGatewayId={paymentGatewayId} />
            }
          />
        </Checkbox>
      </Form.Item>
      {showNewsletter && !throughInvitation && (
        <Form.Item
          name="newsletter"
          className="my-3"
          controlled
          controlledProps={{ valueName: "checked" }}
        >
          <Checkbox id="signupNewsletter" dataCy="signupNewsletter">
            {t("Subscribe to Kanpla newsletter")}
          </Checkbox>
        </Form.Item>
      )}
    </PageWrapper>
  );
};

export default Register;
