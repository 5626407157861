import { callInternalApi, db, useT } from "@kanpla/system";
import { Form, message } from "@kanpla/ui";
import KanplaCard from "apps/frontend/components/forms/child/KanplaCard";
import { useAtomValue } from "jotai";
import {
  IdExistsProps,
  IdExistsReturn,
} from "libs/services/src/lib/_microservices/idExists";
import { useState } from "react";
import PageWrapper from "../../PageWrapper";
import { childAtom } from "../../lib/useChild";

const AddCard = () => {
  const t = useT();

  const child = useAtomValue(childAtom);

  const [haveCard, setHaveCard] = useState(true);
  const [loading, setLoading] = useState(false);

  const submit = async ({ kanplaId }: { kanplaId: string }) => {
    try {
      setLoading(true);
      if (!haveCard) return;

      const { exists } = await callInternalApi<IdExistsProps, IdExistsReturn>(
        "load/idExists",
        { kanplaId: parseInt(kanplaId.toString()) }
      );

      if (exists) {
        throw new Error(t("The Kanpla ID is already in use"));
      }

      await db
        .collection("children")
        .doc(child.id)
        .update({
          kanplaId: parseInt(kanplaId),
        });
    } catch (err) {
      message.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title={t("Kanpla card")}
      subtitle={t(
        "The card can be used to get food or to buy extras in the canteen."
      )}
      buttonTitle={t("Add card")}
      topIllustration="/images/signup-flow/kanpla-card.svg"
      onButtonSubmit={submit}
      buttonLoading={loading}
      skipButton
    >
      <Form.Item
        name="kanplaId"
        controlled
        rules={{ required: haveCard && t("Enter your card number") }}
      >
        {/* @ts-ignore */}
        <KanplaCard haveCard={haveCard} setHaveCard={setHaveCard} />
      </Form.Item>
    </PageWrapper>
  );
};

export default AddCard;
