import { OrderLines } from "@kanpla/types";
import { calculateOrderVatBreakdown } from "./calculateOrderVatBreakdown";

/** calculateOrderTotal displays price inclusive/exclusive VAT based on a boolean provided.
 * Use this if you need to manually control the format, and don’t have module or order to base it on. */

export const calculateOrderTotal = (
  orderLines: OrderLines | undefined = [],
  showInclusiveVat?: boolean
) => {
  const { withoutVat, withVat } = calculateOrderVatBreakdown(orderLines);
  const result = showInclusiveVat ? withVat : withoutVat;

  return result;
};
