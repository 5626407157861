import { LoadOfferProps, Scope } from "@kanpla/types";

type Props = {
  scope: Scope | null;
  schoolId: string;
  /** Optionally limit by selector */
  groupName?: string | null;
  skipFilteringByGroupname?: LoadOfferProps["skipFilteringByGroupname"];
};

export const isSchoolSelectorInScope = ({
  scope,
  schoolId,
  groupName,
  skipFilteringByGroupname,
}: Props) => {
  // 2. Availability by school
  const isAvailableForSchool =
    scope === null ||
    scope?.generatedSchoolIds?.includes(schoolId) ||
    Object.keys(scope?.schools || {}).includes(schoolId);

  // 2.1 Selectors
  const selectorStrings = scope?.schools?.[schoolId]?.selectorStrings || [];
  const skipLimitingSelectors =
    !selectorStrings?.length || skipFilteringByGroupname || !groupName;
  if (skipLimitingSelectors) return isAvailableForSchool;

  const isAvailableForGroup = selectorStrings.includes(groupName || "");
  return isAvailableForSchool && isAvailableForGroup;
};
