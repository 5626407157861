import { Child, Supplier, _FrontendSupplier } from "@kanpla/types";
import classNames from "classnames";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DisplayAttribute } from "../../elements/productAttributes/DisplayAttribute";
import { AttributeData } from "../../elements/productAttributes/attributesData";

type AllergenInputProps = {
  attribute: AttributeData;
  allergens: Child["allergens"];
  setAllergens?: Dispatch<SetStateAction<Child["allergens"]>>;
  supplier?: Supplier | _FrontendSupplier | null;
  childrenAttributes?: AttributeData[];
  parentAttribute?: AttributeData;
};

const AllergenInput = ({
  attribute,
  supplier,
  parentAttribute,
  childrenAttributes = [],
  allergens = {},
  setAllergens = () => null,
}: AllergenInputProps) => {
  const [value, setValue] = useState(
    allergens[attribute?.key] ? allergens[attribute?.key] : false
  );

  const active = allergens?.[attribute.key];

  /**
   * Sets allergen value if it got change no matter if the change
   * done from inside or outside of the component.
   */
  useEffect(() => {
    setValue(active);
  }, [active]);

  return (
    <button
      className="group flex flex-col items-center"
      type="button"
      onClick={() => {
        if (!childrenAttributes) {
          setAllergens({ ...allergens, [attribute?.key]: !value });
          return;
        }

        if (parentAttribute) {
          setAllergens({
            ...allergens,
            [attribute?.key]: !value,
            [parentAttribute?.key]: true,
          });
          return;
        }

        // If there are children, disable them all
        const mappedChildren = childrenAttributes.reduce(
          (acc, child) => ({ ...acc, [child.key]: false }),
          {}
        );

        setAllergens({
          ...allergens,
          [attribute?.key]: !value,
          ...mappedChildren,
        });
      }}
    >
      <DisplayAttribute
        allergenName={attribute.key}
        colorClassName={classNames(
          value ? "text-primary-main" : "text-text-disabled opacity-80",
          "transition-opacity"
        )}
        supplier={supplier}
      />
    </button>
  );
};

export default AllergenInput;
