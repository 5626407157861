import { isEmpty } from "lodash";
import { generate } from "short-uuid";

import { AllowanceRuleDiscount, Discount, Module } from "@kanpla/types";

interface GetAllowanceRulesFromDiscounts {
  discounts: Discount[];
  moduleId: Module["id"];
}

export const getAllowanceRulesFromDiscounts = ({
  discounts,
  moduleId,
}: GetAllowanceRulesFromDiscounts) => {
  if (isEmpty(discounts)) return [] as AllowanceRuleDiscount[];

  const mappedDiscounts = discounts.map((discount) => {
    const { allowance, scope, requiredOrder, moduleIds } = discount;

    if (!allowance) return null;
    if (!moduleIds.includes(moduleId)) return null;

    const allowanceRule: AllowanceRuleDiscount = {
      id: generate(),
      discountId: discount.id,
      allowance,
      scope,
      affectedProducts: requiredOrder,
    };

    return allowanceRule;
  });

  const filteredDiscounts = mappedDiscounts.filter(
    (discount) => discount !== null
  );

  return filteredDiscounts as AllowanceRuleDiscount[];
};
