import { faBan } from "@fortawesome/pro-solid-svg-icons";
import {
  callInternalApi,
  humanSort,
  selectFilterOption,
  useT,
} from "@kanpla/system";
import { Child, School, Selector, User } from "@kanpla/types";
import { Button, Select } from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import { capitalize, orderBy, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { usePreviousImmediate } from "rooks";

type SelectorsInputProps = {
  school?: School;
  allowHiddenOptions?: boolean;
  /** If this flag is set, the input will use the options coming from `extraSelectors` instead of the school */
  useExtraSelectors?: boolean;
  /** Custom selectors, not necessarily coming from a school */
  extraSelectors?: Selector[];
  isControlled?: boolean;
  domainSelectors?: Selector[];
  email?: User["email"];
} & (
  | {
      isControlled: true;
      selectors?: undefined;
      setSelectors?: undefined;
    }
  | {
      isControlled?: false;
      selectors: Child["selectors"];
      setSelectors: (newValue: Child["selectors"]) => void;
    }
);

export const SelectorsInput = ({
  selectors,
  setSelectors = () => null,
  school,
  allowHiddenOptions = false,
  useExtraSelectors = false,
  extraSelectors = [],
  domainSelectors: domainSelectorsContext = [],
  email,
}: SelectorsInputProps) => {
  const t = useT();

  const schoolId = school?.id;

  const { data } = useQuery({
    queryKey: ["loadDomainSelectors", { schoolId, email: email }],
    queryFn: () =>
      callInternalApi("load/loadDomainSelectors", {
        email,
        schoolId,
      }),
    cacheTime: 1000 * 60 * 120, // Cache for 2 hours (no need to reload already cached data)
    staleTime: 1000 * 60 * 120, // Reload after 2 hours
    enabled: !!schoolId && !!email,
  });

  const domainSelectors = uniq([
    ...domainSelectorsContext,
    ...(data?.domainSelectors || []),
  ]);

  const [activeSelectorIndex, setActiveSelectorIndex] = useState(0);

  const previousSchoolId = usePreviousImmediate(school?.id);

  useEffect(() => {
    setActiveSelectorIndex(Object.values(selectors || {}).length);
  }, [selectors]);

  // Empty selectors on school change
  useEffect(() => {
    const isValid = Object.entries(selectors || {}).every(([layer, value]) => {
      const targetLayer = school?.selectors?.find((l) => l.name === layer);
      const hasTargetOption = targetLayer?.options?.some(
        (o) => o.name === value
      );
      return hasTargetOption;
    });
    if (!schoolId || isValid) return;
    if (!previousSchoolId) return;
    if (previousSchoolId === school?.id) return;
    setSelectors({});
  }, [schoolId]);

  const schoolSelectors = school?.selectors || ([] as School["selectors"]);
  const chooseSelectors = useExtraSelectors ? extraSelectors : schoolSelectors;

  const schoolsOptions = orderBy(chooseSelectors, "position");

  const onInputChange = (value: string, name: string, index: number) => {
    const selectorsToNull = schoolsOptions.filter((s, innerIndex) => {
      return innerIndex >= index;
    });

    const newSelectors = Object.entries(selectors || {}).reduce(
      (acc, [layerName, doc]) => {
        acc[layerName] = doc;

        if (selectorsToNull.map((m) => m.name).includes(layerName))
          acc[layerName] = "";

        return acc;
      },
      {}
    );
    newSelectors[name] = value;

    setSelectors(newSelectors);
  };

  const hasMoreThanOneSelector = schoolsOptions.length > 1;

  const amountOfInputs = !hasMoreThanOneSelector
    ? 1
    : schoolsOptions.length + 1;

  return (
    <div
      className="flex flex-col md:grid items-center gap-1"
      style={{ gridTemplateColumns: `repeat(${amountOfInputs}, 1fr)` }}
    >
      {schoolsOptions.map(({ name, options }, index: number) => {
        const allowedOptions = options.filter((option) => {
          // Show only selectors allowed by the domain
          if (
            domainSelectors.some((s) =>
              s.options.some(
                (domainSelOption) => domainSelOption.name === option.name
              )
            )
          )
            return true;

          if (selectors?.[name] === option?.name) return true;

          // 1. Don't allow hidden options, unless it's already active
          // If the selector is included in the domainSelectors, show it even if it's hidden
          if (option?.hidden && !allowHiddenOptions) return false;

          // 2. Allow all on first index (layer)
          if (index === 0) return true;

          // 3. Don't allow disabled combinations
          const disabledEntries = Object.entries(option.disabled || {});
          const allowed = disabledEntries.reduce(
            (acc, [selectorName, disabledValues]) => {
              const selectedValue = selectors?.[selectorName] as string;
              const shouldDisallow = disabledValues.includes(selectedValue);
              if (shouldDisallow) return false;
              return acc;
            },
            true
          );
          return allowed;
        });

        if (allowedOptions.length === 0) return null;

        const isThisAllowed = allowedOptions
          .map((option) => option.name)
          .includes((selectors && selectors[name]) as string);

        return (
          <div className="w-full" key={name}>
            <Select
              label={capitalize(name)}
              isDisabled={
                allowedOptions.length === 0 || index - 1 >= activeSelectorIndex
              }
              value={
                (isThisAllowed &&
                  selectors &&
                  selectors[name] &&
                  selectors[name]) ||
                ""
              }
              filterOption={selectFilterOption}
              onChange={(e) => onInputChange(e as string, name, index)}
              placeholder={t("Choose")}
              isSearchable
              options={[
                {
                  label: t("Choose"),
                  value: "",
                  isDisabled: true,
                },
                ...humanSort(allowedOptions.map((o) => o.name)).map(
                  (optionName) => ({
                    label: optionName,
                    value: optionName,
                  })
                ),
              ]}
              dataCy={name}
              className="block w-full"
            />
          </div>
        );
      })}

      {hasMoreThanOneSelector && (
        <Button
          className="self-end text-text-primary w-full"
          htmlType="button"
          type="secondary"
          onClick={() => setSelectors({})}
          icon={faBan}
        >
          {t("Clear")}
        </Button>
      )}
    </div>
  );
};
