export interface Scroll2Options {
  offsetY?: number;
  offsetX?: number;
  behavior?: ScrollBehavior;
  scrollableElement?: Element;
}

export const getScrollableElement = (): Element => {
  // @ts-ignore
  const isRenderedInWebView =
    typeof window !== "undefined" && window.isRenderedInWebView;

  return isRenderedInWebView ? document.body : document.scrollingElement;
};

/**
 * Uses native `elem.scrollTo()` method.
 * Takes correct scrollable element considering device(e.g. is it mobile device or not)
 * if argument `scrollableElement` is falsy.
 */
export const scroll2 = (
  opts: ScrollToOptions,
  scrollableElement?: Element
): void => {
  /** Scrollable element initialized taking into consideration device(e.g. is it mobile device or not) */
  const deviceScrollableElement = scrollableElement || getScrollableElement();

  deviceScrollableElement.scrollTo(opts);
};

/**
 * Scrolls given html element to top with given `offsetY`.
 *
 * Always use `scroll2` for scrolling into view instead of `element.scrollIntoView(..)`.
 * The `scrollIntoView` can't handle multiple scrolls at once.
 * src: https://stackoverflow.com/questions/49318497/google-chrome-simultaneously-smooth-scrollintoview-with-more-elements-doesn
 */
export const scroll2Elem = (element: Element, opts: Scroll2Options = {}) => {
  const {
    offsetY = 0,
    offsetX = 0,
    behavior = "smooth",
    scrollableElement = getScrollableElement(),
  } = opts;

  /** Wait until the UI will be changed and then get values. */
  setTimeout(() => {
    const { top, left } = element.getBoundingClientRect();
    const { scrollTop, scrollLeft } = scrollableElement;

    const destinationY = scrollTop + top - offsetY;
    const destinationX = scrollLeft + left - offsetX;

    scroll2(
      { top: destinationY, left: destinationX, behavior },
      scrollableElement
    );
  });
};
