import { getErrorMessage, validateSelectors } from "@kanpla/system";
import { Child, ChildSelector, Supplier } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { scopeAtom } from "../screens/partials/CanteenId";
import { schoolIdAtom } from "./useSchool";
import { multiSchoolEmailAtom } from "./useSubmitEmail";

type CreateChildType = {
  name: Child["name"];
  selectors: Child["selectors"];
  childIsSalesplace?: Supplier["childIsSalesplace"];
};

export const childAtom = atom<Child>({} as Child);
export const selectorsAtom = atom<ChildSelector>({} as ChildSelector);

export const useChild = () => {
  const {
    auth,
    setChildId,
    isChildSalesplace,
    createChild: createChildFromContext,
  } = useContainer(AppContext);

  const userId = auth?.user?.uid;
  const schoolId = useAtomValue(schoolIdAtom);
  const scope = useAtomValue(scopeAtom);
  const multiSchoolsEmail = useAtomValue(multiSchoolEmailAtom);

  const setChild = useSetAtom(childAtom);

  const createChild = async ({
    name,
    selectors,
    childIsSalesplace = false,
  }: CreateChildType) => {
    try {
      if (!userId || !selectors) return;

      const currentSchoolEmail = multiSchoolsEmail?.find(
        (data) =>
          data.schoolId === schoolId &&
          validateSelectors(selectors, data?.selectors || [])
      );

      // Create child
      const newChild = await createChildFromContext({
        name,
        schoolId,
        selectors,
        userId,
        codes: currentSchoolEmail?.codes,
        isChildSalesplace: isChildSalesplace || childIsSalesplace || false,
        ...(scope ? { scope } : {}),
      });

      setChild(newChild);
      setChildId(newChild?.id);
    } catch (err) {
      console.error(err);
      message.error(getErrorMessage(err));
    }
  };

  return {
    createChild,
  };
};
