import { atom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import LandingWrapper from "../LandingWrapper";
import AutomaticallySetSchool from "./AutomaticallySetSchool";
import Loading from "./Loading";
import MagicLinkListener from "./MagicLinkListener";
import CurrentView from "./screens";

export const pageLoadingAtom = atom<boolean>(false);
export const nameAtom = atom<string>("");
export const isInModalAtom = atom<boolean>(false);
export const throughInvitationAtom = atom<boolean>(false);

interface SignupFlowProps {
  throughInvitation?: boolean;
  showSidebar?: boolean;
  isInModal?: boolean;
}

const RenderContent = ({ pageLoading }: { pageLoading: boolean }) => {
  return pageLoading ? <Loading /> : <CurrentView />;
};

const LandingContent = ({
  isInModal,
  children,
}: {
  isInModal: boolean;
  children: React.ReactNode;
}) => {
  return isInModal ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <LandingWrapper>{children}</LandingWrapper>
  );
};

const SignupFlow = ({
  throughInvitation = false,
  isInModal = false,
}: SignupFlowProps) => {
  const pageLoading = useAtomValue(pageLoadingAtom);
  const setIsInModal = useSetAtom(isInModalAtom);
  const setThroughInvitation = useSetAtom(throughInvitationAtom);

  useEffect(() => {
    setIsInModal(isInModal);
    setThroughInvitation(throughInvitation);

    // eslint-disable-next-line react-hooks-unreliable-deps/reference-deps
  }, [isInModal, throughInvitation]);

  return (
    <AutomaticallySetSchool throughInvitation={throughInvitation}>
      <MagicLinkListener>
        <LandingContent isInModal={isInModal}>
          <RenderContent pageLoading={pageLoading} />
        </LandingContent>
      </MagicLinkListener>
    </AutomaticallySetSchool>
  );
};

export default SignupFlow;
