import { fb } from "../firebase.config";

const useWebPushNotifications = () => {
  const doesBrowserSupportsPush = () =>
    "serviceWorker" in navigator && "PushManager" in window;

  const askPermissions = async () => {
    try {
      const result = await Notification.requestPermission();
      if (result !== "granted") throw new Error("Permissions weren't granted.");

      return true;
    } catch (err) {
      console.error(err);
    }
  };

  const retrieveFCMToken = async () => {
    try {
      // 1. Check browser support
      const supported = doesBrowserSupportsPush();
      if (!supported)
        throw new Error("This broswer does not support Push Notifications.");

      // 2. Ask for permissions
      const allowed = await askPermissions();
      if (!allowed) return;

      // 3. Request token
      const token = await fb?.messaging()?.getToken({
        vapidKey:
          "BM8oW0rMqfxtDWFXDtLoAT9JyiOjmZyxU0YHlyUMVy7jeTkCiYEyTmDswgOoJAIeMZ1mOZxnL-b0vZYS4Ru5W3U",
      });

      return token;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  return {
    retrieveFCMToken,
    doesBrowserSupportsPush,
  };
};

export default useWebPushNotifications;
