import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import classnames from "classnames";
import React from "react";

interface Props {
  title?: string;
  subtitle?: string;
  className?: string;
  /** Provide Font Awesome icon */
  icon?: IconProp;
  /** Text as a help "info" icon */
  help?: string;
  noBg?: boolean;
  noExtraMargin?: boolean;
  required?: boolean;
}

export const ProductSettingsHeader = ({
  title,
  subtitle,
  className = "",
  icon,
  help,
  noBg = false,
  noExtraMargin = false,
  required,
}: Props) => (
  <header
    className={classnames(
      "mb-0 pb-0 flex relative items-center justify-between",
      { "-mx-10 p-4 px-10": !noExtraMargin },
      { "mt-2": noExtraMargin },
      className
    )}
  >
    <div
      className={`absolute opacity-50 z-0 inset-0 ${
        noBg ? "bg-transparent" : "border-t border-divider-main"
      }`}
    />

    <div className="relative">
      <h4 className="text-text-primary font-semibold flex items-center">
        {required ? (
          <span className="font-medium mr-0.5 text-danger-main">*</span>
        ) : null}
        {title}
        {help && (
          <Tooltip title={help} className="ml-1.5 ">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="text-text-disabled"
              size="xs"
            />
          </Tooltip>
        )}
      </h4>
      {subtitle && <p className="text-xs text-text-secondary">{subtitle}</p>}
    </div>
    {icon && (
      <FontAwesomeIcon icon={icon} className="text-text-secondary text-2xl" />
    )}
  </header>
);
