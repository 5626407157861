import { OrderLine, OrderOptions, OverviewOrderLine } from "@kanpla/types";

type MatchesLineOptions = {
  withVatRate: boolean;
};

export interface UniqueOrderLineProps {
  productId?: OrderLine["productId"];
  options?: {
    [choiceId: string]: Pick<OrderOptions, "amount">;
  };
  amount?: OrderLine["amount"];
  vatRate?: OrderLine["vatRate"];
  deliveryTime?: OrderLine["deliveryTime"];
  productLineId?: OrderLine["productLineId"];
}

export const stringifyOptions = (options: UniqueOrderLineProps["options"]) =>
  Object.entries(options || {})
    .map(([id, option]) => `${id.replace(".", "")}${option.amount}`)
    .sort()
    .join();

export const stringifyOrderLine = (
  orderLine: UniqueOrderLineProps,
  options: MatchesLineOptions = { withVatRate: true }
) => {
  const { withVatRate } = options;

  return `${orderLine?.productId}${stringifyOptions(orderLine?.options)}${
    orderLine?.deliveryTime || ""
  }${withVatRate ? orderLine?.vatRate || "" : ""}`;
};

export const stringifyOverviewOrderLine = (orderLine: OverviewOrderLine) => {
  return `${stringifyOrderLine(orderLine)}${JSON.stringify(
    orderLine.textInput
  )}${orderLine.timeInput}${orderLine.moduleId}${orderLine.schoolId}${
    orderLine.childId
  }${orderLine.reference}${orderLine.isAdmin}${orderLine.groupName}${
    orderLine.orderId
  }`;
};

export const matchesLine = (
  orderLine: UniqueOrderLineProps,
  targetLine: UniqueOrderLineProps,
  options: MatchesLineOptions = { withVatRate: true }
) =>
  stringifyOrderLine(orderLine, options) ===
  stringifyOrderLine(targetLine, options);

const stringifyOrderLineWithAmount = (orderLine: UniqueOrderLineProps) =>
  `${orderLine?.amount}${stringifyOrderLine(orderLine)}`;

export const stringifyOrder = (orderLines: UniqueOrderLineProps[]) =>
  orderLines.map(stringifyOrderLineWithAmount).sort().join();

export const hasOrderChanged = (
  before: UniqueOrderLineProps[],
  after: UniqueOrderLineProps[]
) => stringifyOrder(before) !== stringifyOrder(after);
