import { atom, useAtom } from "jotai";

import { DrawerOrModal } from "@kanpla/ui";

import { AUTHENTICATION_MS } from "@kanpla/system";
import { useEffect, useState } from "react";
import Authentication from "../../anonymous/Authentication";

type AuthenticationModalActions = "login" | "signup" | "closed";
export const authModalStateAtom = atom<AuthenticationModalActions>("closed");

const AnonymousModal = () => {
  const [localState, setLocalState] =
    useState<AuthenticationModalActions>("closed");
  const [authModalState, setAuthModalState] = useAtom(authModalStateAtom);

  // Hack to make sure that the modal closes before it opens again
  useEffect(() => {
    setLocalState("closed");
    if (authModalState === "closed") return;

    setTimeout(() => {
      setLocalState(authModalState);
    }, AUTHENTICATION_MS);
    // eslint-disable-next-line react-hooks-unreliable-deps/reference-deps
  }, [authModalState]);

  return (
    <DrawerOrModal
      open={localState !== "closed" && localState === authModalState}
      setOpen={() => setAuthModalState("closed")}
    >
      <Authentication isInModal />
    </DrawerOrModal>
  );
};

export default AnonymousModal;
