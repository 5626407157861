import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { AppContext } from "../../../contextProvider";
import PageWrapper from "../../PageWrapper";
import { currentPageAtom } from "../../lib/usePageNavigation";

const CompanyNotExisting = () => {
  const t = useT();

  const { supplier } = useContainer(AppContext);
  const setCurrentPage = useSetAtom(currentPageAtom);

  return (
    <PageWrapper
      title={t("There is no registered company for your email in the system")}
      buttonTitle=""
      hideButton
      showBackButton
    >
      <div className="text-center pb-12">
        <Button
          type="primary"
          shape="solid"
          onClick={() => setCurrentPage("email")}
          dataCy="btn-signup-other-company-email"
        >
          {t("Use another company email")}
        </Button>
        <p className="text-sm text-text-secondary pt-3">
          {t("Your company email grant you access to order in your canteen.")}
        </p>
      </div>
      <div className="text-center pb-8">
        <Button
          className="px-8"
          shape="solid"
          onClick={() => setCurrentPage("canteenId")}
          dataCy="btn-signup-login-canteen-id"
        >
          {t("Login with your Canteen ID")}
        </Button>
        <p className="text-sm text-text-secondary pt-3">
          {t("You can still access {value} with a code.", {
            value: supplier?.name,
          })}
        </p>
      </div>
    </PageWrapper>
  );
};

export default CompanyNotExisting;
