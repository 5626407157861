import { auth, useT } from "@kanpla/system";
import { ProviderNames } from "@kanpla/types";
import { Button, Form, Input, Spinner, message } from "@kanpla/ui";
import { processProviders } from "apps/frontend/lib/signup/processProviders";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { useAtomValue } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../../contextProvider";
import PageWrapper from "../../PageWrapper";
import { emailAtom, providersAtom } from "../../lib/useSubmitEmail";
import { providerSetup } from "./Provider";

const AccountAlreadyExists = () => {
  const t = useT();

  const email = useAtomValue(emailAtom);
  const providers = useAtomValue(providersAtom);

  const router = useRouter();
  const { auth: authHook } = useContainer(AppContext);

  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState<Array<ProviderNames>>([]);
  const [loadingMethods, setLoadingMethods] = useState(true);
  /** Set the method name to display loading on the button */
  const [processingMethod, setProcessingMethod] = useState<string>(null);

  const submit = async ({ password }: { password: string }) => {
    try {
      setLoading(true);
      await authHook.signIn(email, password);
      setLoading(false);
      router.push("/app");
    } catch (e) {
      setLoading(false);
      message.error(e?.message);
      console.error(e);
    }
  };

  const ForgotPassword = ({ text }) => {
    return (
      <div className="mt-3 text-center pt-4 text-sm">
        <Link href="./resetPassword">
          <span className="cursor-pointer">{text}</span>
        </Link>
      </div>
    );
  };

  const fetchMethods = async () => {
    setLoadingMethods(true);
    const allMethods = await fetchSignInMethodsForEmail(auth, email);
    setMethods(allMethods);
    setLoadingMethods(false);
  };

  useEffect(() => {
    fetchMethods();
  }, []);

  const doProvider = async (providerName: string) => {
    setProcessingMethod(providerName);
    try {
      await processProviders(providerName, providers, email);
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setProcessingMethod(null);
    }
  };

  const hasPassword = methods.includes("password");

  return (
    <PageWrapper
      title={t("You already have an account with this email {value}", {
        value: email,
      })}
      subtitle={
        hasPassword ? t("Enter your password below.") : t("Log in below.")
      }
      buttonTitle={t("Login")}
      hideButton={!hasPassword}
      onButtonSubmit={submit}
      buttonLoading={loading}
      extraContent={
        hasPassword && <ForgotPassword text={t("Forgot password?")} />
      }
      showBackButton
      stayOnPageAfterSubmit
      htmlType="submit"
      dataCy="account-already-exist"
    >
      <>
        {loadingMethods && methods.length === 0 && (
          <Spinner className="mx-auto" size="large" useCurrentColor />
        )}
        {methods
          .filter((m) => m !== "password")
          .map((method) => {
            return (
              <Button
                loading={processingMethod === method}
                key={method}
                onClick={() => doProvider(method)}
                size="large"
                className="flex mx-auto"
                icon={providerSetup[method]?.icon}
                dataCy={`provider-${method}`}
              >
                {t("Login with {value}", {
                  value: providerSetup[method]?.name || method,
                })}
              </Button>
            );
          })}
        {hasPassword && (
          <Form.Item
            name="password"
            rules={{ required: t("Please enter a password") }}
          >
            <Input.Password
              id="password"
              required
              placeholder={t("Password")}
              dataCy="input-passowrd-account-already-exist"
            />
          </Form.Item>
        )}
      </>
    </PageWrapper>
  );
};

export default AccountAlreadyExists;
