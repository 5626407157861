import { PriceExcludingVat, PriceIncludingVat } from "@kanpla/types";
import { usePriceFormatterFunction } from "./usePriceFormatterFunction";

export const usePriceFormatter = (
  unitValue: PriceIncludingVat | PriceExcludingVat
) => {
  const priceFormatterFunction = usePriceFormatterFunction();

  /** Format the price */
  const price = priceFormatterFunction(unitValue);

  return price;
};
