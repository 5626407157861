import { Module, School } from "@kanpla/types";
import { isEmpty, uniq } from "lodash";

export const getAvailableAccessCodes = (
  modules: Array<Module>,
  school: School | null
) => {
  const allAvailableCodes = [
    ...modules
      .filter((module) =>
        module.scope?.generatedSchoolIds?.includes(school?.id || "")
      )
      .flatMap((module) => {
        if (!module || isEmpty(module)) return [];

        const schoolSpecificSettings = school?.modules?.[module.id] || {};

        return [
          ...(module.config?.codes || []),
          ...(module.config?.bulkCodes || []),
          ...(module.config?.productLinesCodes || []),
          ...((!schoolSpecificSettings.individualDisabled &&
            schoolSpecificSettings.codes) ||
            []),
          ...((schoolSpecificSettings.bulkEnabled &&
            schoolSpecificSettings.bulkCodes) ||
            []),
        ];
      }),
  ];

  return uniq(allAvailableCodes);
};
