import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

interface Props {
  title: string;
  icon: IconProp;
  onClick: () => void;
  dataCy?: string;
}

const ChooseCard: React.FC<Props> = ({ title, icon, onClick, dataCy }) => {
  return (
    <div
      className={classNames(
        "w-40 h-36 rounded-md bg-background-primary border-2 cursor-pointer transition-all duration-75 ease-in-out",
        "flex flex-col justify-center items-center",
        "border-secondary-main hover:border-primary-main hover:text-primary-main group hover:shadow-sm"
      )}
      onClick={onClick}
      data-cy={dataCy}
    >
      <FontAwesomeIcon icon={icon} size="lg" />
      <h1 className="text-lg mt-2 text-text-primary group-hover:text-primary-main">
        {title}
      </h1>
    </div>
  );
};

export default ChooseCard;
