import { useT, useWindowSize } from "@kanpla/system";
import { SelectTypes } from "@kanpla/types";
import { default as classNames } from "classnames";
import { isArray } from "lodash";
import React, { ReactNode, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { OptionsOrGroups, default as ReactSelect } from "react-select";
import { Loader } from "../../../antd/Loader";
import { BaseInputProps } from "../BaseInput";
import ClearIndicator from "./ClearIndicator";
import DropdownIndicator from "./DropdownIndicator";
import IndicatorsContainer from "./IndicatorsContainer";
import LoadingIndicator from "./LoadingIndicator";
import NoOptionsMessage from "./NoOptionsMessage";
import Option from "./Option";

interface InputSelectProps
  extends Omit<BaseInputProps, "value" | "onChange" | "disabled">,
    Omit<SelectTypes.ReactSelectProps, "onChange" | "loadingMessage">,
    Pick<SelectTypes.AdditionalProps, "noOptionsContent" | "noOptionsIcon"> {
  options?: OptionsOrGroups<SelectTypes.Option, any>;
  onChange?: (value: any) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  required?: boolean;
  dropdownZIndex?: 10 | 20 | 30 | 40 | 50 | 60;
  loadingMessage?: ReactNode;
  dropdownWidth?: number;
}

const BaseSelect = (props: InputSelectProps): JSX.Element => {
  const t = useT();

  const {
    style,
    options = [],
    color = "primary",
    design = "bordered",
    className = "",
    value = undefined,
    label = null,
    onChange = null,
    placeholder = null,
    filterOption = null,
    dataCy = null,
    noOptionsContent = null,
    noOptionsIcon = null,
    isDisabled = false,
    required = false,
    error = false,
    isLoading = false,
    isClearable = false,
    isSearchable = false,
    loadingMessage = <Loader loaderClasses="!text-gray-400 text-xl" />,
    dropdownWidth,
    menuPlacement = "auto",
    ...rest
  } = props;

  const [_isHovered, _setIsHovered] = useState(false);

  const selectRef = useRef<HTMLDivElement>(null);
  const height = selectRef?.current?.clientHeight;

  const { width: screenWidth = 0 } = useWindowSize();
  const mobile = isMobile || (screenWidth || 1024) < 768;

  const colorWithError = error ? "danger" : color;

  const classes = classNames(
    // bg-input-primary, bg-input-secondary, outline-danger-focus, outline-primary-focus, outline-secondary-focus, border-primary-focus, border-danger-focus, border-secondary-focus
    `bg-input-${color} select-none !cursor-pointer !rounded-md py-1 md:py-2 px-3 h-14 md:h-auto items-center md:justify-between outline-${colorWithError}-focus border-${colorWithError}-focus border-${colorWithError}-hover transition-all ease-in-out md:my-1`,
    { "border border-danger-main": error },
    {
      [`shadow-sm border border-transparent`]: design === "lighter",

      [`border border-divider-main shadow-sm`]: design === "bordered",
    },
    { "opacity-50 cursor-not-allowed": isDisabled },
    className
  );

  const customClassNames: SelectTypes.ClassNames = {
    control: () => classes,
    placeholder: () => "text-gray-500",
    indicatorSeparator: () => "text-gray-500",
    menu: () => `my-2 rounded-md border !shadow-xl !w-full bg-input-${color}`,
  };

  /**
   * Value represents the object { value, label }.
   * Value will be used to select the option and label will be used to display the value just selected.
   */
  const valueProp =
    value === undefined
      ? {}
      : isArray(value)
      ? value.map((v) => ({
          value: v,
          label: options?.find((o) => o?.value === v)?.label || "",
        }))
      : {
          value: {
            value,
            label: options?.find((o) => o?.value === value)?.label || "",
          },
        };

  return (
    <>
      <div
        className="inline-flex flex-col w-full"
        style={style}
        data-cy={dataCy}
      >
        {label && !mobile && (
          <label className="form-label mb-1">
            {required ? (
              <span className="font-medium mr-0.5 text-danger-main">*</span>
            ) : null}
            {label}
          </label>
        )}
        <div
          onMouseEnter={() => _setIsHovered(true)}
          onMouseLeave={() => _setIsHovered(false)}
          ref={selectRef}
          className="relative"
        >
          <ReactSelect
            filterOption={filterOption}
            onChange={(opt: unknown) => {
              // Handle multi-select
              if (isArray(opt)) {
                const option = opt as Array<SelectTypes.Opt>;
                onChange?.(option?.map((o) => o.value));
                return;
              }

              const option = opt as SelectTypes.Opt;
              onChange?.(option?.value);
            }}
            options={options}
            classNames={customClassNames}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                top: menuPlacement === "top" ? 0 : height,
                left: 0,
                position: "absolute",
                ...(dropdownWidth && { width: `${dropdownWidth}px` }),
              }),
            }}
            isSearchable={isSearchable}
            isClearable={isClearable}
            loadingMessage={() => (
              <div className="py-6 px-3">{loadingMessage}</div>
            )}
            formatGroupLabel={(data: GroupedOption) => (
              <div className="text-text-secondary pt-3 pb-1 uppercase text-sm px-3">
                {data.label}
              </div>
            )}
            components={{
              Option: (props) => (
                <Option {...props} setIsHovered={_setIsHovered} />
              ),
              DropdownIndicator: (props) => (
                <DropdownIndicator
                  {...props}
                  isHovered={_isHovered}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  isLoading={isLoading}
                />
              ),
              ClearIndicator: (props) => (
                <ClearIndicator {...props} isHovered={_isHovered} />
              ),
              IndicatorsContainer: (props) => (
                <IndicatorsContainer {...props} isHovered={_isHovered} />
              ),
              LoadingIndicator,
              NoOptionsMessage: (props) => (
                <NoOptionsMessage
                  {...props}
                  noOptionsContent={noOptionsContent}
                  noOptionsIcon={noOptionsIcon}
                />
              ),
            }}
            placeholder={placeholder || t("Select...")}
            isLoading={isLoading}
            isDisabled={isDisabled}
            unstyled
            classNamePrefix="kanpla_select"
            menuPosition="fixed"
            menuPlacement={menuPlacement}
            /**
             * If value is undefined, we don't want to pass it to the component
             * and make the component display the correct selections.
             */
            {...valueProp}
            {...rest}
          />
        </div>
      </div>
      <style>{`.kanpla_select__indicator-separator { display: none }`}</style>
    </>
  );
};

type CompoundedSelectType = typeof BaseSelect;

export const Select = BaseSelect as CompoundedSelectType;
