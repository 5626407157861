import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useContainer } from "unstated-next";

import { calculateVatRate, createDiscountsFromBasket } from "@kanpla/system";

import { initialAllowanceUsagesAtom } from "../../../../../ordering/src/context";
import { basketAtom } from "../../../../src/shared/basket/useBasket";
import { OrderingContext } from "../../../context";

export const useOrderDiscounts = () => {
  const { offer, moduleId, module, school } = useContainer(OrderingContext);
  const basket = useAtomValue(basketAtom);
  const initialAllowanceUsages = useAtomValue(initialAllowanceUsagesAtom);

  const vatRate = calculateVatRate({ module, school, productLine: null });

  const orderDiscounts = useMemo(
    () =>
      createDiscountsFromBasket({
        basket,
        allowanceUsages: initialAllowanceUsages,
        discounts: offer.discounts.filter(
          (d) => d.type !== "hybridBilling" && d.moduleIds.includes(moduleId)
        ),
        showInclusiveVat: module.paymentMethod === "credit",
        vatRate,
      }),
    [
      JSON.stringify(basket),
      offer.discounts.length,
      initialAllowanceUsages.length,
    ]
  );

  return orderDiscounts;
};
