import { useWindowSize } from "@kanpla/system";
import classNames from "classnames";
import React, {
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  useMemo,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { BaseInputProps } from "./BaseInput";

interface BaseTextAreaProps extends Omit<BaseInputProps, "onChange"> {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  /** Shows a counter indicating the number of characters allowed */
  showCount?: boolean;
  /** The limit of how many characters can be written into the textarea */
  maxChars?: number;
  /** Control if and how the textarea should be resized, defaults to `none` */
  resize?: "none" | "x" | "y";
}

type TextAreaProps = BaseTextAreaProps &
  Omit<
    DetailedHTMLProps<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    "maxLength"
  >;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref): JSX.Element => {
    const {
      color = "primary",
      error = false,
      showCount = false,
      maxChars = Infinity,
      className = "",
      disabled,
      dataCy,
      onChange,
      value,
      label,
      rows = 3,
      resize = "none",
      required = false,
      ...rest
    } = props;

    const [localValue, setLocalValue] = useState<string>(value || "");

    const { width: screenWidth } = useWindowSize();
    const mobile = isMobile || (screenWidth || 0) < 768;

    const charCount = useMemo(() => localValue?.length || 0, [localValue]);

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLocalValue(() => {
        onChange?.(e);
        return e.target.value;
      });
    };

    const classes = classNames(
      // bg-input-primary, bg-input-secondary
      "w-full h-full pt-3.5 md:pt-2 pb-2 relative border border-divider-main shadow-inner",
      `bg-input-${color} text-text-${color} select-none rounded-md h-14 md:h-auto px-3 flex items-center outline-${
        error ? "danger" : color
      }-focus border-${error ? "danger" : color}-focus`,
      { "border-danger-main": error },
      { "opacity-50": disabled },
      className
    );

    return (
      <div className="w-full flex flex-col mt-3">
        {!mobile && label && (
          <label className="form-label mb-2" htmlFor={rest.name}>
            {required ? (
              <span className="font-medium mr-0.5 text-danger-main">*</span>
            ) : null}
            {label}
          </label>
        )}
        <div className={classNames("", classes)}>
          {mobile && label && (
            <label
              className="form-label absolute left-2 top-1 text-sm mb-2"
              htmlFor={rest.name}
            >
              {required ? (
                <span className="font-medium mr-0.5 text-danger-main">*</span>
              ) : null}
              {label}
            </label>
          )}
          <textarea
            rows={rows}
            className={classNames(
              "w-full h-full mt-2 md:mt-0 outline-none",
              resize === "none"
                ? "!resize-none"
                : resize === "x"
                ? "!resize-x"
                : "!resize-y"
            )}
            ref={ref}
            value={localValue}
            onChange={handleOnChange}
            maxLength={maxChars}
            {...rest}
          />
        </div>
        {showCount && (
          <span className="w-full text-right mt-1">
            {maxChars !== Infinity ? `${charCount}/${maxChars}` : charCount}
          </span>
        )}
      </div>
    );
  }
);
