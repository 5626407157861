import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { ButtonProps } from "antd";
import React from "react";

interface Props extends Partial<ButtonProps> {
  text?: string;
  loadingText?: string;
  className?: string;
}

export const ButtonSubmit = (props: Props) => {
  const t = useT();

  const {
    text = t("Confirm"),
    loading,
    onClick,
    htmlType = "submit",
    className,
    disabled = false,
  } = props;

  return (
    <Button
      type="primary"
      id="sign-up"
      size="large"
      onClick={onClick}
      htmlType={htmlType}
      className={`w-full mt-5 ${className}`}
      disabled={disabled}
      loading={loading as boolean}
    >
      {text}
    </Button>
  );
};

// export default ButtonSubmit
