import { isStaging, useLocationHostname } from "@kanpla/system";
import { domainSignupConfig, domainsWithPublicSchools } from "./domains";

export const useHasPublicSchools = () => {
  // Get hostname
  const hostname = useLocationHostname({});

  if (!hostname)
    return {
      hasPublicSchools: null,
      hasSchoolSwitch: null,
      hasSchoolList: null,
    };

  // Put it all together
  const hasPublicSchools =
    domainsWithPublicSchools.includes(hostname) || isStaging;
  const hasSchoolSwitch =
    domainSignupConfig.switch.includes(hostname) || isStaging;
  const hasSchoolList = domainSignupConfig.list.includes(hostname);

  return { hasPublicSchools, hasSchoolSwitch, hasSchoolList };
};
