import classNames from "classnames";
import { ReactNode } from "react";

export type PageHeaderProps = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  titleDataCy?: string;
  titleSize?: "h500" | "h600" | "h700" | "title-main" | "h800";
};

const PageHeader = ({
  title,
  titleDataCy = "signup-screen-generic-title",
  subtitle,
  titleSize = "h700",
}: PageHeaderProps) => {
  return (
    <div>
      <h1
        className={classNames(
          "text-center text-primary-main font-semibold text-3xl"
          // titleSize
        )}
        data-cy={titleDataCy}
      >
        {title}
      </h1>
      {subtitle && (
        <p className="text-center text-md mt-1 text-text-secondary">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default PageHeader;
