import { Child, Module, School } from "@kanpla/types";
import { intersection } from "lodash";

interface Props {
  child: Child;
  school: Pick<School, "modules" | "id" | "hasFlexBulk" | "selectors">;
  module: Pick<Module, "scope" | "config" | "id">;
  /** Module doesn't need to have active bulk or individual for it to work. Could be useful e.g. for export and statistics */
  ignoreActive?: boolean;
}

export const hasAccessThroughModuleSelectors = (
  child: Child,
  module: Pick<Module, "scope">,
  school: Pick<School, "id">
) => {
  const moduleSelectors =
    module?.scope?.schools?.[school?.id]?.selectorStrings || [];
  const childSelector = child?.groupName;

  const hasSameSelectors = moduleSelectors.includes(childSelector);

  return !moduleSelectors.length || Boolean(hasSameSelectors);
};

export const getChildCodes = (
  child: Child,
  school?: Pick<School, "selectors">
) => {
  const selectorEntries = Object.entries(
    child?.selectors || ({} as Child["selectors"])
  );

  const selectorCodes = selectorEntries
    .map(([layerName, optionName]) => {
      const targetLayer = school?.selectors?.find(
        (layer) => layer.name === layerName
      );
      const targetOption = targetLayer?.options?.find(
        (o) => o.name === optionName
      );
      const optionCodes = targetOption?.codes || [];
      return optionCodes;
    })
    .flat();

  const allChildCodes = [...(child?.codes || []), ...selectorCodes];

  return allChildCodes;
};

export type HasAccessType = {
  individual: boolean;
  bulk: boolean;
  other: boolean;
};

export const hasAccessToModule = (props: Props): HasAccessType => {
  const { module, child, school, ignoreActive = false } = props;

  if (module && !module.scope?.generatedSchoolIds?.includes(school?.id))
    return { individual: false, bulk: false, other: false };

  const moduleConfig = module?.config || {};
  const schoolModuleConfig = school?.modules?.[module?.id || "—"] || {};

  const childCodes = getChildCodes(child, school);

  // Disallow user access to module if they don't fit selectors
  const selectorAccess = hasAccessThroughModuleSelectors(child, module, school);
  if (!selectorAccess) return { individual: false, bulk: false, other: false };

  // Evaluate codes
  const codes = [
    ...(moduleConfig.codes || []),
    ...(schoolModuleConfig.codes || []),
  ];

  const bulkCodes = [
    ...(moduleConfig.bulkCodes || []),
    ...(schoolModuleConfig.bulkCodes || []),
  ];

  const validCodes = (targetCodes: Array<string>) =>
    targetCodes.length === 0 ||
    intersection(targetCodes, childCodes).length > 0;

  // Evaluate enabled/disabled
  const individualDisabled =
    schoolModuleConfig.individualDisabled ?? moduleConfig.individualDisabled;

  const bulkEnabled =
    schoolModuleConfig.bulkEnabled ||
    moduleConfig.bulkEnabled ||
    school?.hasFlexBulk;

  // Both must be true
  const allowIndividual =
    (!individualDisabled || ignoreActive) && validCodes(codes);
  const allowBulk = (bulkEnabled || ignoreActive) && validCodes(bulkCodes);
  const allowOther = validCodes(codes);

  return {
    individual: allowIndividual,
    bulk: allowBulk,
    other: allowOther,
  };
};
