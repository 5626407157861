import { OrderingContext } from "@kanpla/ordering";
import { useSetAtom } from "jotai";
import { FC, useMemo } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import { authModalStateAtom } from "./modals/Anonymous/AnonymousModal";

const OrderingContextWrapper: FC = ({ children }) => {
  const appContextData = useContainer(AppContext);
  const setAuthModalState = useSetAtom(authModalStateAtom);

  const initialState = useMemo(
    () => ({
      ...appContextData,
      onAuthClick: (action) => setAuthModalState(action),
    }),
    [appContextData]
  );

  return (
    <OrderingContext.Provider initialState={initialState}>
      {children}
    </OrderingContext.Provider>
  );
};

export default OrderingContextWrapper;
