import { faCoin } from "@fortawesome/pro-duotone-svg-icons";
import { useT } from "@kanpla/system";
import { Alert, Button, Space, usePriceFormatter } from "@kanpla/ui";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { basketAtom } from "../../shared/basket/useBasket";
import { useOrderPriceBreakdown } from "../../shared/basket/useOrderPriceBreakdown";

interface AlertProps {
  balance: number;
  cost: number;
}

export const openPaymentModalAtom = atom(false);

const PaidAlert = ({ balance, cost }: AlertProps) => {
  const t = useT();

  const basket = useAtomValue(basketAtom);
  const { totalDiscountAmount } = useOrderPriceBreakdown({
    orderLines: basket,
  });

  const balanceFormatted = usePriceFormatter(balance);

  const missingAmount = cost - balance;
  const missingAmountFormatted = usePriceFormatter(
    missingAmount - totalDiscountAmount
  );

  const openPaymentModal = useSetAtom(openPaymentModalAtom);

  if (cost > balance)
    return (
      <Space direction="vertical">
        <Alert
          type="warning"
          message={`${t("Not enough credit")}! ${t(
            "You are missing {value} in your account.",
            { value: missingAmountFormatted }
          )}`}
        />
        <Button
          type="primary"
          className="w-full"
          size="large"
          icon={faCoin}
          dataCy="btn-kanpla-go-refuel"
          onClick={() => openPaymentModal(true)}
        >
          {t("Recharge here")}
        </Button>
      </Space>
    );

  return (
    <Alert
      type="info"
      message={t("Paid from balance ({value} left)", {
        value: balanceFormatted,
      })}
    />
  );
};

const PaidFromCredit = () => {
  return null;
};

PaidFromCredit.Alert = PaidAlert;

export default PaidFromCredit;
