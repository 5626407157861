import {
  CombinedOfferItem,
  _FrontendModule,
  _GeneratedSchool,
} from "@kanpla/types";

/**
 * calculateVatRate then takes in all the other factors such as product, module,
 * salesplace etc. to get the final VAT rate for the order.
 */
type Props = {
  module: _FrontendModule;
  /** Pass null if you want generic VAT rate for the module. It won't take product VAT rate into account! */
  productLine: CombinedOfferItem | null;
  school: Pick<
    NonNullable<_GeneratedSchool["school"]>,
    "defaultVatRate" | "vatRate"
  > | null;
  groupName?: string;
};

export const calculateVatRate = ({ module, productLine, school }: Props) => {
  if (!school) return 0.25;

  // 1. Extract VAT rate from provided documents
  const { defaultVatRate, vatRate: schoolRate } = school;
  const moduleRate = module.vatRate;
  const productLineRate = productLine?.vatRate;
  const groupNameRate = null;

  // 2. Prioritize
  const rates = [
    groupNameRate,
    schoolRate,
    productLineRate,
    moduleRate,
    defaultVatRate,
    0.25,
  ];
  const validRates = rates.filter((vatRate) => typeof vatRate === `number`);
  const finalVatRate = validRates[0];

  return finalVatRate as number;
};
