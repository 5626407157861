import { DomainInfo } from "@kanpla/types";

interface Props {
  domainsInfo: DomainInfo[];
  schoolId: string;
}

/**
 * This method get unique codes from a signup domain.
 */
export const getUniqueCodes = ({ domainsInfo = [], schoolId }: Props) => {
  /** Get codes from the domains belonging to the current school */
  const codes = domainsInfo
    .filter((domain) => domain.schoolId === schoolId)
    .reduce(
      (acc: DomainInfo["codes"], domain) => [
        ...(acc || []),
        ...(domain.codes || []),
      ],
      []
    );

  /** Get unique codes */
  const uniqueCodes = [...new Set(codes)];

  return uniqueCodes;
};
