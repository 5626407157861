import { useT } from "@kanpla/system";
import { Alert, Checkbox } from "@kanpla/ui";
import { atom, useAtom, useAtomValue } from "jotai";
import { ReactNode } from "react";
import { useContainer } from "unstated-next";

import { OrderingContext } from "../../../context";
import {
  basketAtom,
  basketResultedPriceAtom,
  hasEnoughMoneyToPayBasketAtom,
} from "../../../shared/basket/useBasket";
import { useOrderPriceBreakdown } from "../../../shared/basket/useOrderPriceBreakdown";
import DisplayDefaultCard from "../../../shared/payment/DisplayDefaultCard";
import PaidFromCredit from "../../kanplaGo/PaidFromCredit";
import { selectedPaymentMethodAtom } from "./selectedPaymentMethodAtom";

export const rememberCardAtom = atom<boolean>(true);

const PaymentWrapper = ({ children }: { children: ReactNode }) => {
  const t = useT();

  return (
    <div className="flex w-full pt-1 md:pt-2 flex-col">
      <h2 className="heading-main pb-3 text-left">{t("Payment")}</h2>
      {children}
    </div>
  );
};

const Payment = () => {
  const [rememberCard, setRememberCard] = useAtom(rememberCardAtom);

  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const hasEnoughMoneyToPayBasket = useAtomValue(hasEnoughMoneyToPayBasketAtom);
  const basketContainerTotalPrice = useAtomValue(basketResultedPriceAtom);
  const basket = useAtomValue(basketAtom);

  const { totalPrice } = useOrderPriceBreakdown({ orderLines: basket });

  const t = useT();

  const { activePlugins, balance, card, module, isOrderInvoiced, fromAdmin } =
    useContainer(OrderingContext);

  const hasOnlyCredit =
    !activePlugins["kanplaGo"] && !activePlugins["payPerOrder"];
  const paysFromCredit =
    (hasOnlyCredit || hasEnoughMoneyToPayBasket) &&
    module.paymentMethod === "credit";

  // Will hide the Payment component, in favor of the Select
  const hidePayment = module.paymentMethod !== "billing" && !hasOnlyCredit;

  if (basketContainerTotalPrice === 0 || hidePayment) return null;

  if (totalPrice === 0) return null;

  if (module.paymentMethod === "credit" && fromAdmin)
    return (
      <PaymentWrapper>
        <Alert type="info" message={t("It is not possible to add products")} />
      </PaymentWrapper>
    );

  if (paysFromCredit)
    return (
      <PaymentWrapper>
        <PaidFromCredit.Alert
          balance={balance || 0}
          cost={basketContainerTotalPrice}
        />
      </PaymentWrapper>
    );

  if (module.paymentMethod === "billing" && isOrderInvoiced)
    return (
      <PaymentWrapper>
        <Alert
          type="warning"
          message={t(
            "This order has been delivered and billed. Please create a new order using “Add new order”"
          )}
        />
      </PaymentWrapper>
    );

  if (module.paymentMethod === "billing")
    return (
      <PaymentWrapper>
        <Alert
          type="info"
          message={t("The order is invoiced to your company")}
        />
      </PaymentWrapper>
    );

  return (
    <PaymentWrapper>
      <DisplayDefaultCard />
      {selectedPaymentMethod === "card" && !card && (
        <div className="flex justify-center mt-2">
          <Checkbox
            onChange={(e) => setRememberCard(e.target.checked)}
            checked={rememberCard}
            className="text-text-primary"
          >
            {t("Save the card for later payments")}
          </Checkbox>
        </div>
      )}
    </PaymentWrapper>
  );
};

export default Payment;
