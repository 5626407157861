import { Module, _FrontendModule } from "@kanpla/types";

export const sortModules = <T extends Module | _FrontendModule>(
  modules: Array<T>
) => {
  const conditionObj: { [key: string]: (m: T) => boolean } = {
    homescreen: (m) => m.type === "homescreen",
    menu: (m) => m.type === "flex" && m.flow === "menuPreview",
    kuverter: (m) =>
      m.type === "flex" && (m.flow === "lunchOrdering" || !m.flow),
    registration: (m) => m.type === "mealplan" && m.flow === "registering",
    shop: (m) => m.type === "mealplan" && (m.flow === "oneClick" || !m.flow),
    meeting: (m) => m.type === "mealplan" && m.flow === "meeting",
    subscription: (m) => m.type === "subscription",
    pos: (m) => m.type === "pos",
  };

  const reducedModule = Object.entries(conditionObj).reduce(
    (acc, [key, condition]) => {
      const filteredModules = modules.filter((m) => condition(m));
      return {
        ...acc,
        [key]: filteredModules,
      };
    },
    {} as { [key: string]: Array<T> }
  );

  const sortedModules = Object.values(reducedModule)
    .map((m) => m.sort((a, b) => a.name.localeCompare(b.name)))
    .flat();

  return sortedModules;
};
