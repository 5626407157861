import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Language, Supplier, _FrontendSupplier } from "@kanpla/types";
import { Tooltip } from "antd";
import classnames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";
import { getAttribute } from "./getAttribute";

export type DisplayDescriptionProps = {
  allergenName: string;
  /** For example 'text-text-secondary' */
  colorClassName?: string;
  supplier?: Supplier | _FrontendSupplier | null;
  layout?: "horizontal" | "vertical";
  onlyIcon?: boolean;
  onlyName?: boolean;
  className?: string;
  size?: "small" | "normal" | "large";
  disabled?: boolean;
  /**
   * - Returns an SVG that is parsed via IMG tag only
   * - Issue: https://www.notion.so/kanpla/Menu-doesn-t-print-with-custom-allergenes-bac7c108d705405387f22b266d093284
   */
  disableSvg?: boolean;
  /** Different modes:
   * - Tag shows only attributes with displayMode tags (as labels)
   * - Description shows only attributes with displayMode description (as allergens)
   * - All shows all attributes (as allergens) */
  mode?: "description" | "tag" | "all";
  fontSizePx?: number;
  noPadding?: boolean;
  language?: Language;
};

export const DisplayAttribute = ({
  allergenName,
  colorClassName = "text-text-primary",
  supplier = null,
  layout = "horizontal",
  onlyIcon = false,
  onlyName = false,
  className,
  size = "normal",
  disabled = false,
  disableSvg = false,
  mode = "description",
  noPadding = false,
  fontSizePx,
  language,
}: DisplayDescriptionProps) => {
  const {
    name,
    type,
    background = "#64748b",
    faIcon,
  } = getAttribute({ supplier, getAll: true, key: allergenName, language });

  const customBrandingImage =
    supplier?.custom?.icons?.[type]?.[allergenName?.split(":")?.[0]];
  const image = `https://storage.googleapis.com/kanpla-87be3.appspot.com/allergenIcons/${allergenName}.svg`;

  const wrapperSizes = {
    small: "text-xs py-0.5 px-1",
    normal: "text-sm py-1 px-1.5",
    large: "text-base py-1 px-2",
  };

  const iconSizes = {
    small: "w-4 h-4",
    normal: "w-5 h-5",
    large: "w-8 h-8",
  };

  const labelSizes = {
    small: "pl-1",
    normal: "pl-1.5",
    large: "pl-1.5",
  };

  const finalColor = mode === "tag" ? "text-white" : colorClassName;

  // Icon
  const imgContent = <img src={customBrandingImage || image} alt={name} />;
  const svgContent = (
    <SVG
      src={customBrandingImage || image}
      width={"100%"}
      height={"100%"}
      className={classnames(disabled ? "opacity-40" : "")}
    >
      <img src={customBrandingImage || image} alt={name} />
    </SVG>
  );

  const customIconContent = disableSvg ? imgContent : svgContent;
  const shouldUseFontAwesome = faIcon && !customBrandingImage;
  /**
   * We need to generate font awesome as font, when printing using puppeteer
   * https://github.com/puppeteer/puppeteer/issues/2556
   */
  const fontAwesomeIcon = shouldUseFontAwesome ? (
    disableSvg ? (
      <i className={classnames(`fa-${faIcon?.iconName}`, "fa-solid")} />
    ) : (
      <FontAwesomeIcon icon={faIcon} imageRendering="jpg" />
    )
  ) : null;

  const iconSizeStyle: React.CSSProperties | undefined = fontSizePx
    ? {
        height: `${fontSizePx}px`,
        width: `${fontSizePx}px`,
        lineHeight: `${fontSizePx}px`,
      }
    : undefined;

  const textSizeStyle: React.CSSProperties | undefined = fontSizePx
    ? {
        fontSize: `${fontSizePx}px`,
        lineHeight: `${Math.floor(fontSizePx * 1.2)}px`,
      }
    : undefined;

  return (
    <span
      key={allergenName}
      className={classnames(
        "inline-flex items-center justify-center",
        wrapperSizes[size],
        finalColor,
        layout === "vertical" && "flex-col",
        className
      )}
      style={{ background: mode === "tag" ? background : "" }}
    >
      {!onlyName && (
        <Tooltip title={onlyIcon && name}>
          <div
            style={textSizeStyle}
            className={classnames(disabled ? "opacity-40" : "", finalColor)}
          >
            {/* Keep SVG import, so the icons keep the right colors */}
            {shouldUseFontAwesome ? (
              fontAwesomeIcon
            ) : (
              <div
                style={iconSizeStyle}
                className={classnames(iconSizes[size])}
              >
                {customIconContent}
              </div>
            )}
          </div>
        </Tooltip>
      )}
      {!onlyIcon && (
        <div
          className={classnames(
            "inline-block font-medium whitespace-wrap text-left",
            labelSizes[size]
          )}
          style={textSizeStyle}
        >
          {name}
        </div>
      )}
    </span>
  );
};
