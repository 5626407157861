import { callInternalApi } from "@kanpla/system";
import { useCallback, useMemo, useState } from "react";
import useSWR, { Key, SWRConfiguration, useSWRConfig } from "swr";
import { APICallOptions } from "./callInternalApi";

interface Config<RequestData, ResponseType>
  extends SWRConfiguration,
    APICallOptions {
  customFetcher?: (req: RequestData) => Promise<ResponseType>;
}

export const useFetch = <RequestData extends Record<any, any>, ResponseType>(
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string | null,
  /** Data passed as props to the api */
  requestData: RequestData,
  /** Extra configuration for the fetching, follows SWR config */
  config: Config<RequestData, ResponseType> = {}
  /** Custom fetcher to overwrite callInternalApi */
) => {
  const { mutate } = useSWRConfig();
  const [loading, setLoading] = useState(false);

  const fullKey: Key = useMemo(
    () => ({ ...requestData, path }),
    [requestData, path]
  );

  type Fetcher = (req: RequestData) => Promise<ResponseType>;

  const hasCustomFetcher = typeof config?.customFetcher !== "undefined";
  const fetcher: Fetcher = useCallback(
    async (keys: RequestData) => {
      try {
        setLoading(true);
        if (hasCustomFetcher) return await config?.customFetcher(keys);
        return await callInternalApi<RequestData, ResponseType>(
          path,
          keys,
          config
        );
      } finally {
        setLoading(false);
      }
    },
    [hasCustomFetcher, config, path]
  );

  // Add path to make it unique
  // @ts-ignore
  requestData.path = path;

  // Fetch
  const { data, isValidating, error } = useSWR<ResponseType>(fullKey, fetcher, {
    refreshInterval: 10 * 60 * 1000,
    onErrorRetry: (error) => {
      if (error.status === 404) return;
    },
    ...config,
  });

  // Update
  const setData = useCallback(
    <NewDataType>(
      /** Data as your expect to receive it from the updater */
      newData: NewDataType,
      /** The actual updater ('submit' from useSubmit hook) */
      updater?: () => Promise<NewDataType>,
      /** Transforms the data into a partial that can be merge with the current data */
      dataTransformer?: (
        newData: NewDataType,
        optimistic?: boolean
      ) => Partial<ResponseType>
    ) => {
      const mergeData = (partial: NewDataType, optimistic: boolean) => ({
        ...data,
        ...(typeof dataTransformer === "function"
          ? dataTransformer(partial, optimistic)
          : newData),
      });

      const fullUpdater = async () => {
        const result =
          typeof updater === "function" ? await updater() : newData;
        return mergeData(result, false);
      };

      mutate(fullKey, fullUpdater, {
        optimisticData: mergeData(newData, true),
        revalidate: false,
        rollbackOnError: true,
      });
    },
    [data, fullKey, mutate]
  );

  const reload = () => {
    mutate(path);
  };

  return { data, isValidating, setData, loading, reload, error };
};
