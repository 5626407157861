import { groupBy } from "lodash";

import { OrderDiscount, OrderDiscountItem } from "@kanpla/types";

interface MergeDiscountsArgs {
  orderDiscounts: OrderDiscount[];
}

export const mergeDiscounts = ({ orderDiscounts }: MergeDiscountsArgs) => {
  // Merge all discounts
  const groupedDiscounts = groupBy(
    orderDiscounts,
    (discount) => discount.discountId
  );

  const mergedDiscounts = Object.values(groupedDiscounts).map<OrderDiscount>(
    (discounts) => {
      const firstDiscount = discounts[0];

      const allItems = discounts.flatMap((discount) => discount.items);
      const mergeItems = allItems.reduce<OrderDiscountItem[]>((acc, item) => {
        const existingItem = acc.find(
          (accItem) => accItem.productLineId === item.productLineId
        );

        if (!existingItem) return [...acc, item];

        return acc.map((accItem) => {
          if (accItem.productLineId !== item.productLineId) return accItem;

          return {
            ...accItem,
            amount: accItem.amount + item.amount,
          };
        });
      }, []);

      const mergedDiscount: OrderDiscount = {
        ...firstDiscount,
        items: mergeItems,
        unitDiscountedAmount: discounts.reduce(
          (acc, discount) => acc + discount.unitDiscountedAmount,
          0
        ),
      };

      return mergedDiscount;
    }
  );

  return mergedDiscounts;
};
