import {
  AllowanceUsage,
  Discount,
  OrderDiscount,
  OrderLines,
} from "@kanpla/types";

import { calculateOrderTotal } from "../../vat/calculateOrderTotal";
import { findAllowanceForProductId } from "./findAllowanceForProductId";
import { mergeDiscounts } from "./mergeDiscounts";

interface CreateDiscountsFromBasketArgs {
  basket: OrderLines;
  allowanceUsages: AllowanceUsage[];
  discounts: Discount[];
  showInclusiveVat: boolean;
  vatRate: number;
}

export const createDiscountsWithAllowance = ({
  basket,
  allowanceUsages,
  discounts,
  showInclusiveVat,
  vatRate: moduleVatRate,
}: CreateDiscountsFromBasketArgs) => {
  const remainingAllowance: Record<string, number> = {};

  // Convert `orderLines` to `orderDiscounts`
  const orderDiscounts = basket.reduce<OrderDiscount[]>((acc, orderLine) => {
    const targetAllowance = findAllowanceForProductId(
      orderLine.productId,
      allowanceUsages
    );

    if (!targetAllowance || !targetAllowance?.discountId) return acc;

    const targetDiscount = discounts.find(
      (discount) => discount.id === targetAllowance?.discountId
    );

    if (!targetDiscount || !targetAllowance?.id) return acc;

    const {
      discount: { amount: discountAmount },
    } = targetDiscount;

    // Base order discount
    const orderDiscountBase: Partial<OrderDiscount> = {
      isBillable: true,
      alreadyDiscounted: false,
      discountId: targetAllowance.discountId,
      discountName: targetDiscount.name,
      discountType: "discount-hybridBilling",
    };

    // Get full price of the order line
    const orderLinePrice = calculateOrderTotal([orderLine], showInclusiveVat);

    // Get price after applying discount
    const discountedOrderLinePrice =
      orderLinePrice - (discountAmount * orderLinePrice) / 100;

    // How much allowance is left
    const remainingAllowanceForDiscount =
      remainingAllowance?.[targetAllowance.id] ??
      targetAllowance.unitPrice?.amount ??
      Infinity;

    // Handle VAT on allowance amount
    const consideringVat = showInclusiveVat ? 1 + moduleVatRate : 1;
    const allowanceLeftWithVat = remainingAllowanceForDiscount * consideringVat;

    // Are we doing a full discount? (100%)
    const isFullDiscount = discountAmount === 100;

    // If the discount is 100% then we use the full price, otherwise we use the discounted price
    const discountPrice = isFullDiscount
      ? orderLinePrice
      : discountedOrderLinePrice;

    // Do we have enough allowance to apply the discount?
    const hasEnoughAllowance = allowanceLeftWithVat - discountPrice >= 0;

    if (!hasEnoughAllowance) {
      const individualDiscounts: OrderDiscount[] = [];

      // Split order line into order discounts until we don't have enough allowance
      Array.from({ length: orderLine.amount }).forEach(() => {
        const productPriceWithVAT =
          orderLine.unitPrice + orderLine.unitPrice * orderLine.vatRate;

        const discountedProductPrice =
          productPriceWithVAT - (discountAmount * productPriceWithVAT) / 100;

        const finalDiscount = isFullDiscount
          ? productPriceWithVAT
          : discountedProductPrice;

        const discountPriceWithoutVAT = finalDiscount / (1 + orderLine.vatRate);

        const remainingDiscount =
          // @ts-ignore
          remainingAllowance?.[targetAllowance.id] ?? allowanceLeftWithVat;

        const diff = remainingDiscount - finalDiscount;

        const hasEnoughAllowanceSingle = diff >= 0;

        // @ts-ignore
        remainingAllowance[targetAllowance.id] = hasEnoughAllowanceSingle
          ? diff
          : 0;

        const { productLineId, name, vatRate } = orderLine;

        if (remainingDiscount <= 0) return;

        const orderDiscount: OrderDiscount = {
          ...(orderDiscountBase as OrderDiscount),
          unitDiscountedAmount: hasEnoughAllowanceSingle
            ? finalDiscount
            : remainingDiscount,
          items: [
            {
              productLineId,
              amount: 1,
              name,
              vatRate,
              unitDiscountedPrice: hasEnoughAllowanceSingle
                ? discountPriceWithoutVAT
                : remainingDiscount / (1 + vatRate),
            },
          ],
        };

        return individualDiscounts.push(orderDiscount);
      });

      return [...acc, ...individualDiscounts];
    }

    remainingAllowance[targetAllowance.id] =
      allowanceLeftWithVat - discountPrice;

    const { productLineId, amount, name, vatRate } = orderLine;

    const discountPriceWithoutVAT = discountPrice / (1 + vatRate) / amount;

    const orderDiscount: OrderDiscount = {
      ...(orderDiscountBase as OrderDiscount),
      unitDiscountedAmount: discountPrice,
      items: [
        {
          productLineId,
          amount,
          name,
          vatRate,
          unitDiscountedPrice: discountPriceWithoutVAT,
        },
      ],
    };

    return [...acc, orderDiscount];
  }, []);

  const mergedDiscounts = mergeDiscounts({ orderDiscounts });

  return mergedDiscounts;
};
