import { SelectTypes } from "@kanpla/types";
import classNames from "classnames";
import React from "react";

const IndicatorsContainer: SelectTypes.IndicatorsContainer = ({
  innerProps,
  children,
}: SelectTypes.IndicatorsContainerProps) => {
  const classes = classNames("text-gray-500 flex items-center pl-2 gap-x-2");

  return (
    <div {...innerProps} className={classes}>
      {children}
    </div>
  );
};

export default IndicatorsContainer;
