import { useRequest } from "@kanpla/system";
import { Child } from "@kanpla/types";
import { LoadFrontendResult } from "apps/frontend/pages/api/internal/offers/loadFrontend";
import { CreateChildProps } from "apps/frontend/pages/api/internal/signup/createChild";
import { sortBy, uniqBy } from "lodash";

const useCreateChild = (
  children: Array<Child>,
  setData: <NewDataType>(
    newData: NewDataType,
    updater?: () => Promise<NewDataType>,
    dataTransformer?: (
      newData: NewDataType,
      optimistic?: boolean
    ) => Partial<LoadFrontendResult>
  ) => void
) => {
  const { request } = useRequest<CreateChildProps, Child>("signup/createChild");

  const createChild = async (
    data: CreateChildProps,
    updateLocalData = true
  ) => {
    const childData = await request(data);
    const childrenList = uniqBy([...(children || []), childData], "id");
    const sortedChildren = sortBy(childrenList, "displayName");
    if (updateLocalData) setData({ children: sortedChildren });

    return childData;
  };

  return { createChild };
};

export default useCreateChild;
