import { callInternalApi, useT } from "@kanpla/system";
import { Button, message } from "@kanpla/ui";
import {
  SubmitEmailVerificationStateProps,
  SubmitEmailVerificationStateReturn,
} from "apps/frontend/pages/api/internal/submit/emailVerificationState";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Loading from "./Loading";
import { currentPageAtom } from "./lib/usePageNavigation";
import { schoolIdAtom } from "./lib/useSchool";
import { emailAtom } from "./lib/useSubmitEmail";

interface Props {
  children: JSX.Element;
}

const MagicLinkListener = ({ children }: Props) => {
  const t = useT();
  const router = useRouter();
  const [codeFailed, setCodeFailed] = useState(false);

  const setCurrentPage = useSetAtom(currentPageAtom);
  const setEmail = useSetAtom(emailAtom);
  const setSchoolId = useSetAtom(schoolIdAtom);

  const email = router.query?.email || "";
  const schoolId = router.query?.schoolId || "";
  const code = router.query?.code;

  const hasMagicLink = typeof email === "string" && typeof code === "string";

  const validateCode = async (email: string, code: string) => {
    try {
      await callInternalApi<
        SubmitEmailVerificationStateProps,
        SubmitEmailVerificationStateReturn
      >("submit/emailVerificationState", {
        code,
        email,
      });

      message.success(t("The code is correct"));
      router.push({
        query: {},
      });

      setEmail(email);
      setSchoolId(schoolId as string);
      setCurrentPage("user");
    } catch (err) {
      message.error(t("Wrong code"));
      setCodeFailed(true);
    }
  };

  useEffect(() => {
    if (typeof email !== "string" || typeof code !== "string") return;
    validateCode(email, code);
  }, [email, code]);

  if (hasMagicLink && codeFailed)
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <h2 className="title-secondary mb-8">{t("Confirmation failed")}</h2>

        <Button
          type="primary"
          shape="solid"
          onClick={() => {
            setCurrentPage("emailCode");
            router.push({
              query: {},
            });
          }}
          dataCy="btn-signup-enter-code-manually"
        >
          {t("Enter code manually")}
        </Button>
      </div>
    );

  if (hasMagicLink) return <Loading />;

  return children;
};

export default MagicLinkListener;
