import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { auth, getErrorMessage, KanplaError, useT } from "@kanpla/system";
import { AuthModalProps } from "@kanpla/types";
import { Button, Form, Input, message, Spinner } from "@kanpla/ui";
import classNames from "classnames";
import { fetchSignInMethodsForEmail, getRedirectResult } from "firebase/auth";
import { useSetAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSessionstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { Helpcenter } from "../Helpcenter";
import { authModalStateAtom } from "../modals/Anonymous/AnonymousModal";
import PageHeader from "../signup-flow/PageHeader";
import { providerSetup } from "../signup-flow/screens/partials/Provider";

type FormData = {
  email: string;
  password: string;
};

const MultiLogin = ({ isInModal }: AuthModalProps) => {
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState<
    Array<"microsoft.com" | "password" | string>
  >([]);
  const [ran, setRan] = useState(false);

  const t = useT();

  const { auth: authHook, setModuleId } = useContainer(AppContext);
  const setAuthModalState = useSetAtom(authModalStateAtom);
  const router = useRouter();

  /**
   * checkInfo, useEffect, and signProviderIn are necessary for Microsoft login
   */
  const checkInfo = async () => {
    const res = await getRedirectResult(auth);
    initProvider(res);
  };

  useEffect(() => {
    if (!auth) return;
    checkInfo();
  }, [auth]);

  const initProvider = async (data) => {
    try {
      if (data?.user) {
        if (!router?.query?.redirectUrl) return router.push("/app");

        const redirectQuery = (router?.query?.redirectUrl as string)?.replace(
          "%3F",
          "?"
        );

        router.push(redirectQuery || "/app");
        return;
      }
    } catch (e) {
      console.error(e);
      message.error(getErrorMessage(e));
    }
  };

  // `doProvider` is out of the form but needs the receive the email value
  const [_, setEmail] = useSessionstorageState("kanpla-login-email", "");

  /** Shows a sign in with email button */
  const [initStep, setInitStep] = useState(true);

  const checkAccess = async (email: string) => {
    try {
      if (!email || email === "")
        throw new KanplaError("kanpla/fill-email", "Fill out email address");
      setInitStep(false);
      setRan(true);
      setLoadingMethods(true);

      const loadedMethods = await fetchSignInMethodsForEmail(
        auth,
        email.trim()
      );

      setMethods(loadedMethods);
      setEmail(email);
    } catch (err) {
      // User isn't created
      if (err?.code === "functions/not-found") {
        setMethods([]);
        return;
      }

      setInitStep(true);

      // Can't log user in
      if (err?.status === 401) {
        console.error(err);
        message.error(
          t(
            "We can't log you into the system, because you already have an account at another partner. Use a different email address."
          )
        );
        return;
      }

      console.error(err);
      message.error(getErrorMessage(err));
    } finally {
      setLoadingMethods(false);
    }
  };

  const submitAuth = async (data: FormData) => {
    try {
      const { email, password } = data;

      if (initStep) return await checkAccess(email);

      if (!initStep && password)
        return await signInWithPassword(email, password);
    } catch (err) {
      console.error(err);
    }
  };

  const signInWithPassword = async (email: string, password: string) => {
    try {
      if (!password || password === "")
        throw new KanplaError("kanpla/fill-password", "Fill out the password");
      setLoading(true);

      await authHook.signIn(email, password);
      setModuleId(null);
    } catch (err) {
      const newError = new KanplaError(err?.code, err?.message);
      console.error(newError);
      message.error(newError?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        title={t("Welcome back!")}
        subtitle={t("Login to your account")}
      />
      <Form<FormData>
        onSubmit={submitAuth}
        className={classNames("my-8 m-auto", {
          "max-w-xs": !isInModal,
        })}
        // Use this to reset the input if the user mistyped their info
        onChangeCapture={() => {
          if (ran && !methods?.length) {
            setRan(false);
            setInitStep(true);
          }
        }}
        defaultValues={{ email: "", password: "" }}
      >
        <Form.Item
          name="email"
          rules={{
            required: true,
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              message: t("Invalid {value}", {
                value: "email",
              }),
            },
          }}
        >
          <Input.Email
            id="email"
            placeholder={t("Enter your email address")}
            required
          />
        </Form.Item>

        {initStep && (
          <Button
            size="large"
            type="primary"
            shape="solid"
            className="w-full mt-4"
            htmlType="submit"
            dataCy="continue-login"
          >
            {t("Continue with email")}
          </Button>
        )}

        {!initStep && loadingMethods && (
          <div className="flex items-center justify-center text-text-primary">
            <Spinner useCurrentColor size="small" />
          </div>
        )}

        {!initStep &&
          !loadingMethods &&
          (!methods || !methods?.length) &&
          ran &&
          (isInModal ? (
            <Button
              onClick={() => {
                setAuthModalState("signup");
              }}
              size="large"
              type="primary"
              className="w-full"
              dataCy="btn-multi-login-account-no-exist"
            >
              {t("Account does not exist, add a new one")}
            </Button>
          ) : (
            <Link href="/signup">
              <Button
                size="large"
                type="primary"
                className="w-full"
                shape="solid"
                dataCy="btn-multi-login-no-account-else"
              >
                {t("Account does not exist, add a new one")}
              </Button>
            </Link>
          ))}

        {!initStep &&
          !loadingMethods &&
          methods.map((method) => {
            if (method === "password")
              return (
                <>
                  <Form.Item
                    name="password"
                    rules={{ required: t("Please enter your password") }}
                  >
                    <Input.Password required dataCy="password" />
                  </Form.Item>

                  <Link href="/resetPassword">
                    <button
                      type="button"
                      className="text-sm text-left -mt-2 font-medium opacity-80 hover:underline cursor-pointer focus:shadow-none focus:underline"
                    >
                      {t("Forgot your password?")}
                    </button>
                  </Link>
                  <Button
                    size="large"
                    type="primary"
                    shape="solid"
                    loading={loading}
                    dataCy="login-confirm"
                    htmlType="submit"
                    className="mt-4"
                  >
                    {t("Login")}
                  </Button>
                </>
              );

            return (
              <Button
                key={method}
                onClick={() => {
                  router.push("/login/__/microsoft");
                }}
                size="large"
                className="flex mx-auto w-full"
                icon={faMicrosoft}
                dataCy="btn-multi-login-microsoft"
              >
                {t("Login with {value}", {
                  value: providerSetup[method]?.name || method,
                })}
              </Button>
            );
          })}
      </Form>

      <div className="mt-6 text-text-secondary flex flex-col">
        {t("Don't have an account?")}{" "}
        {isInModal ? (
          <span
            onClick={() => {
              setAuthModalState("signup");
            }}
            className="font-semibold text-lg text-primary-main cursor-pointer mt-3"
          >
            {t("Sign up here")}
          </span>
        ) : (
          <Link href="/signup">
            <span className="font-semibold underline underline-offset-2 text-primary-main cursor-pointer mt-3">
              {t("Sign up here")}
            </span>
          </Link>
        )}
      </div>

      <div className="mt-6">
        <Helpcenter.Intercom shape="soft" size="small" />
      </div>
    </>
  );
};

export default MultiLogin;
