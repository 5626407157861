/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  faCheckCircle,
  faCreditCard,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentOnceProps, SessionResponse } from "@kanpla/services";
import {
  callInternalApi,
  getReepayErrorText,
  priceFormatter,
  tx,
  useT,
} from "@kanpla/system";
import { AutomaticRefill } from "@kanpla/types";
import {
  Checkbox,
  DotDotDot,
  DrawerOrModal,
  Spinner,
  activeCurrencyAtom,
  message,
} from "@kanpla/ui";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { RecurringPaymentEndpointProps } from "../../../../../../apps/frontend/pages/api/internal/payment/paymentRecurring";
import { OrderingContext } from "../../../context";
import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import DisplayDefaultCard from "../DisplayDefaultCard";
import useChargeSession from "../UseChargeSession";
import AdyenApplePay from "../adyen/AdyenApplePay";
import AdyenMobilePay from "../adyen/AdyenMobilePay";
import { adyenPaymentCheckoutConfigAtom } from "../adyen/AdyenPaymentComponent";
import useWindowPayment from "../useWindowPayment";
import CreditCardInfo from "./CreditCardInfo";
import ErrorModal, { CardErrorProps } from "./ErrorModal";
import OngoingRefillWarning from "./OngoingRefillWarning";
import PaymentAmount, { currencyNumbers } from "./PaymentAmount";
import RefillSettings from "./RefillSettings";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
}

const Divider = () => {
  const t = useT();
  return (
    <div className="pt-8 pb-2">
      <div className="border-b w-full border-divider-main flex items-center justify-center h-0 select-none">
        <div className="px-2 relative bg-background-primary text-text-secondary text-opacity-70 italic text-xs">
          {t("or")}
        </div>
      </div>
    </div>
  );
};

const PaymentModal = (props: Props) => {
  const { open, setOpen } = props;

  const t = useT();

  const {
    user,
    userId,
    balance,
    card,
    setBalance,
    schoolId,
    paymentGatewayProvider,
  } = useContainer(OrderingContext);

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [refillOpen, setRefillOpen] = useState(false);
  const [rememberCard, setRememberCard] = useState(true);
  const [paymentMethod, setPaymentMethod] = useAtom(selectedPaymentMethodAtom);
  const setAdyenCheckoutConfig = useSetAtom(adyenPaymentCheckoutConfigAtom);
  const { callbackUrl } = useWindowPayment({
    mode: "credit",
    setReceiptOpen: () => true,
    setReceiptTime: () => true,
    setCheckoutItems: () => true,
  });
  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const activeNumbers = currencyNumbers[activeCurrency || "DKK"];

  const [cardError, setCardError] = useState<CardErrorProps>({
    text: t("Payment failed"),
  });

  const [cardErrorModal, setCardErrorModal] = useState(false);
  const [activeAutomaticRefill, setActiveAutomaticRefill] = useState<
    AutomaticRefill | undefined
  >(
    user?.refillData?.find(
      (refillData) =>
        refillData.currency === activeCurrency &&
        refillData.paymentGatewayId === card?.paymentGatewayId
    )
  );

  // On successful one time payment
  const { loadChargeSession } = useChargeSession({
    onError: (err: any) => {
      setCardError(getReepayErrorText(err.error || err?.message));
      setCardErrorModal(true);
      setLoading(false);
    },
    setLoading,
  });

  const chargeCustomer = async () => {
    setLoading(true);

    const { cardId } = card || {};

    try {
      const validAmount = parseInt(amount);
      const data = await callInternalApi<RecurringPaymentEndpointProps, string>(
        "payment/paymentRecurring",
        {
          cardId,
          unitPrice: validAmount * 100, // Converting kroner to øre
          schoolId,
          userId,
        }
      );

      // If the payment wasn't right
      if (data === "success") {
        setBalance(balance + validAmount);
        message.success(t("Payment successful"));
      } else {
        console.error(data);
        setCardError(getReepayErrorText(data));
        setCardErrorModal(true);
      }
    } catch (err) {
      console.error(err);
      setCardErrorModal(true);
      message.error(t("Payment failed"));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const chargeOneTime = async () => {
    setLoading(true);

    try {
      const validAmount = parseInt(amount);

      const isCardPayment = paymentMethod === "card";

      const { sessionId, provider, sessionData } = await callInternalApi<
        PaymentOnceProps,
        SessionResponse
      >("payment/paymentOnce", {
        unitPrice: validAmount * 100, // Converting kroner to øre
        recurring: isCardPayment ? rememberCard : false,
        paymentMethod,
        isWindowSession: true,
        callbackUrl,
        mode: "credit",
        schoolId,
      });

      // Remember payment method
      setPaymentMethod(paymentMethod);

      const payload =
        provider === "adyen"
          ? { provider, sessionId, sessionData }
          : { provider, sessionId };

      const session = await loadChargeSession(payload);
      if (session.provider === "adyen") {
        setAdyenCheckoutConfig({
          options: session.config,
          onSuccess: () => setOpen(false),
          rememberCard,
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setCardErrorModal(true);
      message.error(t("Payment failed"));
      setLoading(false);
    }
  };

  const validateAmount = (newAmount: number) => {
    if (!newAmount) {
      message.error(t("Please select the amount"));
      return false;
    }
    if (newAmount < activeNumbers[0]) {
      const formatted = priceFormatter(
        activeNumbers[0] * 100,
        activeCurrency,
        tx.getCurrentLocale()
      );
      message.info(t("Choose an amount over {formatted}.", { formatted }), {
        messageId: "min-amount-deposit",
      });
      return false;
    }
    return true;
  };

  const disabledForNoPaymentMethod = !paymentMethod && !card;

  const actions = loading
    ? []
    : paymentMethod === "applepay" && paymentGatewayProvider === "adyen"
    ? [<AdyenApplePay />]
    : paymentMethod === "mobilepay" && paymentGatewayProvider === "adyen"
    ? [<AdyenMobilePay />]
    : [
        {
          type: "primary",
          disabled: disabledForNoPaymentMethod,
          onClick: async () => {
            const valid = validateAmount(parseInt(amount));
            if (!valid) return;

            if (card && paymentMethod === "card") {
              await chargeCustomer();
            } else {
              await chargeOneTime();
            }
          },
          label: (
            <>
              <FontAwesomeIcon
                icon={faCreditCard}
                className="mr-2 fa-swap-opacity"
              />
              {t("Deposit")}
            </>
          ),
          dataCy: "deposit-credit-balance",
        },
      ];

  return (
    <>
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("Deposit money")}
        subtitle={t("How much credit do you want to add?")}
        showCloseButton
        actions={actions}
        zMax
      >
        {loading ? (
          <div className="my-8 max-w-xs m-auto pb-6 flex flex-col items-center justify-center">
            <div className="py-3">
              <Spinner size={"medium"} />
            </div>
            <p className="text-center text-sm text-text-secondary pt-4">
              <span role="img" aria-label="tid">
                ⌛
              </span>{" "}
              {t("Payment could be slow ⌛")}
              <DotDotDot />
            </p>
          </div>
        ) : (
          <>
            <div className="mt-8 max-w-xs m-auto">
              <OngoingRefillWarning />
              <PaymentAmount amount={amount} setAmount={setAmount} />
              <DisplayDefaultCard label="select" className="py-2 mt-5" />
              <div className="flex justify-center pt-5 border-divider-main" />
              {!card && paymentMethod === "card" && (
                <div className="flex justify-center mt-3">
                  <Checkbox
                    onChange={(e) => setRememberCard(e.target.checked)}
                    checked={rememberCard}
                    className="text-text-primary"
                    data-cy="checkbox-save-card"
                  >
                    {t("Save the card for later payments")}
                  </Checkbox>
                </div>
              )}
            </div>
            {card && <Divider />}
            <div className="pt-1 text-base">
              {!activeAutomaticRefill && card && (
                <button
                  className="block text-primary-main mx-auto font-semibold py-2"
                  onClick={() => setRefillOpen(true)}
                >
                  {t("Setup automatic refill")}
                </button>
              )}
              {!!activeAutomaticRefill && card && (
                <button
                  className="block text-primary-main mx-auto font-semibold py-2"
                  onClick={() => setRefillOpen(true)}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                  {t("Automatic refill")}
                  <p className="text-text-secondary text-sm font-normal">
                    {t(
                      "under {refillBelow} {activeCurrency} add {refillAmount} {activeCurrency}",
                      {
                        // amount is stored in the smallest currency unit
                        refillBelow: activeAutomaticRefill.refillBelow / 100,
                        refillAmount: activeAutomaticRefill.refillAmount / 100,
                        activeCurrency,
                      }
                    )}
                  </p>
                  <p className="text-text-secondary text-sm font-semibold underline mt-1">
                    {t("Edit").toLowerCase()}
                  </p>
                </button>
              )}
            </div>
          </>
        )}
        {card && (
          <div className="pt-9 md:pt-0">
            <CreditCardInfo />
          </div>
        )}
      </DrawerOrModal>
      {/* ERROR MODAL */}
      <ErrorModal
        open={cardErrorModal}
        setOpen={setCardErrorModal}
        cardError={cardError}
        setCardError={setCardError}
      />
      {user && (
        <RefillSettings
          open={refillOpen}
          setOpen={setRefillOpen}
          paymentGatewayId={card?.paymentGatewayId}
          hasAutomaticRefill={!!activeAutomaticRefill}
          updateParentRefillData={(newRefillValue) =>
            setActiveAutomaticRefill(newRefillValue)
          }
        />
      )}
    </>
  );
};

export default PaymentModal;
