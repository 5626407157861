import { KanplaError, useT } from "@kanpla/system";
import { AuthModalProps } from "@kanpla/types";
import { Button, Form, Input, Space, message } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { Helpcenter } from "../Helpcenter";
import { AppContext } from "../contextProvider";
import { authModalStateAtom } from "../modals/Anonymous/AnonymousModal";
import PageHeader from "../signup-flow/PageHeader";

type FormData = {
  email: string;
  password: string;
};

const BasicLogin = ({ isInModal }: AuthModalProps) => {
  const t = useT();

  const [loading, setLoading] = useState(false);

  const { auth, setModuleId } = useContainer(AppContext);
  const setAuthModalState = useSetAtom(authModalStateAtom);

  const router = useRouter();

  const signIn = async (data: FormData) => {
    try {
      setLoading(true);

      const { email, password } = data;

      if (!email || email === "")
        throw new KanplaError("kanpla/fill-email", "Fill out email address");

      setModuleId(null);
      await auth.signIn(email, password);

      return true;
    } catch (err) {
      // User isn't created
      if (err?.code === "functions/not-found") {
        const noUserError = new KanplaError(
          "auth/user-not-found",
          "Firebase: Error (auth/user-not-found)."
        );

        message.error(noUserError.message);
        return;
      }

      // Can't log user in
      if (err?.status === 401) {
        console.error(err);
        message.error(
          t(
            "We can't log you into the system, because you already have an account at another partner. Use a different email address."
          )
        );
        return;
      }

      const newError = new KanplaError(err?.code, err?.message);
      console.error(err);
      message.error(newError.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        title={t("Welcome back!")}
        subtitle={t("Login to your account")}
      />
      <Form<FormData>
        className="my-8 max-w-xs m-auto"
        onSubmit={signIn}
        autoComplete="off"
        defaultValues={{
          email: "",
          password: "",
        }}
      >
        <Form.Item
          name="email"
          rules={{
            required: t("Please enter the {value}", {
              value: "email",
            }),
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              message: t("Invalid {value}", {
                value: "email",
              }),
            },
          }}
        >
          <Input.Email
            dataCy="email"
            placeholder={t("Enter your email address")}
            required
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={{ required: t("Please enter your password") }}
        >
          <Input.Password dataCy="password" required />
        </Form.Item>

        <Link href="/resetPassword">
          <button
            type="button"
            className="text-sm text-left -mt-2 font-medium opacity-80 hover:underline cursor-pointer focus:shadow-none focus:underline"
          >
            {t("Forgot your password?")}
          </button>
        </Link>

        <Button
          loading={loading}
          size="large"
          loadingText={t("Please wait...")}
          dataCy="btn-basic-log-in"
          shape="solid"
          type="primary"
          className="w-full mt-4"
          htmlType="submit"
        >
          {t("Login")}
        </Button>
      </Form>

      <Space direction="vertical" className="w-full" align="center">
        <div className="mt-2 text-sm text-text-secondary">
          {isInModal ? (
            <>
              {t("Account does not exist, add a new one")}
              <span
                onClick={() => {
                  setAuthModalState("signup");
                }}
                className="font-semibold text-primary-main cursor-pointer ml-1"
              >
                {t("Get one here!")}
              </span>
            </>
          ) : (
            <>
              {t("Don't have an account?")}{" "}
              <Link
                href={{
                  pathname: `/signup`,
                  query: router.query,
                }}
              >
                <span className="font-semibold text-primary-main cursor-pointer">
                  {t("Get one here!")}
                </span>
              </Link>
            </>
          )}
        </div>
      </Space>

      <div className="mt-6">
        <Helpcenter.Intercom shape="soft" size="small" />
      </div>
    </>
  );
};

export default BasicLogin;
