import { OrderingContext } from "@kanpla/ordering";
import {
  db,
  fetchCollection,
  getErrorMessage,
  getPartnerUrl,
  isNative,
  useLocalStorage,
  useLocationHostname,
  useT,
} from "@kanpla/system";
import { OrderLines, Supplier } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useContainer } from "unstated-next";
import {
  basketContainerAtom,
  basketResetAtom,
  openBasketAtom,
  shouldNotifyUserAfterRefillAtom,
} from "../basket/useBasket";
import { adyenPaymentCheckoutConfigAtom } from "./adyen/AdyenPaymentComponent";

interface Props {
  mode: "order" | "credit" | "subscription";
  setReceiptTime?: Dispatch<SetStateAction<number>>;
  setCheckoutItems?: Dispatch<SetStateAction<OrderLines>>;
  setReceiptOpen?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
}

/**
 * Handles Reepay payments on external window
 * @param props
 * @returns
 */
const useWindowPayment = (props: Props) => {
  const t = useT();
  // Util to change the localization of moment.js

  const {
    mode: modeProp,
    setReceiptOpen,
    setCheckoutItems,
    setReceiptTime,
    callback = () => null,
  } = props;
  const { loadCards, setBalance, balance, schoolId, moduleId, reloadOrders } =
    useContainer(OrderingContext);
  const basketContainer = useAtomValue(basketContainerAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);
  const setShouldNotifyUserAfterRefill = useSetAtom(
    shouldNotifyUserAfterRefillAtom
  );
  const basketReset = useSetAtom(basketResetAtom);

  const isAdyenCheckout = useAtomValue(adyenPaymentCheckoutConfigAtom);

  /** Indicate if the result was processed and reported to avoid double messages */
  const [reported, setReported] = useState(false);

  const router = useRouter();

  const [baseUrl, setBaseUrl] = useState(null);

  // Retrieve Reepay session id
  const [reepaySessionId, setReepaySessionId] = useLocalStorage(
    "reepay-session-id",
    null
  );

  // Prevent handle function to be fired consecutively
  const [isBeingProcessed, setIsBeingProcessed] = useLocalStorage(
    "reepay-in-progress",
    false
  );

  const hostname = useLocationHostname({});

  const domain = useMemo(() => {
    const newUrl = getPartnerUrl({ hostname });
    const isKanpla = !newUrl || newUrl === "app";
    const domain = isKanpla ? "kanpla" : newUrl;
    return domain;
  }, [hostname]);

  const getUrl = async () => {
    // Get potential supplier based on the app url
    try {
      const suppliers = await fetchCollection<Supplier>(
        db.collection("suppliers").where("url", "==", domain)
      );

      if (isEmpty(suppliers)) return domain;

      const targetSupplier = suppliers?.find((s) => s?.isMain) || suppliers[0];
      const targetUrl = targetSupplier?.appUrl || targetSupplier?.url || domain;

      return targetUrl;
    } catch (err) {
      console.error(err);
      return domain;
    }
  };

  useEffect(() => {
    if (typeof window === "undefined" || domain === undefined) return;

    (async () => {
      const partnerUrl = await getUrl();

      if (isMobile && isNative) {
        setBaseUrl(`${partnerUrl !== "kanpla" ? partnerUrl : "kanpla"}://`);
      } else {
        setBaseUrl(`${window.location.origin}/`);
      }
    })();
  }, [hostname, domain]);

  const resetRouter = async () => {
    // remove queries from the url
    await router.replace(`${baseUrl}app/`, undefined, { shallow: true });
  };

  const handleReepayFallback = async () => {
    const {
      id: sessionId,
      invoice: invoiceId,
      payment_method: cardHandle,
      orderId: paymentId,
      cancel,
      mode,
      error,
    } = router.query;

    try {
      if (reported) return;
      if (!reepaySessionId) return;

      setIsBeingProcessed(true);

      if (error)
        throw new Error(`${t("Error code {value}", { value: error })}`);

      if (cancel && cancel === "true") {
        setIsBeingProcessed(false);
        await resetRouter();
        throw new Error(t("You cancelled the transaction"));
      }

      if (!sessionId && !invoiceId) {
        setIsBeingProcessed(false);
        return;
      }

      if (mode === "order" && !paymentId) throw new Error("Incorrect mode");
      if (reepaySessionId !== sessionId)
        throw new Error("Session er ikke gyldig");

      // if payment is not settled from direct order, update the balance
      if (modeProp === "credit" && mode === "credit") {
        return;
      }

      if (mode === "order") {
        // Reset cross basket
        basketReset();

        // // This is force-cleaning the basket! Should be refactored
        setTimeout(() => basketReset(), 300);
        setTimeout(() => basketReset(), 500);
        setTimeout(() => basketReset(), 700);
        setTimeout(() => basketReset(), 1000);

        callback();

        message.success(t("Payment successful"), {
          messageId: "payment-successful",
        });
      }

      if (!isEmpty(basketContainer)) setShouldNotifyUserAfterRefill(true);

      // store user card
      if (cardHandle) {
        await loadCards();
      }
    } catch (err) {
      message.error(
        t("Payment failed: {value}", { value: getErrorMessage(err) }),
        {
          messageId: "payment-cancelled",
        }
      );
      console.error(err);
      setOpenBasket(true);
    } finally {
      await resetRouter();
      setReported(true);
      setReepaySessionId(null);
      setIsBeingProcessed(false);
      reloadOrders();
    }
  };

  useEffect(() => {
    if (isAdyenCheckout) return;

    if (
      !schoolId ||
      !moduleId ||
      typeof setReceiptOpen === "undefined" ||
      typeof setCheckoutItems === "undefined" ||
      typeof setReceiptTime === "undefined" ||
      typeof setBalance === "undefined" ||
      typeof loadCards === "undefined"
    )
      return;

    if (
      Object.values(router?.query).length === 0 ||
      Boolean(isBeingProcessed) ||
      reepaySessionId === null
    ) {
      return;
    }

    // Since this hook gets called right after the refresh, some functions and values are still undefined
    // we first check that we have them available, depending on the mode of the payment made
    if (
      modeProp === "order" &&
      (typeof setReceiptOpen === "undefined" ||
        typeof setReceiptTime === "undefined" ||
        typeof setCheckoutItems === "undefined")
    )
      return;

    if (
      modeProp === "credit" &&
      (typeof setBalance === "undefined" ||
        typeof loadCards === "undefined" ||
        !balance)
    ) {
      return;
    }

    setOpenBasket(false);

    handleReepayFallback();
  }, [
    setReceiptOpen,
    setCheckoutItems,
    setReceiptTime,
    setBalance,
    loadCards,
    balance,
    schoolId,
    moduleId,
    router?.query,
    isBeingProcessed,
    reepaySessionId,
  ]);

  return {
    callbackUrl: `${baseUrl}app/s/${schoolId}/m/${moduleId}`,
  };
};

export default useWindowPayment;
