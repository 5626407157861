import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import classNames from "classnames";
import React from "react";

interface LoaderProps {
  color?: string;
  /** Classes of the loader (child) */
  loaderClasses?: string;
  /** Classes of the container that makes the loader spinning (parent) */
  spinClasses?: string;
}

export const Loader = ({
  color = "inherit",
  loaderClasses,
  spinClasses,
}: LoaderProps) => {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{ color }}
          className={classNames(loaderClasses)}
          spin
        />
      }
      className={classNames(spinClasses)}
    />
  );
};
