import {
  faBowlHot,
  faCheeseSwiss,
  faCubesStacked,
  faEgg,
  faGarlic,
  faHandsHoldingHeart,
  faHourglassClock,
  faOnion,
  faPepperHot,
  faSnowflake,
  faTurkey,
} from "@fortawesome/pro-regular-svg-icons";
import {
  IconDefinition,
  faBird,
  faBottleDroplet,
  faBowlRice,
  faCalendarDay,
  faCalendarWeek,
  faCauldron,
  faCow,
  faDeer,
  faDuck,
  faFish,
  faGlass,
  faGrillHot,
  faHandHoldingHeart,
  faHandHoldingSeedling,
  faHeart,
  faJackOLantern,
  faLeaf,
  faLobster,
  faOlive,
  faOven,
  faPanFood,
  faPanFrying,
  faPeanuts,
  faPhone,
  faPig,
  faPotFood,
  faRabbitRunning,
  faSeedling,
  faSheep,
  faSoap,
  faSparkles,
  faStar,
  faTreeChristmas,
  faWheat,
  faWheatSlash,
  faWineBottle,
} from "@fortawesome/pro-solid-svg-icons";
import { AttributeCustomization } from "@kanpla/types";

export interface AttributeData {
  key: string;
  /** Localized names by language code */
  names: {
    /** Danish name */
    da: string;
    /** Norwegian name */
    no: string;
    /** English name */
    en: string;
    /** Dutch name */
    nl: string;
  };
  /** Is active (default) */
  active: boolean;
  /** What data type is this (will be deprecated one day) */
  type: "allergens" | "labels" | "pictograms";
  /** How is this shown in backend (default) */
  backendInputCategory?: AttributeCustomization["backendInputCategory"];
  /** How is this shown in frontend (default) */
  frontendDisplayMode?: AttributeCustomization["frontendDisplayMode"];
  /** Background css gradient for labels */
  background?: string;
  /** HEX for labels icon color */
  color?: string;
  /** Optional FontAwesomeIcon icon, overwrites svg file */
  faIcon?: IconDefinition;
  name?: string;
  relations?: { backendUIParent: AttributeData["key"] };
  /** Standard EU Allergen number */
  number?: number;
}

export const attributesData: AttributeData[] = [
  {
    number: 1,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "wheat",
    names: {
      da: "Gluten",
      no: "Gluten",
      en: "Gluten",
      nl: "Gluten",
    },
    type: "allergens",
    faIcon: faWheat,
  },
  {
    active: true,
    key: "wheat:wheat",
    names: { da: "Hvede", en: "Wheat", no: "Hvete", nl: "Tarwe" },
    type: "allergens",
    relations: { backendUIParent: "wheat" },
  },
  {
    active: true,
    key: "wheat:rye",
    names: { da: "Rug", en: "Rye", no: "Rug", nl: "Rye" },
    type: "allergens",
    relations: { backendUIParent: "wheat" },
  },
  {
    active: true,
    key: "wheat:barley",
    names: { da: "Byg", en: "Barley", no: "Bugg", nl: "Gerst" },
    type: "allergens",
    relations: { backendUIParent: "wheat" },
  },
  {
    active: true,
    key: "wheat:oats",
    names: { da: "Havre", en: "Oats", no: "Havre", nl: "Haver" },
    type: "allergens",
    relations: { backendUIParent: "wheat" },
  },
  {
    number: 9,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "celery",
    names: {
      da: "Selleri",
      en: "Celery",
      no: "Selleri",
      nl: "Selderij",
    },
    type: "allergens",
  },
  {
    number: 2,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "crustaceans",
    names: {
      da: "Skaldyr",
      en: "Crustaceans",
      no: "Krepsdyr",
      nl: "Schaaldieren",
    },
    type: "allergens",
    faIcon: faLobster,
  },
  {
    number: 3,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "egg",
    names: {
      da: "Æg",
      en: "Egg",
      no: "Egg",
      nl: "Egg",
    },
    type: "allergens",
    faIcon: faEgg,
  },
  {
    number: 4,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "fish",
    names: {
      da: "Fisk",
      en: "Fish",
      no: "Fisk",
      nl: "Vis",
    },

    type: "allergens",
    faIcon: faFish,
  },
  {
    number: 13,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "lupin",
    names: {
      da: "Lupin",
      en: "Lupine",
      no: "Lupin",
      nl: "Lupine",
    },

    type: "allergens",
  },
  {
    number: 7,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "milk",
    names: {
      da: "Mælk",
      en: "Milk",
      no: "Melk",
      nl: "Milk",
    },
    type: "allergens",
    faIcon: faGlass,
  },
  {
    number: 14,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "molluscs",
    names: {
      da: "Bløddyr",
      en: "Molluscs",
      no: "Bløtdyr",
      nl: "Weekdieren",
    },

    type: "allergens",
  },
  {
    number: 10,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "mustard",
    names: {
      da: "Sennep",
      en: "Mustard",
      no: "Sennep",
      nl: "Mustard",
    },
    type: "allergens",
  },
  {
    number: 8,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "nuts",
    names: {
      da: "Nødder",
      en: "Nuts",
      no: "Nøtter",
      nl: "Nuts",
    },
    type: "allergens",
    faIcon: faPeanuts,
  },
  {
    active: true,
    key: "nuts:almonds",
    names: { da: "Mandler", en: "Almonds", no: "Mandler", nl: "Amandelen" },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:hazelnuts",
    names: {
      da: "Hasselnødder",
      en: "Hazelnuts",
      no: "Hasselnøtter",
      nl: "Hazelnoten",
    },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:walnuts",
    names: { da: "Valnødder", en: "Walnuts", no: "Valnøtter", nl: "Walnoten" },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:cashew",
    names: {
      da: "Cashewnødder",
      en: "Cashew nuts",
      no: "Cashewnøtter",
      nl: "Cashewnoten",
    },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:pecan",
    names: {
      da: "Pekannødder",
      en: "Pecan nuts",
      no: "Pekannøtter",
      nl: "Pecannoten",
    },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:pistachio",
    names: {
      da: "Pistacienødder",
      en: "Pistachio nuts",
      no: "Pistasjnøtter",
      nl: "Pistachenoten",
    },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:brazil",
    names: {
      da: "Paranødder",
      en: "Brazil nuts",
      no: "Paranøtter",
      nl: "Paranoten",
    },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    active: true,
    key: "nuts:macadamia",
    names: {
      da: "Queenslandnødder",
      en: "Macadamia nuts",
      no: "Macadamianøtter",
      nl: "Macadamianoten",
    },
    type: "allergens",
    relations: { backendUIParent: "nuts" },
  },
  {
    number: 5,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "peanut",
    names: {
      da: "Jordnødder",
      en: "Peanuts",
      no: "Peanøtter",
      nl: "Pinda's",
    },
    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: ["allergens", "pictograms"],
    frontendDisplayMode: "description",
    key: "pork",
    names: {
      da: "Gris",
      en: "Pork",
      no: "Svinekjøtt",
      nl: "Varkensvlees",
    },
    type: "allergens",
    faIcon: faPig,
  },
  {
    number: 11,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "sesame",
    names: {
      da: "Sesam",
      en: "Sesame",
      no: "Sesam",
      nl: "Sesam",
    },
    type: "allergens",
  },
  {
    number: 6,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "soya",
    names: {
      da: "Soja",
      en: "Soy",
      no: "Soya",
      nl: "Soja",
    },
    type: "allergens",
  },
  {
    number: 12,
    active: true,
    backendInputCategory: ["allergens"],
    frontendDisplayMode: "description",
    key: "sulphurdioxide",
    names: {
      da: "Svovldioxid og sulfitter",
      en: "Sulfur dioxide and sulfites",
      no: "Svoveldioksid og sulfitter",
      nl: "Zwaveldioxide en sulfieten",
    },
    type: "allergens",
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "vegan",
    names: {
      da: "Vegan",
      en: "Vegan",
      no: "Vegan",
      nl: "Veganistisch",
    },
    type: "labels",
    background: `linear-gradient(107.57deg, #48BB78 -18.83%, #38A169 124.05%)`,
    color: "#48BB78",
    faIcon: faHandHoldingSeedling,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "vegetarian",
    names: {
      da: "Vegetar",
      en: "Vegetarian",
      no: "Vegetarisk",
      nl: "Vegetarisch",
    },
    type: "labels",
    background: `linear-gradient(107.57deg, #48BB78 -18.83%, #38A169 124.05%)`,
    color: "#48BB78",
    faIcon: faLeaf,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "glutenFree",
    names: {
      da: "Glutenfri",
      en: "Gluten Free",
      no: "Glutenfri",
      nl: "Glutenvrij",
    },
    type: "labels",
    faIcon: faWheatSlash,
  },
  // {
  //   active: true,
  //   backendInputCategory: ["labels"],
  //   frontendDisplayMode: "tag",
  //   key: "lactoseFree",
  //   names: {
  //     da: "",
  //     en: "Lactose Free",
  //   },
  //   type: "labels",
  // },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "organic",
    names: {
      da: "Øko",
      en: "Eco",
      no: "Øko",
      nl: "Eco",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #E30613 -19.33%, #E30613 131.99%)`,
    color: "#E30613",
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "almostOrganic",
    names: {
      da: "Delvist Øko",
      en: "Partly Eco",
      no: "Delvis Øko",
      nl: "Gedeeltelijk Eco",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #E30613 -19.33%, #E30613 131.99%)`,
    color: "#E30613",
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "tag",
    key: "popular",
    names: {
      da: "Populær",
      en: "Popular",
      no: "Populær",
      nl: "Populair",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    color: "#ECC94B",
    faIcon: faStar,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "halal",
    names: {
      da: "Halal",
      en: "Halal",
      no: "Halal",
      nl: "Halal",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #054020 -19.33%, #17661F 131.99%)`,
    color: "#2F855A",
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "leftovers",
    names: {
      da: "Overskudsmad",
      en: "Surplus food",
      no: "Overskuddsmat",
      nl: "Overtollig voedsel",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #48BB78 -19.33%, #38A169 131.99%)`,
    color: "#48BB78",
    faIcon: faHeart,
  },
  {
    active: false,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "madeWithLeftovers",
    names: {
      da: "Lavet med rester",
      en: "Made with leftovers",
      no: "Laget med rester",
      nl: "Gemaakt met restjes",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #48BB78 -19.33%, #38A169 131.99%)`,
    color: "#48BB78",
    faIcon: faHandHoldingHeart,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "new",
    names: {
      da: "Nyhed",
      en: "New item",
      no: "Nyhet",
      nl: "Nieuw artikel",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, rgba(121,166,255,1) -19.33%, rgba(0,117,208,1) 131.99%)`,
    color: "rgba(121,166,255,1)",
    faIcon: faSparkles,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "onlyToday",
    names: {
      da: "Kun i dag",
      en: "Only today",
      no: "Bare i dag",
      nl: "Alleen vandaag",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    color: "#ECC94B",
    faIcon: faCalendarDay,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "onlyThisWeek",
    names: {
      da: "Kun i denne uge",
      en: "Only this week",
      no: "Bare denne uken",
      nl: "Alleen deze week",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #ECC94B -19.33%, #D69E2E 131.99%)`,
    color: "#ECC94B",
    faIcon: faCalendarWeek,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "urgentSale",
    names: {
      da: "Først til mølle",
      en: "First come, first served",
      no: "Førstemann til mølla",
      nl: "Wie het eerst komt, het eerst maalt",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, rgba(192,159,255,1) -19.33%, rgba(176,93,255,1) 131.99%)`,
    color: "rgba(192,159,255,1)",
    faIcon: faHourglassClock,
  },
  {
    active: true,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "contactKitchen",
    names: {
      da: "Kontakt Køkken",
      en: "Contact kitchen",
      no: "Kontakt kjøkkenet",
      nl: "Contact keuken",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, rgba(192,159,255,1) -19.33%, rgba(176,93,255,1) 131.99%)`,
    color: "rgba(192,159,255,1)",
    faIcon: faPhone,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "animalCare",
    names: {
      da: "Dyrevelfærd",
      en: "Cruelty free",
      no: "Grusomhetsfri",
      nl: "Wreedheidvrij",
    },
    type: "labels",
    background: `linear-gradient(114.18deg, #48BB78 -19.33%, #38A169 131.99%)`,
    color: "#48BB78",
    faIcon: faHandsHoldingHeart,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "sugar",
    names: {
      da: "Sukker",
      en: "Sugar",
      no: "Sukker",
      nl: "Suiker",
    },
    type: "pictograms",
    faIcon: faCubesStacked,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "duck",
    names: {
      da: "And",
      en: "Duck",
      no: "And",
      nl: "Eend",
    },
    type: "pictograms",
    faIcon: faDuck,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "chili",
    names: {
      da: "Chili",
      en: "Chili",
      no: "Chili",
      nl: "Chili",
    },
    type: "pictograms",
    faIcon: faPepperHot,
  },
  {
    active: false,
    key: "chili:mild",
    names: {
      da: "Let stærk chili",
      en: "Mild chili",
      no: "Litt sterk chili",
      nl: "Milde chili",
    },
    type: "pictograms",
    relations: { backendUIParent: "chili" },
  },
  {
    active: false,
    key: "chili:medium",
    names: {
      da: "Medium stærk chili",
      en: "Medium spicy chili",
      no: "Medium sterk chili",
      nl: "Middelgrote chili",
    },
    type: "pictograms",
    relations: { backendUIParent: "chili" },
  },
  {
    active: false,
    key: "chili:strong",
    names: {
      da: "Stærk chili",
      en: "Strong chili",
      no: "Sterk chili",
      nl: "Sterke chili",
    },
    type: "pictograms",
    relations: { backendUIParent: "chili" },
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "garlic",
    names: {
      da: "Hvidløg",
      en: "Garlic",
      no: "Hvitløk",
      nl: "Knoflook",
    },
    type: "pictograms",
    faIcon: faGarlic,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "turkey",
    names: {
      da: "Kalkun",
      en: "Turkey",
      no: "Kalkun",
      nl: "Kalkoen",
    },
    type: "pictograms",
    faIcon: faTurkey,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "deer",
    names: {
      da: "Krondyr/Vildt",
      en: "Deer",
      no: "Horndyr/vilt",
      nl: "Hert",
    },
    type: "pictograms",
    faIcon: faDeer,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "chicken",
    names: {
      da: "Kylling",
      en: "Chicken",
      no: "Kylling",
      nl: "Kip",
    },
    type: "pictograms",
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "lamb",
    names: {
      da: "Lam",
      en: "Lamb",
      no: "Lam",
      nl: "Lam",
    },
    type: "pictograms",
    faIcon: faSheep,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "beef",
    names: {
      da: "Okse",
      en: "Beef",
      no: "Storfekjøtt",
      nl: "Rundvlees",
    },
    type: "pictograms",
    faIcon: faCow,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "onion",
    names: {
      da: "Løg",
      en: "Onion",
      no: "Løk",
      nl: "Ui",
    },
    type: "pictograms",
    faIcon: faOnion,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "cilantro",
    names: {
      da: "Koriander",
      en: "Cilantro",
      no: "Koriander",
      nl: "Koriander",
    },
    type: "pictograms",
    faIcon: faSeedling,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "cheese",
    names: {
      da: "Ost",
      en: "Cheese",
      no: "Ost",
      nl: "Kaas",
    },
    type: "pictograms",
    faIcon: faCheeseSwiss,
  },
  // @deprecated, replaced by 'pork'
  // {
  //   active: false,
  //   backendInputCategory: ["pictograms"],
  //   frontendDisplayMode: "description",
  //   key: "pig",
  //   names: {
  //     da: "Gris",
  //     en: "Pig",
  //   },
  //   type: "pictograms",
  //   faIcon: faPig,
  // },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "cow",
    names: {
      da: "Ko",
      en: "Cow",
      no: "Ku",
      nl: "Koe",
    },
    type: "pictograms",
    faIcon: faCow,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "goat",
    names: {
      da: "Ged",
      en: "Goat",
      no: "Geit",
      nl: "Geit",
    },
    type: "pictograms",
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "bird",
    names: {
      da: "Fugl",
      en: "Bird",
      no: "Fugl",
      nl: "Vogel",
    },
    type: "pictograms",
    faIcon: faBird,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "partlyNuts",
    names: {
      da: "Spor af nødder",
      en: "Traces of nuts",
      no: "Spor av nøtter",
      nl: "Sporen van noten",
    },
    type: "pictograms",
    faIcon: faPeanuts,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "beans",
    names: {
      da: "Bønner",
      en: "Beans",
      no: "Bønner",
      nl: "Bonen",
    },
    type: "pictograms",
    faIcon: faOlive,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "hotDish",
    names: {
      da: "Varm ret",
      en: "Hot dish",
      no: "Varm rett",
      nl: "Heet gerecht",
    },
    type: "pictograms",
    faIcon: faBowlHot,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "coldDish",
    names: {
      da: "Kold ret",
      en: "Cold dish",
      no: "Kald rett",
      nl: "Koude schotel",
    },
    type: "pictograms",
    faIcon: faSnowflake,
  },
  {
    active: false,
    backendInputCategory: ["pictograms"],
    frontendDisplayMode: "description",
    key: "alcohol",
    names: {
      da: "Indeholder alkohol",
      en: "Contains alcohol",
      no: "Inneholder alkohol",
      nl: "Bevat Alcohol",
    },
    type: "pictograms",
    faIcon: faWineBottle,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "fried",
    names: {
      da: "Stegt",
      en: "Fried",
      no: "Stekt",
      nl: "Gebakken",
    },
    type: "pictograms",
    faIcon: faPanFrying,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "cooked",
    names: {
      da: "Kogt",
      en: "Boiled",
      no: "Kokt",
      nl: "Gekookt",
    },
    type: "pictograms",
    faIcon: faCauldron,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "baked",
    names: {
      da: "Bagt",
      en: "Baked",
      no: "Bakt",
      nl: "Gebakken",
    },
    type: "pictograms",
    faIcon: faOven,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "grilled",
    names: {
      da: "Grillet",
      en: "Grilled",
      no: "Grillet",
      nl: "Gegrild",
    },
    type: "pictograms",
    faIcon: faGrillHot,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "deepFried",
    names: {
      da: "Friturestegt",
      en: "Deep fried",
      no: "Fritert",
      nl: "Gefrituurd",
    },
    type: "pictograms",
    faIcon: faSoap,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "poached",
    names: {
      da: "Pocheret",
      en: "Poached",
      no: "Posjert",
      nl: "Gepocheerd",
    },
    type: "pictograms",
    faIcon: faPanFood,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "steamed",
    names: {
      da: "Dampet",
      en: "Steamed",
      no: "Dampet",
      nl: "Gestoomd",
    },
    type: "pictograms",
    faIcon: faBowlRice,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "braised",
    names: {
      da: "Braiseret",
      en: "Braised",
      no: "Braisert",
      nl: "Gestoofd",
    },
    type: "pictograms",
    faIcon: faPotFood,
  },
  {
    active: false,
    backendInputCategory: ["preparation"],
    frontendDisplayMode: "description",
    key: "marinated",
    names: {
      da: "Marineret",
      en: "Marinated",
      no: "Marinert",
      nl: "Gemarineerd",
    },
    type: "pictograms",
    faIcon: faBottleDroplet,
  },
  {
    active: false,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "christmas",
    names: {
      da: "Julespecial",
      en: "Christmas Special",
      no: "Julespesial",
      nl: "Kerstspecial",
    },
    type: "labels",
    background: `linear-gradient(107.57deg, #C11411 -18.83%, #A10805 124.05%)`,
    color: "#C11411",
    faIcon: faTreeChristmas,
  },
  {
    active: false,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "halloween",
    names: {
      da: "Halloween Special",
      en: "Halloween Special",
      no: "Halloweenspesial",
      nl: "Halloweenspecial",
    },
    type: "labels",
    background: `linear-gradient(107.57deg, #FF6F59 -18.83%, #DB504A 124.05%)`,
    color: "#FF6F59",
    faIcon: faJackOLantern,
  },
  {
    active: false,
    backendInputCategory: ["labels"],
    frontendDisplayMode: "tag",
    key: "easter",
    names: {
      da: "Påskespecial",
      en: "Easter Special",
      no: "Påskespesial",
      nl: "Paasspecial",
    },
    type: "labels",
    background: `linear-gradient(107.57deg, #FFE97C -18.83%, #FAE062 124.05%)`,
    color: "#FFE97C",
    faIcon: faRabbitRunning,
  },
];

export const attributesDataMap: Map<string, AttributeData> = new Map(
  attributesData.map((attr) => [attr.key, attr])
);

/**
 * Allergens accepted sequence keys from "Food standard Agency(food.gov.uk)".
 *
 * ! Keys are taken from `attributesData`.
 **/
export const allergensStandardSequenceKeys: AttributeData["key"][] = [
  "celery",
  "wheat",
  "crustaceans",
  "egg",
  "fish",
  "lupin",
  "milk",
  "molluscs",
  "mustard",
  "nuts",
  "peanut",
  "sesame",
  "soya",
  "sulphurdioxide",
];

/** Allergens accepted sequence attributeData items from "Food standard Agency(food.gov.uk)". */
export const allergensStandardSequence = allergensStandardSequenceKeys.map(
  (key) => attributesDataMap.get(key)
) as AttributeData[];
