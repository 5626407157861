import { OrderingContext } from "@kanpla/ordering";
import { getErrorMessage, useRequest, useT } from "@kanpla/system";
import { AutomaticRefill, User } from "@kanpla/types";
import {
  DrawerOrModal,
  Form,
  Input,
  activeCurrencyAtom,
  message,
} from "@kanpla/ui";
import { RefillCancelRequest } from "apps/frontend/pages/api/internal/payment/cancelAutomaticRefill";
import { RefillDataRequest } from "apps/frontend/pages/api/internal/payment/setRefillData";
import { useAtomValue } from "jotai";
import { last } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { currencyNumbers } from "./PaymentAmount";

type FormData = {
  refillBelow: number;
  refillAmount: number;
};

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  // this is here because the context takes ages to update
  updateParentRefillData: (newValue: AutomaticRefill | undefined) => void;
  // this also means that there is a refillData object for this wallet
  hasAutomaticRefill: boolean;
  paymentGatewayId?: string;
}

const RefillSettings = (props: Props) => {
  const { open, setOpen } = props;
  const t = useT();
  const { user, userId } = useContainer(OrderingContext);
  // to be able to update the user object locally
  const [localUser, setLocalUser] = useState(user);
  const [activeRefillData, setActiveRefillData] = useState<
    AutomaticRefill | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const activeCurrencyNumbers = currencyNumbers[activeCurrency];
  const minCurrencyValue = activeCurrencyNumbers[0] || 25;
  const maxCurrencyValue = last(activeCurrencyNumbers) || 1000;

  useEffect(() => {
    if (localUser?.refillData && !!localUser?.refillData.length) {
      const refillDataInQuestion = localUser.refillData.find(
        (refillData) =>
          refillData.currency === activeCurrency &&
          refillData.paymentGatewayId === props.paymentGatewayId
      );
      setActiveRefillData(refillDataInQuestion);
      props.updateParentRefillData(refillDataInQuestion);
    } else {
      setActiveRefillData(undefined);
      props.updateParentRefillData(undefined);
    }
  }, [JSON.stringify(localUser), activeCurrency, props.paymentGatewayId]);

  const { request: setRefillRequest } = useRequest<RefillDataRequest, User>(
    "payment/setRefillData"
  );
  const { request: cancelRefillRequest } = useRequest<
    RefillCancelRequest,
    User
  >("payment/cancelAutomaticRefill");

  const handleSubmit = async ({ refillBelow, refillAmount }: FormData) => {
    try {
      setLoading(true);
      const updatedUser = await setRefillRequest({
        userId,
        walletCurrency: activeCurrency,
        // the form returns the value in the currency's main unit, multiplied here
        refillAmount: Number(refillAmount) * 100,
        refillBelow: Number(refillBelow) * 100,
        paymentGatewayId: props.paymentGatewayId,
      });
      setLocalUser(updatedUser);
      setOpen(false);
      message.success(t("Automatic recharging is set"));
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      const updatedUser = await cancelRefillRequest({
        userId,
        walletCurrency: activeCurrency,
      });
      setLocalUser(updatedUser);
      setOpen(false);
      message.success(t("Automatic recharging is cancelled"));
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Auto refill")}
      subtitle={t(
        "If your credit will drop below a certain amount, we will automatically refill it"
      )}
      containerClassName="flex"
      actions={[
        {
          onClick: () => {
            handleCancel();
            setOpen(false);
          },
          label: t("Stop automatic recharging"),
          type: "danger",
          className: props.hasAutomaticRefill ? "" : "hidden",
        },
        {
          label: t("Setup"),
          onClick: () => null,
          loading,
          htmlType: "submit",
          form: "refill-settings-form",
        },
      ]}
      zIndex={1000}
    >
      <Form<FormData>
        id="refill-settings-form"
        layout="vertical"
        onSubmit={handleSubmit}
        defaultValues={{
          refillBelow:
            (activeRefillData && activeRefillData.refillBelow / 100) ||
            minCurrencyValue,
          refillAmount:
            (activeRefillData && activeRefillData.refillAmount / 100) ||
            minCurrencyValue,
        }}
      >
        <Form.Item
          name="refillBelow"
          rules={{
            required: t("Choose amount"),
            min: {
              value: minCurrencyValue,
              message: t("The minimum refilling amount is {min} {currency}", {
                min: minCurrencyValue,
                currency: activeCurrency,
              }),
            },
            max: {
              value: maxCurrencyValue,
              message: t(`You can't request more than {max} {currency}`, {
                currency: activeCurrency,
                max: maxCurrencyValue,
              }),
            },
          }}
        >
          <Input.Number
            label={t(`Recharging when under ({currency}):`, {
              currency: activeCurrency,
            })}
          />
        </Form.Item>
        <Form.Item
          name="refillAmount"
          rules={{
            required: t("Choose amount"),
            min: {
              value: minCurrencyValue,
              message: t("The minimum refilling amount is {min} {currency}", {
                min: minCurrencyValue,
                currency: activeCurrency,
              }),
            },
            max: {
              value: maxCurrencyValue,
              message: t(`You can't refill more than {max} {currency}`, {
                currency: activeCurrency,
                max: maxCurrencyValue,
              }),
            },
          }}
        >
          <Input.Number
            label={t(`Recharging amount ({currency}):`, {
              currency: activeCurrency,
            })}
          />
        </Form.Item>
      </Form>
    </DrawerOrModal>
  );
};

export default RefillSettings;
