import { isNative, LanguageConfigProvider } from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { ConfirmProvider, HotJar, message, MessageContainer } from "@kanpla/ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
/** DO NOT REMOVE THIS IMPORT OR THE LOCALIZATION WILL STOP WORKING */
import { useT } from "@kanpla/system";
import { NextQueryParamProvider } from "next-query-params";
import Head from "next/head";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { Offline, Online } from "react-detect-offline";
import "react-toastify/dist/ReactToastify.css";
import { IntercomProvider } from "react-use-intercom";
import smoothscroll from "smoothscroll-polyfill";
import { useContainer } from "unstated-next";
import NewPayment from "../../../libs/ordering/src/shared/payment/payment/index";
import RefillNotification from "../../../libs/ordering/src/shared/payment/payment/RefillNotification";
import CompanySuppliers from "../components/CompanySuppliers";
import ContextProvider, { AppContext } from "../components/contextProvider";
import CookiesLegalMonster from "../components/CookiesLegalMonster";
import CustomAppBanner from "../components/CustomAppBanner";
import CustomSWRConfig from "../components/CustomSWRConfig";
import FCM from "../components/FCM";
import { Mixpanel } from "../components/Mixpanel";
import NotificationTracking from "../components/notifications/NotificationTracking";
import OrderingContextWrapper from "../components/OrderingContextWrapper";
import ServiceWorker from "../components/SerciveWorker";
import * as gtag from "../lib/gtag";
import "../styles/index.scss";

Router.events.on("routeChangeComplete", (url) => gtag.pageview(url));

if (typeof window === `undefined`) {
  require("localstorage-polyfill");
}

const PaymentWrapper = ({ router }) => {
  const [open, setOpen] = useState(true);

  const modifyOpen = (nextOpen) => {
    setOpen(nextOpen);
    if (nextOpen === false) {
      Router.push({
        pathname: router.pathname,
        query: {},
      });
    }
  };

  return <NewPayment open={open} setOpen={modifyOpen} />;
};

const OfflineComponent = ({ offline = false, setPrevOffline, messages }) => {
  const router = useRouter();
  const menusCheck = router.pathname.startsWith("/screen/");

  useEffect(() => {
    if (menusCheck) return null;

    if (offline) {
      setPrevOffline(true);
      // Don't throw visual error on screens page
      if (!menusCheck)
        message.error({ key: "no-connection-error", content: messages.error });
      return;
    }

    // Don't throw visual error on screens page
    if (!menusCheck)
      message.success({
        key: "connection-restored-success",
        content: messages.success,
      });
    return setPrevOffline(false);
  }, [offline, menusCheck]);

  return null;
};

const CustomRouter = () => {
  const { auth } = useContainer(AppContext);
  const router = useRouter();
  const pathName = router?.asPath;

  useEffect(() => {
    // Wait if authentication is reloading
    if (auth.loading) return;

    console.log(pathName);

    if (
      pathName?.includes("/app") &&
      // Ignore redirect to login if /s/ from redirect is defined
      !pathName?.includes("/s/") &&
      router?.isReady &&
      !auth.user?.uid
    ) {
      router.push({ pathname: "/login", query: { redirectUrl: pathName } });
      return;
    }

    if (!pathName?.includes("/login")) return;

    // If the user is authenticated and on the login screen
    if (pathName?.includes("/login") && auth.user?.uid) {
      const redirectQuery = (router?.query?.redirectUrl as string)?.replace(
        "%3F",
        "?"
      );
      router.push(redirectQuery || "/app");
      return;
    }
  }, [auth?.loading, pathName, router?.isReady, auth?.user?.uid]);

  // Error messages
  if (router.query.error) {
    message.error(decodeURI(router.query.error as string));
    const newQuery = router.query;
    delete newQuery.error;
    Router.push({
      pathname: router.pathname,
      query: newQuery,
    });
  }

  // Succes messages
  if (router.query.success) {
    message.success(decodeURI(router.query.success as string));
    const newQuery = router.query;
    delete newQuery.success;
    Router.push({
      pathname: router.pathname,
      query: newQuery,
    });
  }

  // Payment modal open
  if (router.query.paymentModal === "open" && auth.user) {
    return <PaymentWrapper router={router} />;
  }

  return null;
};

interface Props {
  Component: any;
  pageProps: any;
  err: any;
  supplier: Supplier;
}

const queryClient = new QueryClient();

const Kanpla = (props: Props) => {
  const t = useT();

  const [wasOffline, setWasOffline] = useState<boolean>(false);
  const messages = {
    success: t("Your internet connection has been restored."),
    error: t("You have no internet connection. Please try again later."),
  };
  const [enablePolling, setEnablePolling] = useState(false);

  useEffect(() => {
    setEnablePolling(false);

    setTimeout(() => setEnablePolling(true), 5000);

    return () => setEnablePolling(false);
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();

    // Remove Service workers
    if (navigator) {
      if ("serviceWorker" in navigator) {
        if (typeof caches !== "undefined") {
          caches.keys().then(function (cacheNames) {
            cacheNames.forEach(function (cacheName) {
              caches.delete(cacheName);
            });
          });
        }

        navigator.serviceWorker
          .getRegistrations()
          .then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          })
          .catch(function (err) {
            console.log("Service Worker registration failed: ", err);
          });
      }
    }
  }, []);

  const { Component, pageProps, err } = props;

  useEffect(() => {
    if (isNative && window?.["Intercom"]) {
      window?.["Intercom"]("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  return (
    <LanguageConfigProvider>
      <Head>
        <title>Kanpla</title>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="var(--main-color-500, #fff)" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="facebook-domain-verification"
          content="pwb71kzbll3luyu2bulurc7dak80qt"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="viewport"
          content="width=device-width, viewport-fit=cover, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="apple-touch-icon" href="/icons/icon-512x512.png" />
        {/* @ts-ignore */}
        {isNative ? (
          <style id="scroll-pwa">
            {`html {
                  overflow: hidden;
                  user-select: none;
                  -webkit-user-select: none;
                }

                body {
                  overflow: auto;
                  height: 100%!important;
                  user-select: none!important;
                  -webkit-user-select: none!important;
                }
                `}
          </style>
        ) : (
          <style id="scroll-pwa">
            {`html {
                overflow: scroll;
              }

              body {
                height: auto!important;
                min-height: 100vh;
              }
              `}
          </style>
        )}
      </Head>
      <Offline
        polling={{
          enabled: enablePolling,
          timeout: 30000,
          url: "https://ipv4.icanhazip.com",
          interval: 10000,
        }}
      >
        <OfflineComponent
          offline
          setPrevOffline={(e) => setWasOffline(e)}
          messages={messages}
        />
      </Offline>
      {wasOffline ? (
        <Online>
          <OfflineComponent
            setPrevOffline={(e) => setWasOffline(e)}
            messages={messages}
          />
        </Online>
      ) : (
        <p></p>
      )}

      <QueryClientProvider client={queryClient}>
        <CustomSWRConfig>
          <NextQueryParamProvider>
            <IntercomProvider
              appId="x182n37a"
              apiBase="https://api-iam.eu.intercom.io"
              autoBoot
              autoBootProps={{ hideDefaultLauncher: true }}
              initializeDelay={5000}
            >
              <ContextProvider>
                <ServiceWorker />
                <CompanySuppliers />
                <OrderingContextWrapper>
                  <ConfirmProvider>
                    <Mixpanel />
                    <CustomRouter />
                    <CookiesLegalMonster />
                    <HotJar />
                    <CustomAppBanner />
                    <NotificationTracking />

                    <MessageContainer closeButton={false} hideProgressBar />

                    <Component {...pageProps} err={err} />

                    <RefillNotification />
                    <FCM />
                    <Script
                      id="reepay-checkout-api"
                      src="https://checkout.reepay.com/checkout.js"
                    />
                  </ConfirmProvider>
                </OrderingContextWrapper>
              </ContextProvider>
            </IntercomProvider>
          </NextQueryParamProvider>
        </CustomSWRConfig>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </LanguageConfigProvider>
  );
};

export default Kanpla;
