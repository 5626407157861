import { CombinedOfferItem, OrderLine } from "@kanpla/types";

type Props = {
  amount: number;
  product: Pick<
    CombinedOfferItem,
    | "productLineId"
    | "productId"
    | "id"
    | "name"
    | "photo"
    | "vatRate"
    | "unitPrice"
    | "category"
  >;
  options?: OrderLine["options"];
};

export const createOrderLine = ({ amount, product, options }: Props) => {
  const orderLine: OrderLine = {
    amount,
    unitPrice: product.unitPrice,
    productLineId: product.productLineId,
    productId: product.productId || product.id,
    name: product.name,
    photo: product?.photo,
    options: options || {},
    vatRate: product.vatRate ?? 0.25,
  };

  const productId = product?.productId || product?.id;

  if (productId.startsWith("custom-")) orderLine.category = product?.category;

  return orderLine;
};
