import { callInternalApi } from "@kanpla/system";
import { DomainInfo, Providers } from "@kanpla/types";
import { message } from "@kanpla/ui";
import {
  ValidateEmailOptions,
  ValidateEmailResponse,
} from "apps/frontend/pages/api/internal/signup/validateEmail";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { currentPageAtom } from "./usePageNavigation";
import { schoolIdAtom } from "./useSchool";

export const emailAtom = atom<string>("");
/** Available signup providers (e.g. Microsoft Azure) */
export const providersAtom = atom<Providers[]>([]);
/** If email domain has more than one school associated to it  */
export const multiSchoolEmailAtom = atom<DomainInfo[]>([]);
/** Current domains info */
export const domainsInfoAtom = atom<DomainInfo | DomainInfo[]>([]);
/** Determines the type of input field (company email / any email) */
export const onlyCompanySignupAtom = atom<boolean>(false);

export const useSubmitEmail = () => {
  const setCurrentPage = useSetAtom(currentPageAtom);
  const setEmail = useSetAtom(emailAtom);
  const setDomainsInfo = useSetAtom(domainsInfoAtom);
  const setMultiSchoolsEmail = useSetAtom(multiSchoolEmailAtom);
  const setProviders = useSetAtom(providersAtom);

  const [schoolId, setSchoolId] = useAtom(schoolIdAtom);

  const onlyCompanySignup = useAtomValue(onlyCompanySignupAtom);

  const { supplier } = useContainer(AppContext);

  const handleProviders = (
    alreadyExists: boolean,
    localProviders: Providers[]
  ): boolean => {
    if (alreadyExists) {
      setCurrentPage("accountAlreadyExists");
      if (localProviders?.length > 0) {
        setProviders(localProviders);
      }
      return true;
    }

    if (localProviders?.length > 0) {
      setProviders(localProviders);
      setCurrentPage("provider");
      return true;
    }

    return false;
  };

  const handleResponseData = (domainInfo: DomainInfo | DomainInfo[] = []) => {
    const handledDomainInfo = [domainInfo].flat();

    if (handledDomainInfo?.length > 1) {
      const alreadyExists = handledDomainInfo.find((d) => d?.alreadyExists);
      const localProviders = (alreadyExists?.providers || []).filter(
        (p) => !p.onlyLogin
      );

      if (handleProviders(Boolean(alreadyExists), localProviders)) return;

      const needsVerification = handledDomainInfo.some(
        (d) => d.needsVerification
      );
      setMultiSchoolsEmail(handledDomainInfo);
      return setCurrentPage(
        needsVerification
          ? "emailCode"
          : handledDomainInfo.length > 1
          ? "salesplace"
          : "user"
      );
    }

    setMultiSchoolsEmail(handledDomainInfo);

    const {
      schoolId: resultSchoolId,
      needsVerification,
      providers: allLocalProviders,
      alreadyExists,
    } = handledDomainInfo[0];

    const localProviders = (allLocalProviders || []).filter(
      (p) => !p.onlyLogin
    );

    if (resultSchoolId) setSchoolId(resultSchoolId);
    if (handleProviders(alreadyExists, localProviders)) return;

    setProviders(localProviders);
    setCurrentPage(needsVerification ? "emailCode" : "user");
  };

  const submitEmail = async ({ email, avoidProviders = false }) => {
    setEmail(email);

    try {
      const domainInfo = await callInternalApi<
        ValidateEmailOptions,
        ValidateEmailResponse
      >("signup/validateEmail", {
        email,
        onlyCompanySignup,
        avoidProviders,
        partnerId: supplier?.partnerId || null,
        schoolId,
      });

      setDomainsInfo(domainInfo);
      handleResponseData(domainInfo);
    } catch (err) {
      if (err.code === "not-found") {
        return setCurrentPage("companyNotExisting");
      }
      console.error(err);
      message.error(err?.message);
    }
  };

  return submitEmail;
};
