import { isEmpty } from "lodash";

import {
  AllowanceUsage,
  Discount,
  OrderDiscount,
  OrderLines,
} from "@kanpla/types";

import { createDiscountsWithAllowance } from "../allowance/discounts/createDiscountsWithAllowance";
import { mergeDiscounts } from "../allowance/discounts/mergeDiscounts";
import { calculateOrderTotal } from "../vat/calculateOrderTotal";

interface CreateDiscountsFromBasketArgs {
  basket: OrderLines;
  allowanceUsages: AllowanceUsage[];
  discounts: Discount[];
  showInclusiveVat: boolean;
  vatRate: number;
}

export const createDiscountsFromBasket = ({
  basket,
  allowanceUsages,
  discounts,
  showInclusiveVat,
  vatRate,
}: CreateDiscountsFromBasketArgs) => {
  if (isEmpty(discounts)) return [];
  const withAllowances = !isEmpty(allowanceUsages);

  // If we have allowances, we need to keep in mind that the discounts are limited
  if (withAllowances)
    return createDiscountsWithAllowance({
      basket,
      allowanceUsages,
      discounts,
      showInclusiveVat,
      vatRate,
    });

  // Otherwise we can appply indefinitely
  const orderDiscounts = basket.reduce<OrderDiscount[]>((acc, orderLine) => {
    const targetDiscount =
      discounts.find((discount) =>
        discount.requiredOrder?.layers[0].productIds.includes(
          orderLine.productId
        )
      ) || discounts[0];

    const {
      discount: { amount: discountAmount },
      id: discountId,
      name: discountName,
    } = targetDiscount;

    // Get full price of the order line
    const orderLinePrice = calculateOrderTotal([orderLine], showInclusiveVat);

    // Get price after applying discount
    const discountedOrderLinePrice =
      orderLinePrice - (discountAmount * orderLinePrice) / 100;

    const isFullDiscount = discountAmount === 100;

    // If the discount is 100% then we use the full price, otherwise we use the discounted price
    const discountPrice = isFullDiscount
      ? orderLinePrice
      : discountedOrderLinePrice;

    const { productLineId, amount, name, vatRate } = orderLine;

    const discountPriceWithoutVAT = discountPrice / (1 + vatRate) / amount;

    const orderDiscount: OrderDiscount = {
      isBillable: true,
      alreadyDiscounted: false,
      discountType: "discount-hybridBilling",
      discountId,
      discountName,
      unitDiscountedAmount: discountPrice,
      items: [
        {
          productLineId,
          amount,
          name,
          vatRate,
          unitDiscountedPrice: discountPriceWithoutVAT,
        },
      ],
    };

    return [...acc, orderDiscount];
  }, []);

  return mergeDiscounts({ orderDiscounts });
};
