import { Spinner } from "@kanpla/ui";
import React from "react";

const Loading = () => {
  return (
    <div className="w-full h-screen text-primary-main flex justify-center items-center">
      <Spinner size="medium" useCurrentColor />
    </div>
  );
};

export default Loading;
