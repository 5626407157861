import { faMailbox } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, fn, T, useT } from "@kanpla/system";
import { EmailVerificationState } from "@kanpla/types";
import { Button, message } from "@kanpla/ui";
import { useAtomValue, useSetAtom } from "jotai";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useContainer } from "unstated-next";
import { AppContext } from "../../../contextProvider";
import { currentPageAtom } from "../../lib/usePageNavigation";
import { emailAtom } from "../../lib/useSubmitEmail";
import PageWrapper from "../../PageWrapper";

const ReactCodeInput = dynamic(() =>
  import("react-code-input").catch((err) => {
    return () => <p>Failed to load</p>;
  })
);

const EmailCode = () => {
  const t = useT();
  const { supplier } = useContainer(AppContext);
  const [code, setCode] = useState<string>("");
  const [sendAgainLoading, setSendAgainLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const email = useAtomValue(emailAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);

  const [verifiedDoc] = useDocument<EmailVerificationState>(
    db.collection("emailVerificationStates").doc(email),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );

  useEffect(() => {
    if (!verifiedDoc) return;

    setIsVerified(
      verifiedDoc?.metadata?.fromCache === false &&
        verifiedDoc?.metadata?.hasPendingWrites === false &&
        verifiedDoc?.data()?.verified === true
    );
  }, [verifiedDoc]);

  useEffect(() => {
    if (isVerified) setCurrentPage("user");
  }, [isVerified]);

  const submit = async () => {
    try {
      setLoading(true);
      const codeString = `${code}`;

      await db.collection("emailVerificationStates").doc(email).set({
        email,
        code: codeString,
        verified: true,
      });

      message.success(t("The code is correct"));
    } catch (err) {
      message.error(t("Wrong code"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code.length < 4) return;
    submit();
  }, [code]);

  const sendCodeAgain = async () => {
    try {
      setSendAgainLoading(true);
      const callable = fn.httpsCallable("signup-validateEmail");
      await callable({
        email,
        onlyCompanySignup: true,
        partnerId: supplier?.partnerId || null,
      });

      message.success(t("The code was sent again"));
    } catch (err) {
      message.error(err.message);
    } finally {
      setSendAgainLoading(false);
    }
  };

  const SendAgainComponent = () => {
    return (
      <div className="mt-3 text-center pt-4">
        {t("Didn't receive a code or you don't have it?")}
        <span className="text-primary-main hover:text-primary-dark">
          <Button
            shape="plain"
            className="-mx-3"
            onClick={sendCodeAgain}
            htmlType="button"
            loading={sendAgainLoading}
            dataCy="btn-signup-resend-email-code"
          >
            <a className="font-semibold">{t("Resend the code")}</a>
          </Button>
        </span>
      </div>
    );
  };

  return (
    <PageWrapper
      title={t("Confirm your email address")}
      subtitle={
        <p className="text-center leading-6">
          <T str="Email has been sent to " />
          <span className="text-primary-main font-semibold">
            {{ value: email }}
          </span>
        </p>
      }
      buttonTitle={t("Confirm")}
      onButtonSubmit={submit}
      extraContent={<SendAgainComponent />}
      showBackButton
      stayOnPageAfterSubmit
      buttonLoading={loading}
    >
      <p className="text-center leading-6 pt-2">
        {t("Enter the 4-digit code here:")}
      </p>
      <div className="pb-2 pt-6 flex justify-center">
        <ReactCodeInput
          type="number"
          className="w-full grid grid-cols-4"
          inputStyle={{
            fontFamily: "inherit",
            padding: 12,
            border: "1px solid",
            borderRadius: 6,
            margin: 6,
            textAlign: "center",
            fontSize: 22,
            fontWeight: "600",
            borderColor: "var(--palette-primary-main)",
          }}
          style={{
            display: "grid !important",
          }}
          value={code}
          onChange={(newValue) => setCode(newValue)}
          fields={4}
          name="emailCode"
          inputMode="numeric"
        />
      </div>
      <div className="mt-4 bg-background-secondary border rounded-lg grid grid-cols-8 p-3 px-4">
        <FontAwesomeIcon
          icon={faMailbox}
          className="text-xl text-primary-main mt-1"
        />
        <div className="col-span-7 text-text-secondary text-sm">
          {t(
            "It may take up to 2 minutes for the email to arrive. If you still can't see it, please check in your spam folder."
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default EmailCode;
