import { SelectTypes } from "@kanpla/types";
import { escapeRegExp } from "lodash";

export const selectFilterOption: SelectTypes.FilterOptionFunction = (
  option,
  inputValue
) => {
  const pattern = new RegExp(escapeRegExp(inputValue?.toLowerCase()), "i");
  return pattern.test(`${option?.label}${option?.value}`.toLowerCase());
};
