export const getTodaySeconds = () => {
  const local = Date.now() / 1000; // get time right now
  const localModulus = local % 604800; // seconds to Thursday, 00:00
  const midnightModulus = localModulus % 86400; // seconds to Today, 00:00
  // const adjustCET = 3600 // remember we work in CET

  const dayStart = local - midnightModulus;

  return dayStart;
};
