import { useLocalstorageState } from "rooks";

export const useAllergensState = () => {
  const [_hasSkippedAllergens, _setHasSkippedAllergens] = useLocalstorageState(
    "kanpla:has_skipped_allergens",
    false
  );

  return {
    hasSkippedAllergens: _hasSkippedAllergens,
    setHasSkippedAllergens: _setHasSkippedAllergens,
  };
};
