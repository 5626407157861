import { PaymentProvider, paymentProviderOptions } from "@kanpla/types";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const defaultValue = "card";

export const selectedPaymentMethodAtomRaw = atomWithStorage<PaymentProvider>(
  "kanpla:last-payment-method",
  defaultValue
);

const getValidValue = (rawValue: PaymentProvider) => {
  const isValid = paymentProviderOptions.includes(rawValue);
  const finalValue: PaymentProvider = isValid ? rawValue : defaultValue;
  return finalValue;
};

export const selectedPaymentMethodAtom = atom(
  (get) => {
    const rawValue = get(selectedPaymentMethodAtomRaw);
    return getValidValue(rawValue);
  },
  (get, set, newValue: PaymentProvider) => {
    set(selectedPaymentMethodAtomRaw, getValidValue(newValue));
  }
);
