import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";

import { OrderingContext } from "@kanpla/ordering";
import { SessionResponse } from "@kanpla/services";
import { calculateAmountOfOrderItems, callInternalApi } from "@kanpla/system";
import { AdyenCheckoutApplePayConfiguration } from "@kanpla/types";

import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import {
  basketContainerAtom,
  basketContainerTotalPriceAtom,
  basketResetAtom,
  openBasketAtom,
} from "../../basket/useBasket";
import useBasketPayment from "../useBasketPayment";
import AdyenPaymentComponent, {
  adyenPaymentCheckoutConfigAtom,
} from "./AdyenPaymentComponent";

const AdyenMobilePay = () => {
  const { hasKanplaGo, balance, schoolId, paymentGatewayId, dateSeconds } =
    useContainer(OrderingContext);

  const basketOpen = useAtomValue(openBasketAtom);
  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const basketContainer = useAtomValue(basketContainerAtom);
  const setAdyenCheckoutConfig = useSetAtom(adyenPaymentCheckoutConfigAtom);
  const basketReset = useSetAtom(basketResetAtom);

  const [sessionLoading, setSessionLoading] = useState<boolean>(false);
  const isMobilePay = selectedPaymentMethod === "mobilepay";

  const { loadChargeSession, callbackUrl } = useBasketPayment({
    setLoading: setSessionLoading,
    hasKanplaGo,
  });

  const initializeSession = async () => {
    try {
      // Calculate basket price
      const missingMoney = basketContainerTotalPrice - balance;
      const calculatePrice =
        missingMoney < 0 ? basketContainerTotalPrice : missingMoney;

      // Create order personal
      const validBasketContainer = Object.values(basketContainer).filter(
        (o) => {
          const hasDate = typeof o.dateSeconds === `number`;
          const isValid = calculateAmountOfOrderItems(o.orderLines) > 0 || o.id;
          return hasDate && isValid;
        }
      );

      const { sessionId, provider, sessionData }: SessionResponse =
        await callInternalApi("payment/paymentOnce", {
          unitPrice: calculatePrice,
          recurring: false,
          paymentMethod: "mobilepay",
          isWindowSession: true,
          mode: "order",
          callbackUrl,
          orders: validBasketContainer,
          schoolId,
          paymentGatewayId,
          orderDateSeconds: dateSeconds,
        });

      const payload =
        provider === "adyen"
          ? { provider, sessionId, sessionData }
          : { provider, sessionId };

      const session = await loadChargeSession(payload);

      // Initialize Adyen checkout
      setAdyenCheckoutConfig({
        options: {
          ...(session.config as AdyenCheckoutApplePayConfiguration),
        },
        onSuccess: basketReset,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setSessionLoading(false);
    }
  };

  useEffect(() => {
    if (!isMobilePay || !basketOpen) return;

    setAdyenCheckoutConfig(null);

    initializeSession();
  }, [basketContainerTotalPrice, isMobilePay, basketOpen, callbackUrl]);

  if (sessionLoading) return <div className="loader flex-1" />;

  return <AdyenPaymentComponent id="adyen-payment-checkout" type="mobilepay" />;
};

export default AdyenMobilePay;
