import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Button, Form, message } from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { ReactNode, useState } from "react";
import { useContainer } from "unstated-next";
import { isInModalAtom } from ".";
import { Helpcenter } from "../Helpcenter";
import { AppContext } from "../contextProvider";
import { authModalStateAtom } from "../modals/Anonymous/AnonymousModal";
import PageHeader from "./PageHeader";
import { currentPageAtom, usePageNavigation } from "./lib/usePageNavigation";

interface Props {
  title: string;
  titleSize?: "h500" | "h600" | "h700" | "h800";
  subtitle?: ReactNode;
  topIllustration?: string | IconProp;
  buttonTitle?: string;
  onButtonSubmit?: (data: any) => void | Promise<void>;
  onButtonClick?: () => void | Promise<void>;
  children: ReactNode;
  hideButton?: boolean;
  disableButton?: boolean;
  showBackButton?: boolean;
  buttonLoading?: boolean;
  extraContent?: ReactNode;
  skipButton?: boolean;
  stayOnPageAfterSubmit?: boolean;
  largerWidth?: boolean;
  htmlType?: "button" | "submit";
  dataCy?: string;
  hideAnonymousAuth?: boolean;
  defaultFormValues?: Record<string, any>;
}

const PageWrapper = (props: Props) => {
  const {
    title,
    titleSize,
    subtitle = null,
    buttonTitle,
    children,
    onButtonSubmit = null,
    onButtonClick = null,
    hideButton = false,
    disableButton,
    showBackButton = false,
    extraContent = null,
    skipButton = false,
    topIllustration,
    stayOnPageAfterSubmit = false,
    buttonLoading,
    largerWidth = false,
    htmlType = "submit",
    dataCy = "",
    hideAnonymousAuth = false,
    defaultFormValues = {},
  } = props;

  const { setHasSkippedAllergens } = useContainer(AppContext);

  const t = useT();

  const { goToNextScreen, pages } = usePageNavigation();

  const setAuthModalState = useSetAtom(authModalStateAtom);

  const [loading, setLoading] = useState(false);

  const isInModal = useAtomValue(isInModalAtom);
  const currentPage = useAtomValue(currentPageAtom);
  const currentIndex = pages.indexOf(currentPage);

  const router = useRouter();

  const onSubmit = async ({
    submit,
    data,
  }: {
    submit: (data: unknown) => void | Promise<void>;
    data: unknown;
  }) => {
    try {
      setLoading(true);
      await submit(data);
      if (!stayOnPageAfterSubmit) goToNextScreen();
    } catch (err: any) {
      console.error(err);
      message.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const skip = () => {
    goToNextScreen();
    setHasSkippedAllergens(true);
  };

  return (
    <div className="w-full h-full flex justify-center">
      <div className="w-full h-full flex flex-col items-center">
        {!isInModal && showBackButton && (
          <div className="flex w-full">
            <Button
              shape="plain"
              type="secondary"
              onClick={() =>
                !currentIndex ? router.push("/") : window.history.back()
              }
              dataCy="btn-sign-up-go-back"
              icon={faChevronLeft}
              className="w-fit"
            >
              {t("Back")}
            </Button>
          </div>
        )}
        <div
          className={classNames(
            "md:flex md:flex-col md:items-center mx-auto max-w-md",
            {
              "w-full h-full pb-2": !isInModal,
              "max-w-2xl": largerWidth,
              "max-w-md": !largerWidth,
            }
          )}
        >
          {topIllustration &&
            (typeof topIllustration === "string" ? (
              <img src={topIllustration} alt="" className="-mb-2 mx-auto" />
            ) : (
              <FontAwesomeIcon
                icon={topIllustration}
                className="text-7xl text-text-secondary"
              />
            ))}
          <div
            className={classNames(
              !isInModal && !showBackButton ? "mt-4" : "mt-0"
            )}
          >
            <PageHeader
              titleSize={titleSize}
              title={title}
              titleDataCy={dataCy}
              subtitle={subtitle}
            />
          </div>
          <div className="w-full h-full pt-0 mx-auto pb-16 md:pb-0">
            <Form
              id="page-wrapper-form-signup"
              className="w-full h-full flex flex-col items-center justify-center"
              layout="vertical"
              onSubmit={(data) => {
                onButtonSubmit && onSubmit({ submit: onButtonSubmit, data });
              }}
              defaultValues={defaultFormValues}
            >
              <div className="w-full h-full mt-8 mx-auto">{children}</div>
              {!hideButton && (
                <div className="w-full">
                  <Button
                    type="primary"
                    loading={loading || buttonLoading}
                    className="w-full"
                    size="large"
                    htmlType={htmlType}
                    disabled={disableButton}
                    dataCy="button-page-wrapper"
                    form="page-wrapper-form-signup"
                    onClick={() => {
                      onButtonClick?.();
                    }}
                  >
                    {buttonTitle}
                  </Button>
                </div>
              )}
              {skipButton && (
                <div className="w-full flex justify-center mb-4">
                  <Button
                    shape="plain"
                    type="primary"
                    className="text-center w-full"
                    size="large"
                    onClick={skip}
                    dataCy="btn-signup-add-later"
                  >
                    {t("Skip for now")}
                  </Button>
                </div>
              )}
              {!hideAnonymousAuth && isInModal && (
                <p className="mt-4 text-text-secondary">
                  {t("Do you already have an account?")}{" "}
                  <span
                    onClick={() => {
                      setAuthModalState("login");
                    }}
                    className="font-semibold text-primary-main cursor-pointer"
                  >
                    {t("Log in here!")}
                  </span>
                </p>
              )}
              {extraContent}
              <div className="mt-auto">
                <div className="mt-3 mx-auto flex justify-center">
                  <Helpcenter.Intercom shape="soft" size="small" />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
