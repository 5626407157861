import { getAvailableAccessCodes, useT } from "@kanpla/system";
import { Form, Input } from "@kanpla/ui";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useContainer } from "unstated-next";
import PageWrapper from "../../PageWrapper";
import { useSchool } from "../../lib/useSchool";

const AccessCode = () => {
  const t = useT();

  const { updateCurrentChild } = useContainer(AppContext);
  const { school, modules } = useSchool();

  const allAvailableCodes = getAvailableAccessCodes(modules || [], school);

  const submit = async ({ newCode }: { newCode: string }) => {
    await updateCurrentChild({ codes: [newCode] });
  };

  return (
    <PageWrapper
      title={t("Registration code")}
      subtitle={t(
        "Entering a code, will grant you access to order food on behalf of your employer."
      )}
      buttonTitle={t("Add registration code")}
      topIllustration="/images/signup-flow/access-code.svg"
      onButtonSubmit={submit}
      skipButton
    >
      <Form.Item
        name="newCode"
        rules={{
          validate: (newCode: string) =>
            !allAvailableCodes.includes(newCode)
              ? t("Invalid special password")
              : true,
        }}
      >
        <Input label={t("Special password")} dataCy="special-password" />
      </Form.Item>
      <p className="text-xs text-text-secondary opacity-70 mb-6">
        {t("Grant access to features reserved to selected users")}
      </p>
    </PageWrapper>
  );
};

export default AccessCode;
