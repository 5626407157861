import { School } from "@kanpla/types";
import { useAtomValue, useSetAtom } from "jotai";
import { compact, isEmpty } from "lodash";
import { useEffect } from "react";
import { selectorsAtom } from "./useChild";
import { schoolIdAtom } from "./useSchool";
import { multiSchoolEmailAtom } from "./useSubmitEmail";

export const useMultiSchoolEmail = () => {
  const multiSchoolEmail = useAtomValue(multiSchoolEmailAtom);
  const schoolId = useAtomValue(schoolIdAtom);
  const setSelectors = useSetAtom(selectorsAtom);

  const domainSelectors = multiSchoolEmail.find(
    (domain) => domain.schoolId === schoolId
  )?.selectors;

  const availableSelectors = domainSelectors?.[0]?.options?.length;

  useEffect(() => {
    if (isEmpty(multiSchoolEmail) || availableSelectors !== 1) return;

    const defaultSelector = domainSelectors.reduce((arr, sel) => {
      const name = sel?.name;
      const firstOption = sel?.options?.[0]?.name;

      arr[name] = firstOption;

      return arr;
    }, {});

    setSelectors(defaultSelector);
  }, [availableSelectors]);

  const multiSchoolIds = compact(
    multiSchoolEmail.map((domain) => domain.schoolId)
  );

  const filterMultiSchoolEmail = (school: School) => {
    if (multiSchoolIds.includes(school.id)) return true;
    return false;
  };

  return {
    hasMultiSchoolEmail: !isEmpty(multiSchoolIds),
    hasOneSelectorOnly: availableSelectors === 1,
    domainSelectors,
    filterMultiSchoolEmail,
  };
};
