import { CombinedOfferItem, Module, OrderLine } from "@kanpla/types";
import { createOrderLine } from "../orders/createOrderLine";
import { calculateOrderTotalByModule } from "./calculateOrderTotalByModule";

type Props = {
  product: CombinedOfferItem;
  module: Pick<Module, "paymentMethod" | "type">;
  options?: OrderLine["options"];
};

/**
 * calculateProductPrice uses the product and a module,
 * and calculates how much would the product cost if ordered.
 */
export const calculateProductPrice = ({
  product,
  module,
  options = {},
}: Props) => {
  const potentialOrderLine = createOrderLine({
    product,
    amount: 1,
    options,
  });
  const productPrice = calculateOrderTotalByModule(
    [potentialOrderLine],
    module
  );

  return productPrice;
};
