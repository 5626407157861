import { getChildGroupName, useFetch } from "@kanpla/system";
import {
  GetRegistrationInfoOptions,
  GetRegistrationInfoResponse,
} from "apps/frontend/pages/api/internal/signup/getRegistrationInfo";
import { atom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { selectorsAtom } from "./useChild";

export const schoolIdAtom = atom<string>("");

export const useSchool = () => {
  const { setSchoolId } = useContainer(AppContext);
  const schoolId = useAtomValue(schoolIdAtom);
  const selectors = useAtomValue(selectorsAtom);

  const groupName = getChildGroupName(selectors);
  const shouldFetch = schoolId || groupName;

  const { data, reload } = useFetch<
    GetRegistrationInfoOptions,
    GetRegistrationInfoResponse
  >(shouldFetch ? "signup/getRegistrationInfo" : null, {
    schoolId,
    groupName,
  });

  useEffect(() => {
    if (!shouldFetch || data?.school) return;

    reload();
  }, [shouldFetch]);

  /** Pass the school id to the contextProvider, so we can load supplier accordingly */
  useEffect(() => {
    setSchoolId(schoolId);
  }, [schoolId]);

  return {
    ...data,
  };
};
