import { getErrorMessage, useT } from "@kanpla/system";
import { Button, Form, Input, message } from "@kanpla/ui";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { isInModalAtom, throughInvitationAtom } from "../..";
import PageWrapper from "../../PageWrapper";
import { currentPageAtom } from "../../lib/usePageNavigation";
import { useSchool } from "../../lib/useSchool";
import {
  onlyCompanySignupAtom,
  useSubmitEmail,
} from "../../lib/useSubmitEmail";

const Email = () => {
  const t = useT();

  const isInModal = useAtomValue(isInModalAtom);
  const onlyCompanySignup = useAtomValue(onlyCompanySignupAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);

  const throughInvitation = useAtomValue(throughInvitationAtom);

  const { isSchoolForChildren } = useSchool();

  const [loading, setLoading] = useState(false);
  const submitEmail = useSubmitEmail();

  const { title, subtitle = null } = onlyCompanySignup
    ? {
        title: t("Write your company email"),
        subtitle: t("You will get direct access to your canteen"),
      }
    : isSchoolForChildren
    ? {
        title: t("Write your email"),
      }
    : {
        title: t("Write your email"),
        subtitle: t("Write your company email if possible"),
      };

  const ExtraContent = () => (
    <div
      className={classNames("text-center mt-8", isInModal ? "mb-8" : "mb-2")}
    >
      <p className="mb-2 text-text-secondary text-sm">
        {t("You have a canteen ID or can't use your work email?")}
      </p>
      <Button
        type="primary"
        id="signupGoWithoutCompanyEmail"
        className="mt-1 px-10"
        onClick={() => {
          setCurrentPage("canteenId");
        }}
        shape="soft"
        dataCy="btn-signup-login-canteen-id"
      >
        {t("Register with Canteen ID")}
      </Button>
    </div>
  );

  const submit = async ({ email }: { email: string }) => {
    try {
      setLoading(true);
      await submitEmail({ email });
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title={title}
      subtitle={subtitle}
      buttonTitle={t("Continue")}
      onButtonSubmit={submit}
      extraContent={onlyCompanySignup && <ExtraContent />}
      showBackButton={!throughInvitation}
      stayOnPageAfterSubmit
      buttonLoading={loading}
      htmlType="submit"
      largerWidth
    >
      <Form.Item
        name="email"
        rules={{
          required: t("Please enter the {value}", {
            value: "email",
          }),
          pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
            message: t("Invalid {value}", {
              value: "email",
            }),
          },
        }}
      >
        <Input.Email
          placeholder={t("Enter your email address")}
          required
          dataCy="new-email-signup"
        />
      </Form.Item>
    </PageWrapper>
  );
};

export default Email;
