import ApplePayElement from "@adyen/adyen-web/dist/types/components/ApplePay/ApplePay";
import { OrderingContext } from "@kanpla/ordering";
import { SaveCardAdyenServiceProps } from "@kanpla/services";
import { callInternalApi, delayPromise, useT } from "@kanpla/system";
import {
  AdyenCheckoutApplePayConfiguration,
  AdyenCheckoutOrApplePayConfiguration,
} from "@kanpla/types";
import { message } from "@kanpla/ui";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useRef } from "react";
import { useContainer } from "unstated-next";
import { confirmationModalOpenAtom } from "../../../mealplan2/basket/elements/PurchaseAnimation";
import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";

interface AdyenCheckoutConfigArgs {
  options: AdyenCheckoutOrApplePayConfiguration;
  onSuccess: () => void;
  rememberCard?: boolean;
}

export const adyenPaymentCheckoutConfigAtom =
  atom<AdyenCheckoutConfigArgs | null>(null);

interface AdyenPaymentComponentProps {
  /** Create either a card component or a Apple Pay button */
  type: "card" | "applepay" | "mobilepay";
  applePayButtonColor?: AdyenCheckoutApplePayConfiguration["buttonColor"];
  className?: string;
}

const AdyenPaymentComponent = ({
  type,
  applePayButtonColor = "black",
  className,
  id,
  ...rest
}: AdyenPaymentComponentProps & React.HTMLAttributes<HTMLDivElement>) => {
  const paymentContainerRef = useRef<HTMLDivElement>(null);

  const t = useT();

  const { paymentGatewayId, user, reloadOrders, hasKanplaGo } =
    useContainer(OrderingContext);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const [adyenCheckoutConfig, setAdyenCheckoutConfig] = useAtom(
    adyenPaymentCheckoutConfigAtom
  );
  const setConfirmationModalOpen = useSetAtom(confirmationModalOpenAtom);

  const { options, onSuccess } = adyenCheckoutConfig || {};

  const createCheckout = async (
    options: AdyenCheckoutOrApplePayConfiguration
  ) => {
    try {
      if (!paymentContainerRef.current) return;

      const configuration: AdyenCheckoutOrApplePayConfiguration = {
        ...options,
        showPayButton: true,
        ...(type === "applepay"
          ? { buttonType: "check-out", buttonColor: applePayButtonColor }
          : {}),
        ...(type === "mobilepay" ? { buttonType: "check-out" } : {}),
        onPaymentCompleted: async (result: { resultCode: string }) => {
          if (result.resultCode === "Authorised") {
            message.success("Payment successful");

            if (adyenCheckoutConfig?.rememberCard && paymentGatewayId && user) {
              // Save the card id for later payments in the user wallet
              await callInternalApi<SaveCardAdyenServiceProps, void>(
                "payment/saveCardAdyen",
                {
                  user,
                  paymentGatewayId,
                }
              );
            }
          }

          // Handle refusals
          if (result.resultCode === "Refused") {
            message.error(
              t(
                "The payment has been refused. Try again or with a different card."
              )
            );
            setAdyenCheckoutConfig(null);
            return;
          }

          if (!hasKanplaGo) {
            setConfirmationModalOpen(true);
            setTimeout(() => setConfirmationModalOpen(false), 3000);
          }

          setAdyenCheckoutConfig(null);
          onSuccess?.();
          reloadOrders();
          await delayPromise(5000);
          reloadOrders();
          await delayPromise(5000);
          reloadOrders();
        },
        onError() {
          message.error("Payment failed");
          setAdyenCheckoutConfig(null);
        },
      };

      const AdyenCheckout = (await import("@adyen/adyen-web")).default;
      const checkout = await AdyenCheckout(configuration);

      const createComponent = checkout.create(type);

      // Apple Pay needs to be validated before it can be mounted
      if (type === "applepay") {
        const applePayElement = createComponent as unknown as ApplePayElement;
        const isAvailable = await applePayElement.isAvailable();

        if (!isAvailable) return;
      }

      if (paymentContainerRef.current) {
        createComponent.mount(paymentContainerRef.current);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!options || !paymentContainerRef.current) return;

    createCheckout(options);
  }, [options, selectedPaymentMethod, paymentContainerRef.current]);

  return <div id={id} ref={paymentContainerRef} className={className} />;
};

export default AdyenPaymentComponent;
