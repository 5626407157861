export const domainsWithPublicSchools = [
  "dabba.kanpla.dk",
  "bistrupognoer.kanpla.dk",
  "thecantina.kanpla.dk",
  "viborgpay.kanpla.dk",
  "smoerrebroed.kanpla.dk",
  "martinkok.kanpla.dk",
  "spispaent.kanpla.dk",
];

export const kanplaDomains = [
  "kanpla.dk",
  "app.kanpla.dk",
  "kanpla.io",
  "next.kanpla.dk",
  "localhost",
];

export const domainSignupConfig = {
  switch: [
    ...kanplaDomains,
    "thecantina.kanpla.dk",
    "martinkok.kanpla.dk",
    "dabba.kanpla.dk",
  ],
  list: [
    "bistrupognoer.kanpla.dk",
    "viborgpay.kanpla.dk",
    "smoerrebroed.kanpla.dk",
    "spispaent.kanpla.dk",
  ],
};
