import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, selectFilterOption, useT } from "@kanpla/system";
import { School, Supplier } from "@kanpla/types";
import { Select } from "@kanpla/ui";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  school: School | null;
  schools: Array<School>;
  setSchool: (newSchool: School) => void;
  edit?: boolean;
  company?: boolean;
  allowedSchools?: Array<string>;
  loading?: boolean;
}

const SelectSchool = ({
  school,
  schools,
  allowedSchools,
  setSchool,
  edit = false,
  company,
  loading,
}: Props) => {
  const t = useT();
  // Search Functionality
  const [selected, setSelected] = useState<School | null>(school || null);
  const [options, setOptions] = useState<Array<School>>([]);
  const { supplier } = useContainer(AppContext);

  const [allSuppliers = [], allSuppliersLoading] =
    useCollectionDataOnce<Supplier>(
      !company ? db.collection("suppliers").orderBy("url") : null
    );

  useEffect(() => {
    setSelected(school);
  }, [school?.id]);

  useEffect(() => {
    if (allSuppliersLoading) return;

    const sortedSchools = uniqBy(schools, "id").sort((a, b) =>
      a?.name?.localeCompare(b.name || "")
    );

    if (!allowedSchools?.length) {
      return setOptions(
        sortedSchools.filter((s) => {
          if (!s?.id || !s?.name || s?.deleted) return false;
          return true;
        })
      );
    }

    const filteredSchools = sortedSchools.filter((s) => {
      if (!s?.id || !s?.name || s?.deleted) return false;

      if (s?.id === school?.id || allowedSchools?.includes(s?.id)) return true;

      // Filter out hidden schools
      if (s.hidden) return false;

      // Filter schools and custom items
      if (!company)
        return !allSuppliers
          .map((supplier) => supplier.partnerId)
          .includes(s.partnerId);

      return s.partnerId === supplier?.partnerId;
    });
    setOptions(filteredSchools);
  }, [schools, allSuppliersLoading]);

  return (
    <Select
      isLoading={loading}
      placeholder={t("Search {value}", {
        value: t("point of sale"),
      })}
      value={selected?.id}
      filterOption={selectFilterOption}
      isDisabled={!!edit}
      onChange={(schoolId) => {
        const selectedSchool = schools.find((s) => s.id === schoolId);
        if (!selectedSchool) return;
        setSelected(selectedSchool);
        setSchool(selectedSchool);
      }}
      options={options.map((option) => ({
        value: option?.id,
        label: `${option.name}${
          option?.contact?.zip ? `, ${option.contact.zip}` : ""
        }`,
      }))}
      noOptionsContent={t("No sales places found")}
      noOptionsIcon={<FontAwesomeIcon icon={faStore} className="text-lg" />}
      className="w-full"
      id="selectSchool"
      isSearchable
    />
  );
};

export default SelectSchool;
