import { t } from "@kanpla/system";
import { Child, Supplier } from "@kanpla/types";
import { Allergens, message } from "@kanpla/ui";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import PageWrapper from "../../PageWrapper";
import { childAtom } from "../../lib/useChild";

const ChooseAllergens = () => {
  const { supplier, updateCurrentChild } = useContainer(AppContext);

  const child = useAtomValue(childAtom);

  const [allergens, setAllergens] = useState<Child["allergens"]>({});

  const submit = async () => {
    try {
      await updateCurrentChild({ id: child.id, allergens });
      message.success(t("Allergens information saved"));
    } catch (e) {
      console.error("Error saving allergens", e);
      message.error(t("Something went wrong"));
    }
  };

  return (
    <PageWrapper
      title={t("Choose allergens")}
      subtitle={t("Choose the allergens you are allergic to.")}
      buttonTitle={t("Confirm allergens")}
      onButtonSubmit={submit}
      skipButton
    >
      <Allergens
        supplier={supplier as Supplier}
        child={child as Child}
        value={allergens}
        onChange={setAllergens}
        fromSignup
      />
    </PageWrapper>
  );
};

export default ChooseAllergens;
