import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, getErrorMessage, useT } from "@kanpla/system";
import { Child, Supplier, _FrontendSupplier } from "@kanpla/types";
import { ButtonSubmit, getAttributes, message } from "@kanpla/ui";
import { Dropdown, Menu, Tooltip } from "antd";
import classNames from "classnames";
import React, { Dispatch, SetStateAction, useState } from "react";
import AllergenInput from "./AllergenInput";

export const sectionClasses = "border-t border-main-700 mb-2 py-6 px-4";

type AllergensProps = {
  child: Child;
  onSave?: () => void;
  supplier?: Supplier | _FrontendSupplier | null;
  fromSignup?: boolean;
  value?: Child["allergens"];
  onChange?: Dispatch<SetStateAction<Child["allergens"]>>;
  withBorder?: boolean;
};

export const Allergens = ({
  child,
  supplier,
  value,
  onChange,
  onSave = () => null,
  fromSignup = false,
  withBorder = true,
}: AllergensProps) => {
  const t = useT();

  const [allergens, setAllergens] = useState<Child["allergens"]>(
    child?.allergens || {}
  );
  const [saving, setSaving] = useState(false);

  const submit = async () => {
    try {
      setSaving(true);
      await db.collection("children").doc(child.id).update({
        allergens,
      });
      message.success(t("Allergens information saved"));
      onSave();
    } catch (e) {
      console.error(e);
      message.error(getErrorMessage(e));
    } finally {
      setSaving(false);
    }
  };

  const attributesData = getAttributes({ supplier: supplier }).filter(
    (attr) => attr.type === "allergens"
  );

  const allergensValue = value ? value : allergens;
  const allergensSetter = onChange ? onChange : setAllergens;

  const withExtraStyles = !fromSignup && withBorder && sectionClasses;

  return (
    <div className={classNames(withExtraStyles, "mt-6")}>
      {!fromSignup && withBorder && (
        <>
          <h3 className="heading-main mb-4">{t("Allergens")}</h3>
          <p className="mt-1 text-sm text-text-secondary">
            {t(
              "The allergens are only descriptive, and the canteen may choose to not include them"
            )}
          </p>
        </>
      )}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-y-2 pt-2 pb-8 place-items-baseline">
        {attributesData
          .filter((attribute) => !attribute.relations?.backendUIParent)
          .map((a) => {
            const children = attributesData.filter(
              (attribute) => attribute?.relations?.backendUIParent === a.key
            );
            const hasChildren = children?.length;

            const allergen = (
              <AllergenInput
                attribute={a}
                allergens={allergensValue}
                setAllergens={allergensSetter}
                supplier={supplier}
                childrenAttributes={children}
              />
            );

            if (!hasChildren) return allergen;

            const anyChildActive = Object.entries(allergensValue || {}).some(
              ([value, isActive]) =>
                isActive &&
                value?.split(":")?.[0] === a.key &&
                value.includes(":")
            );

            const menu = (
              <Menu>
                <div className="p-1 mx-2 border-b">{allergen}</div>
                {children.map((child) => (
                  <div className="p-1 px-2">
                    <AllergenInput
                      attribute={child}
                      allergens={allergensValue}
                      setAllergens={allergensSetter}
                      supplier={supplier}
                      parentAttribute={a}
                    />
                  </div>
                ))}
              </Menu>
            );

            return (
              <div className="flex items-center w-full">
                {hasChildren ? (
                  <Tooltip title={t("View more specific cases")}>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <div className="flex items-center">
                        <AllergenInput
                          attribute={a}
                          allergens={allergensValue}
                          supplier={supplier}
                          childrenAttributes={children}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={classNames(
                            "px-4 cursor-pointer transition-colors",
                            anyChildActive
                              ? "text-primary-dark"
                              : "text-text-disabled"
                          )}
                        />
                      </div>
                    </Dropdown>
                  </Tooltip>
                ) : (
                  <AllergenInput
                    attribute={a}
                    allergens={allergensValue}
                    setAllergens={allergensSetter}
                    supplier={supplier}
                    childrenAttributes={children}
                  />
                )}
              </div>
            );
          })}
      </div>
      {!fromSignup && <ButtonSubmit onClick={submit} loading={saving} />}
    </div>
  );
};
