import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { callInternalApi, useRequest, useT } from "@kanpla/system";
import { User } from "@kanpla/types";
import { Button, ModalConfirm, activeCurrencyAtom, message } from "@kanpla/ui";
import { RefillCancelRequest } from "apps/frontend/pages/api/internal/payment/cancelAutomaticRefill";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import CardPreview from "./CardPreview";
interface Props {
  card: any;
  isSelected: boolean;
  setSelectedId: any;
  setOpen: (nextState: boolean) => void;
  setLoading: (nextState: boolean) => void;
}

const CardItem = (props: Props) => {
  const { card, isSelected, setSelectedId, setLoading } = props;
  const t = useT();
  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const { userId, loadCards, paymentGatewayId } = useContainer(OrderingContext);
  const { request: cancelRefillRequest } = useRequest<
    RefillCancelRequest,
    User
  >("payment/cancelAutomaticRefill");

  const deleteCard = async () => {
    try {
      setLoading(true);

      // remove reference from the customer document
      await callInternalApi("payment/cardRemove", {
        cardId: card.cardId,
        userId,
        paymentGatewayId,
      });

      message.success(t("Card removed"));

      // cancel automatisk bestilling
      await cancelRefillRequest({
        userId,
        walletCurrency: activeCurrency,
      });
    } catch (err) {
      console.error(err);
      message.error(t("Unable to delete card"));
    } finally {
      setLoading(false);
      loadCards();
    }
  };

  return (
    <div className="flex items-center mb-3">
      <label className="items-center flex flex-1">
        <input
          type="radio"
          className="form-radio"
          value={card?.cardId ? card.cardId : card.id}
          checked={isSelected}
          onChange={() =>
            setSelectedId(card?.cardId ? card.cardId : card.subscriptionId)
          }
        />
        <div className="bg-background-secondary rounded-l py-4 px-5 ml-4 flex-1">
          <CardPreview card={card} />
        </div>
      </label>
      <ModalConfirm
        content={t("Are you sure you want to remove the card?")}
        onConfirm={deleteCard}
        danger
        zIndex={1000}
      >
        <Button
          className="rounded-l-none h-auto rounded-r flex items-center justify-center ml-1 px-5 w-fit"
          type="danger"
          name={card.metadata.last4}
          aria-label={t("Delete credit card")}
          icon={faTrash}
          dataCy="btn-delete-card"
        />
      </ModalConfirm>
    </div>
  );
};

export default CardItem;
