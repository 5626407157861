import {
  IconDefinition,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import PageWrapper from "../../PageWrapper";
import { schoolIdAtom } from "../../lib/useSchool";
import {
  emailAtom,
  providersAtom,
  useSubmitEmail,
} from "../../lib/useSubmitEmail";

export const providerSetup: {
  [key: string]: {
    name: string;
    id: string;
    icon: IconDefinition;
  };
} = {
  "microsoft.com": {
    name: "Microsoft",
    id: "microsoft.com",
    icon: faMicrosoft,
  },
};

const Provider = () => {
  const t = useT();

  const schoolId = useAtomValue(schoolIdAtom);
  const email = useAtomValue(emailAtom);
  const providers = useAtomValue(providersAtom);
  const submitEmail = useSubmitEmail();

  const router = useRouter();

  const doProvider = async (providerName: string) => {
    router.push(`/signup/${providerName}/${schoolId || "null"}/${email}`);
  };

  return (
    <PageWrapper title={t("Access your canteen")} hideButton showBackButton>
      <div className="text-center pb-12">
        {providers.map((provider) => (
          <Button
            key={provider.name}
            onClick={() => doProvider(provider.name)}
            size="large"
            className="flex mx-auto"
            icon={providerSetup[provider.name].icon}
            dataCy={`btn-provider-${provider.name}`}
          >
            {t("Sign up with {value}", {
              value: providerSetup[provider.name]?.name || provider.name,
            })}
          </Button>
        ))}

        <p className="text-sm text-text-secondary pt-3">
          {t("You will be redirected")}
        </p>
      </div>
      {!providers.some((p) => p.disablePassword) && (
        <div className="text-center pb-8">
          <Button
            onClick={() => submitEmail({ email, avoidProviders: true })}
            dataCy="btn-signup-use-password"
            shape="solid"
          >
            {t("Use password")}
          </Button>
          <p className="text-sm text-text-secondary pt-3">
            {t("Create an account manually")}
          </p>
        </div>
      )}
    </PageWrapper>
  );
};

export default Provider;
