import { OAuthProvider } from "firebase/auth";

interface ExtraProps {
  email?: string;
  tenantId?: string;
}

const getProviderClass = (providerId: string, props?: ExtraProps) => {
  const providerClass = new OAuthProvider(providerId);

  if (providerId === "microsoft.com") {
    providerClass.setCustomParameters({
      tenant: props?.tenantId,
      login_hint: props?.email,
    });
  }

  return providerClass;
};

export default getProviderClass;
