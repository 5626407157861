import { db } from "@kanpla/system";
import { School } from "@kanpla/types";
import { Spinner } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";
import { currentPageAtom } from "./lib/usePageNavigation";
import { schoolIdAtom } from "./lib/useSchool";

/** Note: Only active on invitation flow */
const AutomaticallySetSchool = ({
  children,
  throughInvitation = false,
}: {
  children: ReactNode;
  throughInvitation: boolean;
}) => {
  const setSchoolId = useSetAtom(schoolIdAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);

  // Get schoolId from the query
  const router = useRouter();
  const { schoolId } = router.query;

  const [loading, setLoading] = useState(true);

  const checkIfSchoolExists = async (schoolId: string) => {
    try {
      const res = await db.collection("schools").doc(schoolId).get();

      // If no school, return to the login screen
      if (!res.exists) {
        router.push("/login");
        return;
      }

      // Otherwise set the school to the sign-up-flow context
      const school = {
        ...res.data(),
        id: res.id,
        ref: res.ref,
      } as unknown as School;

      setSchoolId(school.id);
      setCurrentPage("email");
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!throughInvitation) return;
    if (schoolId) checkIfSchoolExists(schoolId.toString());
  }, [schoolId]);

  if (loading && throughInvitation) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <Spinner size="medium" />
      </div>
    );
  }

  return <>{children}</>;
};

export default AutomaticallySetSchool;
