import { Language, School, Supplier, User } from "@kanpla/types";
import { useEffect } from "react";
import { useSessionstorageState } from "rooks";
import { callInternalApi } from "../api/callInternalApi";
import { tx, useT } from "../transifex.config";
import { useDomainLanguage } from "./useDomainLanguage";
import { useNavigatorLanguage } from "./useNavigatorLanguage";

type Props = {
  school?: School;
  user?: User;
  supplier?: Supplier;
};

type Return = {
  updateLanguage: (newLanguage: Language) => Promise<void>;
  selectedLanguage: Language;
};

export const UseLocale = ({ user, school, supplier }: Props): Return => {
  const [selectedLanguage, setSelectedLanguage] =
    useSessionstorageState<Language | null>("kanpla-i18n-lang", null);

  const t = useT();
  const navigatorLang = useNavigatorLanguage();

  const domainLang = useDomainLanguage();

  const checkLanguage = async () => {
    const languageLevels = {
      user: user?.language,
      navigator: navigatorLang,
      school: school?.language,
      supplier: supplier?.language,
      session: selectedLanguage,
      domain: domainLang,
      default: "da",
    };

    const newLanguage = Object.values(languageLevels).find(
      (value) => !!value
    ) as Language;

    setSelectedLanguage(newLanguage);
    await tx.setCurrentLocale(newLanguage);
  };

  const updateLanguage = async (newLanguage: Language) => {
    if (!user?.id) throw new Error(t("Missing user ID"));
    if (!newLanguage) throw new Error(t("Missing language settings"));

    await callInternalApi("submit/user-language", {
      language: newLanguage as Language,
      userId: user?.id,
    });

    setSelectedLanguage(newLanguage);
    await tx.setCurrentLocale(newLanguage);
  };

  // Reset on new user
  useEffect(() => {
    checkLanguage();
  }, [
    user?.language,
    school?.language,
    supplier?.language,
    navigatorLang,
    domainLang,
  ]);

  return {
    updateLanguage,
    selectedLanguage: selectedLanguage || "da",
  };
};
