import { useFetch, useT, validateSelectors } from "@kanpla/system";
import { ChildSelector, School } from "@kanpla/types";
import { Form, SelectorsInput } from "@kanpla/ui";
import SelectSchool from "apps/frontend/components/forms/SelectSchool";
import { LoadSchoolsProps } from "apps/frontend/pages/api/internal/load/schools";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useWatch } from "react-hook-form";
import { useContainer } from "unstated-next";
import { AppContext } from "../../../contextProvider";
import PageWrapper from "../../PageWrapper";
import { selectorsAtom } from "../../lib/useChild";
import { useMultiSchoolEmail } from "../../lib/useMultiSchoolEmail";
import {
  currentPageAtom,
  usePageNavigation,
} from "../../lib/usePageNavigation";
import { schoolIdAtom, useSchool } from "../../lib/useSchool";
import { multiSchoolEmailAtom } from "../../lib/useSubmitEmail";

type FormData = { school: School; selectors: ChildSelector };

const Salesplace = () => {
  const t = useT();
  const { supplier } = useContainer(AppContext);
  const multiSchoolEmail = useAtomValue(multiSchoolEmailAtom);

  const [schoolId, setSchoolId] = useAtom(schoolIdAtom);
  const [selectors, setSelectors] = useAtom(selectorsAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);

  const { goToNextScreen } = usePageNavigation();

  const {
    filterMultiSchoolEmail,
    hasMultiSchoolEmail,
    hasOneSelectorOnly,
    domainSelectors,
  } = useMultiSchoolEmail();

  const { data, loading } = useFetch<LoadSchoolsProps, { schools: School[] }>(
    "load/schools",
    {
      multiSchoolIds: multiSchoolEmail
        .filter((e) => e.schoolId)
        .map((e) => e.schoolId),
    },
    { fallbackData: { schools: [] }, revalidateOnFocus: false }
  );

  const schools = data?.schools || [];

  const { isSchoolForChildren } = useSchool();

  const submit = (data: FormData) => {
    const { school, selectors: selectorsForm } = data;

    setSchoolId(school.id);

    // 2. Check selectors
    const targetSchool = schools.find((s: School) => s.id === schoolId);
    const isSelectorValid = validateSelectors(
      selectorsForm,
      targetSchool?.selectors || []
    );

    if (!isSchoolForChildren && !isSelectorValid)
      throw new Error(t("Fill all the fields"));

    if (isSelectorValid) {
      setSelectors(selectorsForm);
    }

    if (hasMultiSchoolEmail) {
      setCurrentPage("user");
    } else {
      goToNextScreen();
    }
  };

  const availableSchools = hasMultiSchoolEmail
    ? schools.filter(filterMultiSchoolEmail)
    : schools;

  const availableSchoolsIds = availableSchools.map((s) => s.id);
  const schoolIds = schools.map((s) => s.id);

  const hasExtraSelectors = hasMultiSchoolEmail && !hasOneSelectorOnly;

  return (
    <PageWrapper
      title={t("Find your location")}
      buttonTitle={t("Continue")}
      onButtonSubmit={submit}
      showBackButton
      defaultFormValues={{
        school: schools.find((s) => s.id === schoolId),
        selectors,
      }}
    >
      <div className="w-full">
        <Form.Item
          label={t("Point of sale")}
          name="school"
          controlled
          controlledProps={{
            valueName: "school",
            onChangeName: "setSchool",
          }}
          rules={{ required: t("Please select a salesplace") }}
        >
          {/* @ts-ignore */}
          <SelectSchool
            schools={availableSchools}
            company={!isEmpty(supplier?.logo)}
            loading={loading}
            allowedSchools={
              hasMultiSchoolEmail ? availableSchoolsIds : schoolIds
            }
          />
        </Form.Item>
      </div>
      <div
        className={classNames("mt-4", {
          hidden: hasMultiSchoolEmail && hasOneSelectorOnly,
        })}
      >
        <Form.Item
          name="selectors"
          controlled
          controlledProps={{
            valueName: "selectors",
            onChangeName: "setSelectors",
          }}
          className="mt-1"
        >
          {({ watch }) => (
            // @ts-ignore
            <SelectorsInput
              school={watch("school")}
              useExtraSelectors={hasExtraSelectors}
              extraSelectors={hasExtraSelectors ? domainSelectors : []}
            />
          )}
        </Form.Item>
      </div>
    </PageWrapper>
  );
};

export default Salesplace;
