import {
  faCcAmex,
  faCcApplePay,
  faCcMastercard,
  faCcVisa,
  faGooglePay,
} from "@fortawesome/free-brands-svg-icons";
import {
  faChevronDown,
  faCreditCard,
  faPlus,
  faRectangleHistoryCirclePlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { PaymentProvider } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import classNames from "classnames";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { rememberCardAtom } from "../../mealplan2/basket/elements/Payment";
import { selectedPaymentMethodAtom } from "../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import { SelectPayment } from "./SelectPayment";

interface Props {
  className?: string;
  label?: "pay" | "select";
  showEditCardsButton?: boolean;
}

interface IconImageProps {
  icon: string;
  paymentMethod: "mobilepay";
  alt: string;
}

const IconImage = ({ icon, paymentMethod, alt }: IconImageProps) => (
  <img
    src={`/images/${paymentMethod ? icon : "add-card-icon"}.png`}
    className={`w-8 object-contain mr-4 ${!paymentMethod ? "mt-1" : ""}`}
    alt={alt}
  />
);

const DisplayDefaultCard = (props: Props) => {
  const { className = "", label = "pay", showEditCardsButton = false } = props;
  const { allowMobilePay, card } = useContainer(OrderingContext);
  const t = useT();
  const [open, setOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useAtom(selectedPaymentMethodAtom);
  const setRememberCard = useSetAtom(rememberCardAtom);

  const onSelect = (method: PaymentProvider) => {
    setPaymentMethod(method);
    method !== "card" && setRememberCard(false);
  };

  useEffect(() => {
    if (!allowMobilePay && paymentMethod === "mobilepay")
      setPaymentMethod("card");
  }, [allowMobilePay, paymentMethod]);

  const getPaymentIcon = () => {
    switch (paymentMethod) {
      case "applepay":
        return <FontAwesomeIcon size="lg" icon={faCcApplePay} />;
      case "googlepay":
        return <FontAwesomeIcon size="lg" icon={faGooglePay} />;
      case "mobilepay":
        return (
          <IconImage
            icon={"MPay"}
            paymentMethod={paymentMethod}
            alt={t("Card icon")}
          />
        );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      case "":
        return (
          <FontAwesomeIcon size="lg" icon={faRectangleHistoryCirclePlus} />
        );
      default:
        return <FontAwesomeIcon size="lg" icon={faCreditCard} />;
    }
  };

  const getPaymentMethodName = () => {
    switch (paymentMethod) {
      case "applepay":
        return "Apple Pay";
      case "googlepay":
        return "Google Pay";
      case "mobilepay":
        return "Mobile Pay";
      case "card":
        return `${card ? t("Default card") : t("Card")}`.toLowerCase();
      default:
        return t("Card");
    }
  };

  return (
    <>
      <Button
        className={classNames(
          "w-full flex items-center justify-between cursor-pointer",
          paymentMethod === "card" && card ? "py-2" : "py-3",
          className
        )}
        onClick={() => setOpen(true)}
        size="large"
        dataCy="payment-method-button"
        type="primary"
        shape="soft"
      >
        <div className="flex items-center justify-between w-full text-primary-main">
          <div className="flex items-center">
            {paymentMethod !== "card" || !card ? (
              <span
                className={`w-8 overflow-hidden block object-contain mr-4 ${
                  !paymentMethod ? "mt-1" : ""
                }`}
              >
                {getPaymentIcon()}
              </span>
            ) : (
              <FontAwesomeIcon
                icon={
                  card.metadata.brand === "visa"
                    ? faCcVisa
                    : card.metadata.brand === "mc"
                    ? faCcMastercard
                    : card.metadata.brand === "amex"
                    ? faCcAmex
                    : faCreditCard
                }
                size="lg"
                className="mr-4"
              />
            )}
            <div className="flex flex-col justify-start">
              <span className="capitalize">
                {paymentMethod
                  ? `${
                      label === "pay" ? t("Pay with ") : ""
                    }${getPaymentMethodName()}`
                  : t("Choose payment method")}
              </span>
              {paymentMethod === "card" && card && (
                <span className="text-text-secondary text-xs text-left">
                  {t("ends with {value}", { value: card.metadata.last4 })}
                </span>
              )}
            </div>
          </div>
          <FontAwesomeIcon
            icon={paymentMethod ? faChevronDown : faPlus}
            className={`${paymentMethod ? "mt-1" : ""}`}
          />
        </div>
      </Button>
      <SelectPayment
        open={open}
        setOpen={setOpen}
        selectedMethod={paymentMethod}
        showCardButton={showEditCardsButton}
        onSelect={(method) => {
          setPaymentMethod(method);
          onSelect(method);
        }}
      />
    </>
  );
};

export default DisplayDefaultCard;
