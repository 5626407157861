import { faIntercom } from "@fortawesome/free-brands-svg-icons";
import { tx, useT } from "@kanpla/system";
import { BaseButtonProps, Button } from "@kanpla/ui";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import CompanyInfo from "./CompanyInfo";

const BaseHelpcenter = () => {
  const t = useT();

  const [infoOpen, setInfoOpen] = useState(false);

  const helpLanguage = tx.getCurrentLocale() === "da" ? "da" : "en";
  const url = `https://help.kanpla.dk/${helpLanguage}/`;

  return (
    <div className="w-full flex flex-col justify-center overflow-y-hidden relative mt-2">
      <Button
        type="primary"
        shape="plain"
        dataCy="button-open-intercom-help"
        className="font-semibold text-lg text-center"
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {t("Visit our Help Center")}
      </Button>

      <Intercom />

      <Button
        type="primary"
        shape="plain"
        dataCy="button-open-contact-info"
        className="font-semibold text-lg"
        onClick={() => setInfoOpen(true)}
      >
        {t("Contact information")}
      </Button>

      {/* Info Modal */}
      <CompanyInfo open={infoOpen} setOpen={setInfoOpen} />
    </div>
  );
};

const Intercom: React.FC<{
  type?: BaseButtonProps["type"];
  shape?: BaseButtonProps["shape"];
  size?: BaseButtonProps["size"];
}> = ({ type = "primary", shape = "plain", size = "medium" }) => {
  const t = useT();
  const { show } = useIntercom();

  return (
    <Button
      onClick={show}
      type={type}
      shape={shape}
      icon={faIntercom}
      size={size}
      dataCy="button-open-intercom-chat"
      className="font-semibold text-lg"
    >
      {t("Chat with us")}
    </Button>
  );
};

type CompoundedIntercomType = typeof BaseHelpcenter & {
  Intercom: typeof Intercom;
};

(BaseHelpcenter as CompoundedIntercomType).Intercom = Intercom;

export const Helpcenter = BaseHelpcenter as CompoundedIntercomType;
