import { db, useT } from "@kanpla/system";
import { Form, InvoiceReference } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useContainer } from "unstated-next";
import { AppContext } from "../../../contextProvider";
import PageWrapper from "../../PageWrapper";
import { childAtom } from "../../lib/useChild";
import { useSchool } from "../../lib/useSchool";

const Reference = () => {
  const t = useT();
  const { updateCurrentChild } = useContainer(AppContext);

  const { modules = [] } = useSchool();
  const child = useAtomValue(childAtom);

  const submit = async ({ invoiceReference }: { invoiceReference: string }) => {
    await updateCurrentChild({
      id: child.id,
      defaultReference: invoiceReference,
    });
  };

  const module = modules.find(
    (module) => module?.plugins?.invoiceReference?.active
  );

  const invoiceReferenceData = module?.plugins?.invoiceReference;

  const title = invoiceReferenceData?.title || t("Reference number");
  const description =
    invoiceReferenceData?.description ||
    t(
      "Insert a number to help your organization assign your orders (e.g. your employee number)"
    );

  const isRequired = invoiceReferenceData?.defaultRequired || false;

  if (!module) return null;

  return (
    <PageWrapper
      title={title}
      subtitle={description}
      buttonTitle={t("Add {what}", { what: title })}
      topIllustration="/images/signup-flow/reference.svg"
      onButtonSubmit={submit}
      skipButton={!isRequired}
    >
      <Form.Item
        name={"invoiceReference"}
        required={module?.plugins?.invoiceReference?.required ?? true}
        controlled
        rules={{
          required: {
            value: module?.plugins?.invoiceReference?.required ?? true,
            message: t("{fieldName} is required", {
              fieldName: module?.plugins?.invoiceReference?.title,
            }),
          },
          minLength: {
            value: module?.plugins?.invoiceReference?.minLength || 0,
            message: t("write at least {value} characters", {
              value: module?.plugins?.invoiceReference?.minLength,
            }),
          },
        }}
      >
        <InvoiceReference module={module} noExtraMargin />
      </Form.Item>
    </PageWrapper>
  );
};

export default Reference;
