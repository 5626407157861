import { faIdCard } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { Input } from "@kanpla/ui";
import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  haveCard?: boolean;
  setHaveCard: Dispatch<SetStateAction<boolean>>;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const KanplaCard = (props: Props) => {
  const { value, onChange, haveCard, setHaveCard, ...rest } = props;
  const t = useT();

  // If no value is provided, let's set the haveCard on unmount
  useEffect(() => {
    !value && setHaveCard(false);
  }, [value, setHaveCard]);

  return (
    <>
      <div className="flex items-center">
        <Input
          label={t("Kanpla Card ID")}
          id="card-input"
          placeholder={t("eg. 100000")}
          value={value as string}
          onChange={onChange}
          onFocus={() => {
            setHaveCard(true);
          }}
          onBlur={() => setHaveCard(+value >= 100000 && +value <= 140000)}
          min={haveCard && "100000"}
          max={haveCard && "140000"}
          icon={faIdCard}
          iconRight
          {...rest}
        />
      </div>
      <p className="text-xs text-text-secondary mb-4 mt-1 opacity-70">
        {t(
          "Present your Kanpla Card for scanning at the counter, and your meal will be promptly handed out to you"
        )}
      </p>
    </>
  );
};

export default KanplaCard;
