import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useEffect } from "react";

import { LoadAllowanceArgs } from "@kanpla/services";
import {
  AllowanceUsage,
  Child,
  Discount,
  Order,
  School,
  User,
  _FrontendModule,
} from "@kanpla/types";

import { getAllowanceRulesFromDiscounts } from "../allowance/util/getAllowanceRulesFromDiscounts";
import { callInternalApi } from "../api/callInternalApi";

interface LoadAllowanceUsageArgs {
  userId: User["id"];
  schoolId: School["id"];
  childId: Child["id"];
  groupName?: Child["groupName"];
  module: _FrontendModule | null;
  /** Current dateSeconds */
  dateSeconds: number;
  orders: Order[];
  discounts?: Discount[];
}

export const useLoadAllowanceUsages = ({
  userId,
  schoolId,
  childId,
  module,
  dateSeconds,
  groupName,
  orders,
  discounts,
}: LoadAllowanceUsageArgs): AllowanceUsage[] => {
  const hybridBillingAllowance = Boolean(
    module?.plugins?.hybridBilling?.active
  );

  const allowanceDiscounts = getAllowanceRulesFromDiscounts({
    discounts: discounts || [],
    moduleId: module?.id || "",
  });

  const { data, isError, refetch } = useQuery({
    queryKey: [
      "loadAllowances",
      { moduleId: module?.id || "", dateSeconds, schoolId },
    ],
    queryFn: () => {
      if (!userId || !module) return;

      return callInternalApi<LoadAllowanceArgs, AllowanceUsage[]>(
        "load/allowances",
        {
          moduleId: module.id,
          allowanceRules: [
            ...allowanceDiscounts,
            ...(module?.allowanceRules || []),
          ],
          dateSeconds,
          schoolId,
          childId,
          groupName,
        }
      );
    },
    enabled: !isEmpty(module?.allowanceRules) || !isEmpty(allowanceDiscounts),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60 * 1, // 1 hour
  });

  useEffect(() => {
    refetch();
  }, [orders.length, schoolId, dateSeconds, hybridBillingAllowance]);

  const correctModule = module && module?.type === "mealplan";

  if (!correctModule || isError) return [];

  return data || [];
};
