import {
  AllowanceUsage,
  CombinedOfferItem,
  _FrontendModule,
} from "@kanpla/types";
import { isEmpty } from "lodash";
import { calculateProductPrice } from "../vat/calculateProductPrice";
import { getAllowancesForProduct } from "./getAllowancesForProduct";

interface IsAllowanceOverForProductArgs {
  product: CombinedOfferItem;
  allowanceUsages: AllowanceUsage[];
  module: _FrontendModule;
}

export const isAllowanceOverForProduct = ({
  product,
  allowanceUsages,
  module,
}: IsAllowanceOverForProductArgs) => {
  const allowancesForProduct = getAllowancesForProduct({
    productId: product.id,
    allowanceUsages,
  });

  // 1. No allowances for this product, don't disable
  if (isEmpty(allowancesForProduct)) return false;

  const overUsage = allowancesForProduct.some((a) => a.isAllowanceOver);

  // 2. At least one allowance is over, disable
  if (overUsage) return true;

  // 3. No allowance is over, check if the usage for specific product is over (remaning allowance lower than product price)
  const withUnitPrice = allowancesForProduct.filter((a) => a?.unitPrice);

  if (isEmpty(withUnitPrice)) return false;

  const productPrice = calculateProductPrice({ product, module });
  const targetAllowanceUnitPrice = Math.min(
    ...withUnitPrice.map((a) => a?.unitPrice?.amount || Infinity)
  );

  // 4. If the lowest unit price is lower than the product price, disable
  return targetAllowanceUnitPrice < productPrice;
};
