import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { db, useT } from "@kanpla/system";
import { Card } from "@kanpla/types";
import { Button, DrawerOrModal, Spinner, message } from "@kanpla/ui";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import CardAddButtonWrapper from "./CardAddButtonWrapper";
import CardItem from "./CardItem";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
  subtitle?: string;
}

const isCardSelected = (card: Card, selectedId: string | undefined) =>
  card?.cardId === selectedId;

const CardsManage = (props: Props) => {
  const t = useT();
  const { open, setOpen } = props;

  const { card, cards, setCard, loadCards, userId } =
    useContainer(OrderingContext);
  const defaultCardId = card?.cardId || "";
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();

  useEffect(() => {
    setSelectedId(defaultCardId);
  }, [defaultCardId, cards, open]);

  const send = async () => {
    if (!selectedId) {
      message.info(t("Select a card"));
      return;
    }

    const selectedCard = (cards || []).find((card: Card) =>
      isCardSelected(card, selectedId)
    );

    try {
      await db.collection("customers").doc(userId).update({
        defaultCardId: selectedCard?.cardId,
      });

      setOpen(false);
      loadCards();
      setCard(card);
      message.success(t("Payment method updated"));
    } catch (err) {
      console.error(err);
      message.error(t("Payment method could not be updated"));
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      zMax
      title={t("My cards")}
      subtitle={t("Select your default card")}
      actions={[
        {
          label: t("Confirm"),
          onClick: send,
          className: "primary",
        },
      ]}
    >
      <div className="pt-4 pb-3">
        {loading && (
          <div className="pb-6 flex items-center justify-center">
            <Spinner size="medium" useCurrentColor />
          </div>
        )}
        {!loading &&
          (cards || [])
            .filter((c) => !c.hasOwnProperty("subscriptionId"))
            .map((card: Card, index: number) => (
              <CardItem
                key={index.toString()}
                card={card}
                isSelected={isCardSelected(card, selectedId)}
                setSelectedId={setSelectedId}
                setOpen={setOpen}
                setLoading={setLoading}
              />
            ))}
        <div className="flex w-full">
          <CardAddButtonWrapper setLoading={setLoading}>
            <Button
              size="large"
              disabled={loading}
              icon={faPlus}
              loading={loading}
              loadingText={t("Please wait...")}
              dataCy="btn-add-new-card"
              shape="solid"
              className="w-full"
            >
              {t("Add new card")}
            </Button>
          </CardAddButtonWrapper>
        </div>
      </div>
    </DrawerOrModal>
  );
};

export default CardsManage;
