/* eslint-disable max-lines */
import { useBasketPreventClick } from "@kanpla/ordering";
import {
  UseAuth,
  UseLocale,
  UseTimeNavigation,
  callFunction,
  getActivePlugins,
  getErrorMessage,
  getModulesByChildSchoolId,
  getPartnerUrl,
  getUserLanguage,
  hasAccessToModule,
  scroll2,
  sortModules,
  useAvailableLanguages,
  useFetch,
  useLoadFrontend,
  useLocationHostname,
  useNavigatorLanguage,
  useSubmit,
} from "@kanpla/system";
import {
  Child,
  LoadOfferReturn,
  Partner,
  PaymentGateway,
  User,
} from "@kanpla/types";
import { message } from "@kanpla/ui";
import { atom, useAtom, useSetAtom } from "jotai";
import { schoolIdAtom } from "libs/ordering/src/context";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { isMobile, osName } from "react-device-detect";
import { unstable_batchedUpdates } from "react-dom";
import { useIntercom } from "react-use-intercom";
import { useLocalstorageState } from "rooks";
import { createContainer } from "unstated-next";
import useCreateChild from "../lib/signup/useCreateChild";
import { useAllergensState } from "../lib/useAllergensState";
import { useBalance } from "../lib/useBalance";
import { useCustomerCard } from "../lib/useCustomerCard";
import { useStatusBar } from "../lib/useStatusBar";
import {
  LoadPartialPartnerProps,
  LoadPartialPartnerResult,
} from "../pages/api/internal/load/partialPartner";
import { UpdateChildProps } from "../pages/api/internal/user/updateChild";
import { UpdateUserProps } from "../pages/api/internal/user/updateUser";
import CustomIntercom from "./UseIntercom";
import useModules from "./useModules";

export const paymentGatewayIdAtom = atom<PaymentGateway["id"] | undefined>(
  undefined
);

export const partnerDataSubjectAtom = atom<Partner["dataSubject"] | undefined>(
  undefined
);

export const translationsAvailableAtom = atom<boolean>(false);

const ContextState = () => {
  const { update } = useIntercom();

  const router = useRouter();

  const auth = UseAuth({ updateIntercomUser: update });
  const { user: authUser } = auth;
  const [overrideUser, setOverrideUser] = useState(authUser);

  const setPaymentGatewayId = useSetAtom(paymentGatewayIdAtom);
  const setPartnerDataSubject = useSetAtom(partnerDataSubjectAtom);
  const setTranslationsAvailable = useSetAtom(translationsAvailableAtom);

  // Native status bar
  const { statusBarStyle, setStatusBarStyle } = useStatusBar();

  useEffect(() => {
    setOverrideUser(authUser);
  }, [authUser?.uid]);

  const userId = overrideUser?.uid || (overrideUser as any)?.id || null;

  const [isOverridingUser, setIsOverridingUser] = useState<boolean>(false);

  // MODULE WRAPPER
  const [isBulk, setIsBulk] = useState<boolean | null>(null);

  // UI
  const [__kanplaGoOrderFromWindow, __setKanplaGoOrderFromWindow] =
    useState(false);

  // Url
  const parseResult = useLocationHostname({ defaultValue: undefined });
  const url = getPartnerUrl({ hostname: parseResult });

  // ChildId
  const [selectedChildId, setChildId] = useLocalstorageState<string | null>(
    "current_child_id"
  );

  // Browser language
  const browserLanguage = useNavigatorLanguage(true);

  // Load everything
  const [defaultSchoolId, setSchoolId] = useAtom(schoolIdAtom);
  const {
    user,
    isSuperadmin,
    card: receivedCard,
    cards: receivedCards,
    children: childrenResult = [],
    child,
    schools: childSalesplaceSchools,
    school,
    allModules = [],
    modules = [],
    supplier,
    getOffer,
    loading,
    initialLoading,
    triggerReload,
    setData,
    allowMobilePay,
    childId,
    schoolId,
    partnerId,
    innerAppLoading,
    paymentGatewayId,
    paymentGatewayProvider,
    domainSelectors,
    translations,
    dataSubjectInfo,
  } = useLoadFrontend({
    userId,
    url,
    schoolId: defaultSchoolId || undefined,
    childId: selectedChildId || undefined,
    language: browserLanguage || undefined,
  });

  useEffect(() => {
    setPartnerDataSubject(dataSubjectInfo);
  }, [dataSubjectInfo]);

  useEffect(() => {
    if (paymentGatewayId) setPaymentGatewayId(paymentGatewayId);
  }, [paymentGatewayId]);

  useEffect(() => {
    setTranslationsAvailable(translations?.shouldTranslate || false);
  }, [translations?.shouldTranslate]);

  const children = childrenResult || [];

  const { createChild } = useCreateChild(children, setData);

  // Sub values
  const defaultReference = (child as Child)?.defaultReference;
  const appLoading = initialLoading;
  const moduleLoading = loading;
  const supplierLoading = initialLoading;

  // Hold states
  const { balance, setBalance } = useBalance({ user, schoolId });
  const { moduleId, setModuleId, module, previousModuleId } = useModules({
    modules: modules || [],
  });

  // Set bulk if no individual available
  useEffect(() => {
    if (!moduleId) return;
    if (!child || !school) return;
    if (module?.type !== "flex") return;

    const accessCheck = hasAccessToModule({
      child,
      school,
      module,
    });

    if (isBulk && !accessCheck.bulk) setIsBulk(false);
    if (!isBulk && !accessCheck.individual) setIsBulk(true);
  }, [moduleId, childId, isBulk]);

  // Time
  const {
    week,
    timeNavigation,
    setTimeNavigation,
    dayIndex,
    date,
    setDate,
    dateSeconds,
    displayDate,
  } = UseTimeNavigation(module);

  // If the allergens have being skipped
  const { hasSkippedAllergens, setHasSkippedAllergens } = useAllergensState();

  const [os, setOs] = useState<string | null>(null);

  useEffect(() => {
    if (innerAppLoading) return;

    scroll2({ top: 0 });
  }, [innerAppLoading, moduleId]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    setOs(osName);
  }, [typeof window]);

  const confirmationPopup = useBasketPreventClick({});
  const setChild = async (child: Child | null): Promise<void> => {
    try {
      await confirmationPopup?.(module);
      const modules = await getModulesByChildSchoolId(child);
      const sortedModules = sortModules(modules);

      /**
       * Since current function is asynchronous, the React won't perform batched updates on its own.
       * And the `load/frontend` will be fired twice(on childId and schoolId changes).
       **/
      unstable_batchedUpdates(() => {
        setChildId(child?.id || null);
        setSchoolId(child?.schoolId || null);
        setModuleId(sortedModules?.[0]?.id || null);
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      console.error(errorMessage);
      message.error(errorMessage);
    }
  };

  const offer: LoadOfferReturn = getOffer(moduleId);
  const {
    card,
    cards = [],
    setCard,
    loadCards,
  } = useCustomerCard({
    userId,
    card: receivedCard,
    cards: receivedCards,
  });

  // Update functions
  const { submit: updateCurrentChild } = useSubmit<
    UpdateChildProps,
    Partial<Child>,
    typeof setData
  >({
    path: "user/updateChild",
    setData,
    requestConstructor: (data) => ({
      childId: data.id || child?.id,
      child: { ...data, userId },
    }),
    responseDestructor: (response) => {
      const newChildren: Child[] = [
        ...(children?.filter((ch) => ch.id !== response?.id) || []),
        { ...child, ...response },
      ] as Child[];

      const filteredChildren = newChildren.filter((ch) => !ch.deleted);

      unstable_batchedUpdates(() => {
        setData({ children: filteredChildren });

        if (response.deleted) {
          const [firstChild] = filteredChildren;
          setChild(firstChild);
        }
      });

      return {
        child: !response.deleted ? { ...child, ...response } : null,
        children: filteredChildren,
      };
    },
  });

  const { submit: updateUser } = useSubmit<
    UpdateUserProps,
    Partial<User>,
    typeof setData
  >({
    path: "user/updateUser",
    setData,
    requestConstructor: (data) => ({ user: data, userId: data.id || userId }),
    responseDestructor: (response) => ({
      user: { ...user, ...response },
    }),
  });

  const [deficit, setDeficit] = useState(null);

  const mobile = isMobile;

  const availableLanguages = useAvailableLanguages();

  const { updateLanguage, selectedLanguage } = UseLocale({
    school,
    user,
    supplier,
  });

  const { activePlugins } = getActivePlugins({
    module,
    schoolId,
    groupName: child?.groupName,
  });

  // Supplier info
  const isChildSalesplace = supplier?.childIsSalesplace || false;

  // Tracking id is the notification reference that the user has opened
  const trackingId = useMemo(() => {
    const { trackingId } = router.query;
    if (!trackingId) return null;

    return trackingId as string;
  }, [router.query]);

  // Track notification events
  useEffect(() => {
    if (!trackingId || !childId || !schoolId || !partnerId) return;

    (async () => {
      await callFunction("popups-registerSeenNotificationEvent", {
        schoolId,
        childId,
        partnerId,
        notificationId: trackingId,
      });
    })();
  }, [trackingId, childId, schoolId, partnerId]);

  const { data: partialPartner } = useFetch<
    LoadPartialPartnerProps,
    LoadPartialPartnerResult
  >(
    "load/partialPartner",
    {
      partnerId,
      userId,
    },
    {
      isPaused: () => !partnerId || !userId,
      revalidateOnFocus: false,
    }
  );

  return {
    // DATA: User
    auth,
    userId,
    user,
    isSuperadmin,
    setOverrideUser, // super-admin!
    isOverridingUser, // if the super-admin is overriding a user
    setIsOverridingUser,

    // DATA: Money
    deficit,
    setDeficit,
    balance,
    setBalance,

    // DATA: Payment cards
    card,
    cards,
    setCard,
    loadCards,

    // DATA: Children + child
    children,
    child,
    childId,
    setChildId,
    groupName: child?.groupName,
    defaultReference,

    // DATA: School
    school,
    schoolId,
    setSchoolId,
    partnerId,
    supplier,
    isChildSalesplace,
    allowMobilePay,
    partner: partialPartner,
    domainSelectors,

    // Available schools if `isChildSalesplace`
    childSalesplaceSchools,
    createChild,

    // DATA: Modules
    allModules,
    modules,
    module,
    moduleId,
    setModuleId,
    previousModuleId,
    isBulk,
    setIsBulk,

    supplierLoading,

    // UI: Time navigation
    week,
    /* Selected day index */
    dayIndex,
    date,
    dateSeconds,
    setDate,
    displayDate,
    timeNavigation,
    setTimeNavigation,

    appLoading,
    innerAppLoading,
    moduleLoading,

    // DATA: Languages
    availableLanguages,
    updateLanguage,

    // UI: Device detect
    mobile,

    __kanplaGoOrderFromWindow,
    __setKanplaGoOrderFromWindow,
    activePlugins,

    // Native status bar style
    statusBarStyle,
    setStatusBarStyle,

    offer,
    getOffer,

    triggerReload,

    os,
    updateCurrentChild,

    setFrontendData: setData,
    defaultSchoolId,

    paymentGatewayId,
    paymentGatewayProvider,
    setChild,
    updateUser,

    // If user has skipped allergens
    hasSkippedAllergens,
    setHasSkippedAllergens,
    userDeviceLanguage: getUserLanguage(
      browserLanguage || null,
      selectedLanguage
    ),
  };
};

export const AppContext = createContainer(ContextState);

interface Props {
  children: ReactNode;
}

const ContextProvider = (props: Props) => {
  return (
    <AppContext.Provider>
      {props.children}
      <Extras />
    </AppContext.Provider>
  );
};

const Extras = () => {
  return <CustomIntercom />;
};

export default ContextProvider;
