import { useEscapeKeyPress } from "@kanpla/system";
import { Portal } from "@mui/material";
import classNames from "classnames";
import { atom, useSetAtom } from "jotai";
import React, { useEffect, useRef } from "react";
import { animated, config, useTransition } from "react-spring";

interface Props {
  children: any;
  open: boolean;
  setOpen: (nextState: boolean) => void;
  containerClassName?: string;
  innerClassName?: string;
  innerStyles?: any;
  dragToClose?: boolean;
  zMax?: boolean;
  fullHeight?: boolean;
  zIndex?: number;
  closableOutside?: boolean;
  /** This will add a `preventDefault` on the modal, in case there's a trigger we don't want to trigger */
  preventThroughClick?: boolean;
  /** Stop event propagation when mousedown event it's fired on the container of the modal. Added due to issues with Kanpla Select component */
  stopPropagation?: boolean;
  /** Optionally, pass a container you want to render this modal in */
  portalContainer?: HTMLElement;
}

/** Globally share the modal state (useful to prevent handlers from being triggered. @see Popper */
export const isModalOpen = atom<boolean>(false);

export const ModalWrapper = (props: Props) => {
  const {
    children,
    open,
    setOpen,
    containerClassName,
    innerClassName = "",
    innerStyles = {},
    zMax,
    fullHeight = false,
    zIndex = 40,
    closableOutside = true,
    preventThroughClick,
    stopPropagation = true,
    portalContainer,
  } = props;

  const setModalOpen = useSetAtom(isModalOpen);

  const windowEl = useRef<HTMLDivElement>(null);
  const containerEl = useRef<HTMLDivElement>(null);

  useEscapeKeyPress(
    closableOutside && open ? () => setOpen(false) : () => null
  );

  const detectClick = (e: MouseEvent) => {
    if (stopPropagation) e.stopPropagation();

    if (preventThroughClick) e.preventDefault();
    const eventTarget = e.target as HTMLElement;

    const scrollbar =
      e.offsetX > eventTarget.clientWidth ||
      e.offsetY > eventTarget.clientHeight;

    const isOutside =
      // @ts-ignore
      windowEl?.current && !windowEl?.current?.contains(e.target);
    const closeIfOutside = closableOutside && isOutside;

    if (!scrollbar && closeIfOutside && open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const element = containerEl.current;
    if (!element) return;

    (element as HTMLElement).addEventListener("mousedown", detectClick, false);
    return () => {
      (element as HTMLElement).removeEventListener(
        "mousedown",
        detectClick,
        false
      );
    };
  }, [containerEl, open]);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const overlayTransitions = useTransition(open, {
    config: config.slow,
    from: { opacity: 0 },
    enter: { opacity: 0.4 },
    leave: { opacity: 0, pointerEvents: "none" },
  });

  const customConfig = { mass: 3, tension: 450, friction: 40 };

  const modalTransitions = useTransition(open, {
    config: customConfig,
    from: { opacity: 0, scale: 0.95, y: 20 },
    enter: { opacity: 1, scale: 1, y: 0 },
    leave: { opacity: 0, scale: 0.75, y: 20 },
  });

  const containerClasses = classNames(
    "fixed inset-0 overflow-x-hidden",
    fullHeight ? "h-full" : "",
    open
      ? "pointer-events-auto overflow-y-scroll"
      : "pointer-events-none overflow-y-hidden",
    containerClassName
  );

  return (
    <Portal container={portalContainer || undefined}>
      <>
        {overlayTransitions(
          (props, item) =>
            item && (
              <animated.button
                style={{ ...props, zIndex: zMax ? "999" : zIndex }}
                aria-label="close modal"
                className={`block bg-text-primary inset-0 fixed opacity-50 w-full cursor-default`}
              />
            )
        )}

        <div
          ref={containerEl}
          style={{ ...props, zIndex: zMax ? "999" : zIndex }}
          className={containerClasses}
        >
          {modalTransitions(
            ({ opacity, y }, item) =>
              item && (
                <animated.div
                  ref={windowEl}
                  style={{
                    opacity: opacity,
                    transform: y.to((y) => `translate3d(0,${y}px,0)`),
                    transformOrigin: "50% 50% 0",
                    height: fullHeight && "100%",
                    ...innerStyles,
                  }}
                  className={innerClassName}
                >
                  {children}
                </animated.div>
              )
          )}
        </div>
      </>
    </Portal>
  );
};
