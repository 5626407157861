type KeyboardOffset =
  | -220
  | -120
  | -100
  | -90
  | -80
  | -70
  | -60
  | -50
  | -40
  | -30
  | -20
  | -10
  | 0
  | 10
  | 20;

/**
 * Set an arbitrary offset value on the native keyboard (defaults to -90), useful when the keyboard hovers inputs.
 * See usage example in `lib/ui/BottomDrawer/index.tsx`
 * @param offset The space between the top of the user's screen and the native keyboard.
 *
 * As a general rule, the lesser the space, the higher the offset
 * @returns
 */
export const setNativeKeyboardOffset = (offset: KeyboardOffset) => {
  try {
    if (typeof window === undefined || !window?.["isRenderedInWebView"]) return;

    window?.["ReactNativeWebView"]?.postMessage(
      JSON.stringify({ keyboardOffset: offset })
    );
  } catch (err) {
    console.error(err);
  }
};
