import { useHasPublicSchools } from "apps/frontend/lib/signup/useHasPublicSchools";
import BasicLogin from "./BasicLogin";
import MultiLogin from "./MultiLogin";

type LoginContentProps = {
  isInModal?: boolean;
};

const LoginContent = ({ isInModal = false }: LoginContentProps) => {
  const { hasPublicSchools } = useHasPublicSchools();

  if (hasPublicSchools) {
    return <BasicLogin isInModal={isInModal} />;
  }

  return <MultiLogin isInModal={isInModal} />;
};

export default LoginContent;
