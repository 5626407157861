import { deconstructSlugs } from "@kanpla/system";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import SVG from "react-inlinesvg";
import { Logo } from "..";

interface Props {
  type?: "default" | "inverted" | "white";
  className?: string;
  /** Overrides secondary colors to match primary */
  monotone?: boolean;
  style?: React.CSSProperties;
  logoStyle?: React.CSSProperties;
  centered?: boolean;
  schoolId?: string;
  /** Allows to check if the logo should use true color (no CSS filters) but in some cases it shouldn't be allowed anyway because of components with colored background */
  allowKeepTrueColorCheck?: boolean;
  /** Get horizonal version of the logo, if existing. Defaults to `false` */
  horizontal?: boolean;
}

/** WORKS ONLY ON FRONTEND! */
export const NewLogo = ({
  type = "default",
  className = "",
  monotone,
  style = {},
  centered = true,
  schoolId: customSchoolId,
  allowKeepTrueColorCheck = true,
  logoStyle = {},
  horizontal = false,
}: Props) => {
  // Load schoolId from url
  const router = useRouter();
  const slugs = router.query?.slugs as unknown as Array<string>;
  const { schoolId: slugSchoolId } = deconstructSlugs({
    slugs,
  });

  const schoolId = useMemo(
    () => customSchoolId || slugSchoolId,
    [customSchoolId, slugSchoolId]
  );

  return (
    <SVG
      src={
        schoolId
          ? `/api/logo?schoolId=${schoolId}&format=svg&horizontal=${horizontal}`
          : `/api/logo?format=svg&horizontal=${horizontal}`
      }
      height="auto"
      title="Logo"
      className={`logo-${type} ${monotone ? "monotone" : ""} ${className}`}
      style={{
        width: "100%",
        height: "100%",
        maxHeight: 120,
        maxWidth: 120,
        ...style,
      }}
      onError={() => null}
    >
      <div
        className={classNames(
          `logo-${type} ${monotone ? "monotone" : ""}`,
          "w-full h-full flex justify-center items-center",
          className
        )}
        style={{
          height: 120,
          width: 120,
          filter: monotone ? "brightness(0)" : "brightness(1)",
          ...style,
        }}
      >
        <Logo
          center={centered}
          dark={type !== "default"}
          white={type === "white"}
          schoolId={schoolId}
          allowKeepTrueColorCheck={allowKeepTrueColorCheck}
          style={logoStyle}
          horizontal={horizontal}
        />
      </div>
    </SVG>
  );
};
