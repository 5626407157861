import { isEqual, pickBy } from "lodash";
import mixpanel from "mixpanel-browser";
import { useEffect, useRef } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

export const initMixpanel = () => {
  mixpanel.init("94c362d513fd6b58a9412d096400a473", { debug: true });
};

export const Mixpanel = () => {
  const { user, childId, schoolId, moduleId } = useContainer(AppContext);

  // User
  const userId = user?.id;
  const email = user?.email;
  useEffect(() => {
    try {
      if (userId) {
        initMixpanel();
        mixpanel.identify(userId);
        mixpanel.people.set({ email });
      } else {
        mixpanel.disable();
      }
    } catch (err) {
      console.log("Mixpanel init error: ", err);
    }
  }, [userId, email]);

  const trackedProps = { childId, schoolId, moduleId };
  const trigger = JSON.stringify(trackedProps);

  const prevTrackedProps = useRef({});

  useEffect(() => {
    if (!userId) return;

    try {
      // 1. Register all new values
      mixpanel.register(trackedProps);

      // 2. Compare to last values to extract the ones that changed
      prevTrackedProps.current = trackedProps;
      const changedValues = pickBy(
        trackedProps,
        (v, k) => !isEqual(prevTrackedProps?.[k], v)
      );

      // 3. For each changed value, send an event to Mixpanel
      Object.keys(changedValues).forEach((name) => {
        mixpanel.track(`Set ${name}`);
      });
    } catch (err) {
      console.log("Mixpanel track error: ", err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]); //run this code when the value of count changes

  return null;
};

export default Mixpanel;
