import {
  db,
  getDeveloperVariables,
  getPartnerUrl,
  getSupplier,
  useLocationHostname,
} from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import Image from "next/image";
import React, { useCallback, useEffect, useState } from "react";
import { maskedURLs } from "../../../system/src/hooks/useLocationHostname";
import { KanplaLogo } from "./KanplaLogo";

interface Props {
  /** @deprecated */
  color?: string;
  onlyIcon?: boolean;
  dark?: boolean;
  white?: boolean;
  style?: React.CSSProperties;
  /** Used to have custom logo from backend where we can't get it from the domain */
  partnerUrl?: string;
  /** @deprecated */
  supplier?: any;
  center?: boolean;
  /** @deprecated */
  vertical?: boolean;
  /** @deprecated */
  className?: string;
  /** If the branding is setup on a specific school */
  schoolId?: string;
  /** Allows to check if the logo should use true color (no CSS filters) but in some cases it shouldn't be allowed anyway because of components with colored background */
  allowKeepTrueColorCheck?: boolean;
  /** Get horizonal version of the logo, if existing. Defaults to `false` */
  horizontal?: boolean;
}

export const Logo = (props: Props) => {
  const {
    partnerUrl,
    dark,
    white,
    center = true,
    schoolId,
    style,
    allowKeepTrueColorCheck = true,
    horizontal = false,
  } = props;

  const hostname = useLocationHostname({});

  const [supplier, setSupplier] = useState<Supplier>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const keepTrueColor =
    (horizontal
      ? supplier?.custom?.horizontalIcon?.keepTrueColor
      : supplier?.custom?.icon?.keepTrueColor) ?? false;

  const getCorrectSupplier = useCallback(async () => {
    try {
      setLoading(true);

      const url =
        getDeveloperVariables()?.supplierUrl || partnerUrl || hostname;
      const correctPartnerUrl = getPartnerUrl({
        hostname: maskedURLs[url] || url,
      });

      const _supplier = await getSupplier({
        db,
        schoolId,
        url: correctPartnerUrl,
      });

      setSupplier(_supplier);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [hostname, partnerUrl, schoolId]);

  const getCorrectLogo = () => {
    const url = supplier?.custom?.icon?.url;

    if (url) {
      const requestLogo = schoolId
        ? `/api/logo?schoolId=${schoolId}&format=png&horizontal=${horizontal}`
        : `/api/logo?format=png&horizontal=${horizontal}`;

      return requestLogo;
    }

    return supplier?.logo?.trim() || supplier?.logoUrl;
  };

  useEffect(() => {
    getCorrectSupplier();
  }, [getCorrectSupplier]);

  if (loading) return null;

  if (
    !supplier ||
    isEmpty(supplier) ||
    (!supplier?.custom?.icon?.url && !supplier?.logo)
  )
    return <KanplaLogo {...props} />;

  const logoImage = getCorrectLogo();

  if (!logoImage?.includes("https://"))
    return (
      <img
        src={logoImage}
        style={style}
        alt={`${supplier?.name || "App"} logo`}
        className={classNames(
          "h-auto w-full max-h-full min-h-[2rem] max-w-full object-contain",
          {
            "brand-icon": !(allowKeepTrueColorCheck && keepTrueColor),
          },
          { dark: dark },
          { white: white },
          center ? "object-center" : "object-left"
        )}
      />
    );

  return (
    <Image
      src={logoImage}
      style={style}
      alt={`${supplier?.name || "App"} logo`}
      className={classNames(
        "max-h-full min-h-[2rem] max-w-full object-contain",
        {
          "brand-icon": !(allowKeepTrueColorCheck && keepTrueColor),
        },
        { dark: dark },
        { white: white },
        center ? "object-center" : "object-left"
      )}
    />
  );
};

export default Logo;
