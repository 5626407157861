import {
  faChevronDown,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectTypes } from "@kanpla/types";
import classNames from "classnames";
import React from "react";

const DropdownIndicator: SelectTypes.DropdownIndicator = ({
  innerProps,
  isFocused,
  isSearchable,
  isLoading,
}: SelectTypes.DropdownIndicatorProps) => {
  const icon = isSearchable && isFocused ? faMagnifyingGlass : faChevronDown;

  return (
    <div
      {...innerProps}
      className={classNames(
        "text-sm transition-transform ease-linear",
        !isSearchable && isFocused ? "rotate-180" : "rotate-0",
        {
          "text-gray-500": !isFocused,
          hidden: isLoading,
          block: !isLoading,
        }
      )}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

export default DropdownIndicator;
