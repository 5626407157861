import { faCcApplePay } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isApplePaySupported, useT } from "@kanpla/system";
import { Card, PaymentProvider } from "@kanpla/types";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import PaymentRow from "./PaymentRow";

interface Props {
  onClick: (val: PaymentProvider) => void;
  method: PaymentProvider | null;
  card: Card;
}

const PaymentRowsWrapper = (props: Props) => {
  const { onClick, card, method } = props;
  const t = useT();
  const { allowMobilePay } = useContainer(OrderingContext);

  const [selected, setSelected] = useState<PaymentProvider>(method || "card");

  const hasApplePay = isApplePaySupported();

  const getCardBrandName = (type: string) => {
    if (!card) return t("Card");

    switch (type) {
      case "visa":
        return "Visa";
      case "mc":
        return "Mastercard";
      case "amex":
        return "American Express";
      case "dankort":
        return "Dankort";
      default:
        return t("Default card");
    }
  };

  return (
    <div className="w-full h-full">
      {allowMobilePay && (
        <PaymentRow
          name="Mobile Pay"
          value="mobilepay"
          image="MPay"
          selected={selected}
          onChange={(val: PaymentProvider) => {
            setSelected(val);
            onClick(val);
          }}
        />
      )}
      {hasApplePay && (
        <PaymentRow
          name="Apple Pay"
          value="applepay"
          imageComponent={<FontAwesomeIcon icon={faCcApplePay} size="2x" />}
          selected={selected}
          onChange={(val) => {
            setSelected(val);
            onClick(val);
          }}
        />
      )}
      <PaymentRow
        name={getCardBrandName(card?.metadata?.brand)}
        imageComponent={<FontAwesomeIcon icon={faCreditCard} size="2x" />}
        value="card"
        detail={
          card ? t("ends with {value}", { value: card.metadata.last4 }) : null
        }
        selected={selected}
        onChange={(val) => {
          setSelected(val);
          onClick(val);
        }}
      />
    </div>
  );
};

export default PaymentRowsWrapper;
