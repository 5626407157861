import { School, Supplier } from "@kanpla/types";
import { Firestore } from "firebase-admin/firestore";
import { orderBy } from "lodash";
import { fetchCollection } from "../firestore/fetchCollection";
import { fetchDocument } from "../firestore/fetchDocument";

interface GetSupplierArgs {
  /** Database provider for fetching documents */
  db: Firestore;
  /** Url of the app being loaded, used for loading default supplier */
  url?: string;
  /** Request suppliers for a specific school */
  schoolId?: string;
  /** Optionally provide partnerId to skip querying for it */
  partnerId?: string;
}

export const getSupplier = async ({
  db,
  schoolId,
  partnerId: partner_id,
  url = "—",
}: GetSupplierArgs) => {
  // 0. Get partnerId from schoolId
  const getPartnerIdFromSchoolId = async () => {
    if (!schoolId) return null;
    const school = await fetchDocument<School>(
      db.collection("schools").doc(schoolId),
      false,
      { allowUnknown: true }
    );
    return school?.partnerId;
  };
  const partnerId = partner_id || (await getPartnerIdFromSchoolId()) || null;

  // 1. Fetch suppliers both by 'url' and 'partnerId' of the school
  const fetchSuppliersBy = (key: string, value: string | undefined) =>
    value
      ? fetchCollection<Supplier>(
          db.collection("suppliers").where(key, "==", value)
        )
      : ([] as Supplier[]);

  const res = await Promise.all([
    fetchSuppliersBy("url", url),
    fetchSuppliersBy("partnerId", partnerId || undefined),
  ]);
  const allSuppliers = res.flat();

  // 2. If schoolId provided, filter by scope
  const filteredSuppliers = allSuppliers.filter(
    (s) =>
      !s.scope || (schoolId && s?.scope?.generatedSchoolIds?.includes(schoolId))
  );
  const finalSuppliers = schoolId ? filteredSuppliers : allSuppliers;

  // 3. Get most relevant branding
  // Smaller `generatedSchoolIds` means location is more specific
  const targetSpecificLocation = orderBy(
    finalSuppliers,
    (item) => item?.scope?.generatedSchoolIds?.length || Infinity,
    schoolId ? "asc" : "desc"
  );

  return targetSpecificLocation[0];
};
