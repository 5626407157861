import { Language } from "@kanpla/types";
import { createNativeInstance, t, tx } from "@transifex/native";
import { T, useT, useLocale as useTXLocale } from "@transifex/react";

// initialize
tx.init({
  token: "1/ea4ee311c73bda3f503526312d898bda9079baa0",
});

/**
 * Used for server-side functions to ensure
 * the language is fetched and cached before usage.
 * @param locale user's language or defaults to danish
 * @returns locale
 */
async function getLocale(locale: Language = "da") {
  await tx.setCurrentLocale(locale);
  return locale;
}

/**
 * Wrapper to type Transifex's translateLocale function
 * @param locale locale to translate to
 * @param text text to translate
 * @returns translated text
 */
function translateLocale(locale: Language, text: string) {
  return tx.translateLocale(locale, text) as string;
}

//Initialize Transifex for Print
const txPrint = createNativeInstance();
txPrint.init({
  token: "1/ea4ee311c73bda3f503526312d898bda9079baa0",
});

const useLocale = useTXLocale as () => Language;

export { T, getLocale, t, translateLocale, tx, txPrint, useLocale, useT };
