import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@kanpla/system";
import classNames from "classnames";
import React, { InputHTMLAttributes, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useMergeRefs } from "rooks";
import { useCurrencySymbol } from "../../prices/useCurrencySymbol";
import { BaseInputProps } from "./BaseInput";

export interface NumberProps
  extends Omit<BaseInputProps, "value" | "type" | "onChange"> {
  /** Maximum allowed value */
  max?: number;
  /** Minimum allowed value */
  min?: number;
  value?: number;
  onChange?: (value: number) => void;
  /** Adds a text prefix before the input (useful to specify currencies or systems) */
  prefix?: React.ReactNode;
}

type OmittedHTMLInput = Partial<
  Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "max" | "min" | "type" | "value" | "onChange" | "prefix"
  >
>;

type InputNumberProps = NumberProps & OmittedHTMLInput;

export const Number: React.FC<InputNumberProps> = React.forwardRef<
  HTMLInputElement,
  InputNumberProps
>((props, ref): JSX.Element => {
  const {
    max = Infinity,
    min = 0,
    value,
    onChange,
    className = "",
    disabled = false,
    readOnly = false,
    label = null,
    dataCy,
    color = "primary",
    style = {},
    error = false,
    required,
    placeholder,
    prefix = "",
    ...rest
  } = props;

  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile || (screenWidth || 0) < 768;

  const baseInputRef = useRef<HTMLInputElement>(null);
  const inputRefs = useMergeRefs(ref, baseInputRef);

  const currencySymbol = useCurrencySymbol();

  const onChangeHandler = (value: number) => {
    if (value < min) return onChange?.(min);
    if (value > max) return onChange?.(max);
    return onChange?.(value);
  };

  const classes = classNames(
    // bg-input-primary, bg-input-secondary
    "relative border border-divider-main shadow-inner",
    `bg-input-${color} text-text-${color} mt-3 md:mt-0 select-none rounded-md h-14 md:h-11 flex items-center outline-${
      error ? "danger" : color
    }-focus border-${error ? "danger" : color}-focus`,
    { "border-danger-main": error },
    { "opacity-50": disabled },
    className
  );

  const decreaseIncreaseButtons = (
    <div className="h-full flex flex-col border-l border-divider-main overflow-hidden">
      <button
        type="button"
        className={classNames(
          `outline-none active:scale-110 px- md:pl-2 md:pr-3 rounded-tr-md h-1/2 py-0 bg-input-${color} shadow-inner border-b border-divider-main transition-all ease-in-out duration-200 transform origin-bottom hover:shadow-none group hover:translate-y-1 disabled:text-text-disabled disabled:opacity-30 disabled:pointer-events-none disabled:cursor-not-allowed`
        )}
        onClick={() => onChangeHandler((value || 0) + 1)}
        disabled={(value || 0) >= max}
      >
        <FontAwesomeIcon
          icon={faChevronUp}
          className=" text-text-secondary group-hover:text-primary-main"
          size="2xs"
        />
      </button>
      <button
        type="button"
        className={classNames(
          `outline-none active:scale-110 px-3 md:pl-2 md:pr-3 rounded-br-md h-1/2 py-0 bg-input-${color} hover:shadow-none group hover:-translate-y-1 hover:border-t hover:border-divider-main shadow-inner transition-all ease-in-out duration-200 transform origin-top disabled:text-text-disabled disabled:opacity-30 disabled:pointer-events-none disabled:cursor-not-allowed`
        )}
        onClick={() => onChangeHandler((value || min) - 1)}
        disabled={(value || min) <= min}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          className="text-text-secondary group-hover:text-primary-main"
          size="2xs"
        />
      </button>
    </div>
  );

  if (mobile)
    return (
      <div
        className={classNames("w-full", classes)}
        style={style}
        onClick={() => baseInputRef.current?.focus()}
      >
        <div className={classNames("flex items-center w-full h-full")}>
          {prefix && (
            <div className="px-2 w-fit h-full text-sm flex text-text-secondary items-center border-r border-secondary-main">
              {prefix}
            </div>
          )}
          <div className={classNames("flex relative w-full h-full")}>
            <input
              type="number"
              placeholder={placeholder}
              data-cy={dataCy}
              disabled={disabled}
              readOnly={readOnly}
              className={classNames(
                `bg-input-${color}`,
                "peer w-full text-lg placeholder-transparent focus:placeholder-text-disabled pl-2 rounded-md",
                label ? "pt-3.5" : ""
              )}
              ref={inputRefs}
              max={max}
              min={min}
              value={value}
              onChange={(e) => onChangeHandler(parseInt(e.target.value) || 0)}
              {...rest}
            />
            {decreaseIncreaseButtons}
            {label && (
              <label
                className={classNames(
                  value ? "text-sm top-0" : "text-base top-4 font-medium",
                  "text-text-secondary absolute left-2 transition-all peer-focus:font-normal peer-focus:text-sm peer-focus:top-0"
                )}
              >
                {label}
                {required ? (
                  <span className="font-medium mr-0.5 text-danger-main">*</span>
                ) : null}
              </label>
            )}
          </div>
        </div>
      </div>
    );

  return (
    <div className={classNames("w-full py-1 mt-1 flex flex-col")} style={style}>
      {label && (
        <label className="form-label mb-2" htmlFor={rest.name}>
          {required ? (
            <span className="font-medium mr-0.5 text-danger-main">*</span>
          ) : null}
          {label}
        </label>
      )}
      <div className={classes} onClick={() => baseInputRef.current?.focus()}>
        <div className="px-4 w-fit h-full flex items-center text-text-secondary border-r border-divider-main">
          {prefix || currencySymbol}
        </div>
        <input
          type="number"
          placeholder={placeholder}
          data-cy={dataCy}
          disabled={disabled}
          readOnly={readOnly}
          className={`bg-input-${color} w-full px-3 rounded-md`}
          ref={inputRefs}
          max={max}
          min={min}
          value={value}
          onChange={(e) => onChangeHandler(parseInt(e.target.value) || 0)}
          {...rest}
        />
        {decreaseIncreaseButtons}
      </div>
    </div>
  );
});
