import { useT } from "@kanpla/system";
import { SelectTypes } from "@kanpla/types";
import React from "react";

const NoOptionsMessage: SelectTypes.NoOptionsMessage = ({
  innerProps,
  noOptionsContent = null,
  noOptionsIcon = null,
}: SelectTypes.NoOptionsMessageProps) => {
  const t = useT();

  return (
    <div
      {...innerProps}
      className="py-2 text-center text-gray-500 flex flex-col gap-y-1"
    >
      {noOptionsIcon}
      {noOptionsContent || t("Nothing found")}
    </div>
  );
};

export default NoOptionsMessage;
