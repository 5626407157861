import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ReactNode, useState } from "react";

interface Props {
  /** The type of the alert */
  type: "success" | "danger" | "warning" | "info";
  /** The title of the alert */
  title?: string;
  /** The message of the alert */
  message: string | ReactNode;
  /** If the alert can be closed */
  closable?: boolean;
  /** Hide the alert (useful if it should be shown again, usually with localstorage variables) */
  hidden?: boolean;
  className?: string;
  /** If the alert should wrap content or fill the whole width as is default */
  wrapContent?: boolean;
}

const alertIcons = {
  success: faCircleCheck,
  danger: faCircleXmark,
  warning: faCircleExclamation,
  info: faCircleInfo,
};

const Alert = (props: Props): JSX.Element | null => {
  const {
    type,
    title,
    message,
    closable = false,
    className = "",
    hidden: _hidden = false,
    wrapContent = false,
  } = props;

  const [hidden, setHidden] = useState(_hidden);

  // bg-success-backdrop, bg-warning-backdrop, bg-danger-backdrop, bg-info-backdrop
  // text-success-main, text-warning-main, text-danger-main, text-info-main

  if (hidden) return null;

  return (
    <div
      className={classNames(
        "rounded-md py-2 px-4  gap-x-3 relative",
        `bg-${type}-backdrop text-${type}-main`,
        title ? "" : "items-center",
        wrapContent ? "inline-flex" : "w-full flex",
        className
      )}
    >
      <FontAwesomeIcon
        icon={alertIcons[type]}
        className={classNames(`text-${type}-main`, title ? "mt-2" : "mt-0")}
      />
      <div className="flex flex-col justify-center">
        {title && (
          <h1 className={classNames("text-lg font-medium", closable && "mr-2")}>
            {title}
          </h1>
        )}
        <p className="text-md">{message}</p>
      </div>
      {closable && (
        <div className="absolute top-2 right-4">
          <FontAwesomeIcon
            icon={faXmark}
            className="hover:opacity-80 cursor-pointer"
            onClick={() => setHidden(true)}
          />
        </div>
      )}
    </div>
  );
};

export { Alert };
