import {
  calculateOrderDiscountAmount,
  calculateOrderVatBreakdown,
} from "@kanpla/system";
import { OrderLines } from "@kanpla/types";
import { usePriceFormatter, vatRateFormatter } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { uniq } from "lodash";
import { useOrderDiscounts } from "../../mealplan2/basket/lib/useOrderDiscounts";
import { basketPaidAmountAtom, basketResultedPriceAtom } from "./useBasket";

interface UseOrderPriceBreakdownArgs {
  orderLines: OrderLines;
}

/** Breaks down the price for the current order in the basket */

export const useOrderPriceBreakdown = ({
  orderLines,
}: UseOrderPriceBreakdownArgs) => {
  const discounts = useOrderDiscounts();

  const paidAmount = useAtomValue(basketPaidAmountAtom);
  const resultedPrice = useAtomValue(basketResultedPriceAtom);

  const { withoutVat, vat, withVat } = calculateOrderVatBreakdown(orderLines);
  const totalDiscountAmount = calculateOrderDiscountAmount(discounts, false);

  const vatRates = uniq(orderLines.map((l) => l.vatRate));
  const vatRatesDisplay = vatRates.map((r) => vatRateFormatter(r)).join(", ");

  const totalExcludingPriceFormatted = usePriceFormatter(withoutVat);
  const totalVatAmountFormatted = usePriceFormatter(vat);
  const totalDiscountFormatted = usePriceFormatter(totalDiscountAmount);
  const totalPriceFormatted = usePriceFormatter(withVat - totalDiscountAmount);
  const paidAmountFormatted = usePriceFormatter(paidAmount);

  const resultedPriceDiscounted = resultedPrice - totalDiscountAmount;
  const resultedPriceFormatted = usePriceFormatter(resultedPriceDiscounted);

  return {
    totalExcludingPriceFormatted,
    totalVatAmountFormatted,
    totalDiscountFormatted,
    totalPriceFormatted,
    paidAmountFormatted,
    vatRatesDisplay,
    resultedPriceFormatted,
    totalDiscountAmount,
    totalPrice: withVat - totalDiscountAmount,
    orderDiscounts: discounts,
  };
};
