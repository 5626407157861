import { faBuildings, faSchool } from "@fortawesome/pro-duotone-svg-icons";
import { useT } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { AppContext } from "apps/frontend/components/contextProvider";
import { useSetAtom } from "jotai";
import { useContainer } from "unstated-next";
import PageWrapper from "../../PageWrapper";
import ChooseCard from "../../components/ChooseCard";
import { currentPageAtom } from "../../lib/usePageNavigation";
import { onlyCompanySignupAtom } from "../../lib/useSubmitEmail";

const SchoolOrCompany = () => {
  const { supplier } = useContainer(AppContext);

  const setOnlyCompanySignup = useSetAtom(onlyCompanySignupAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);

  const t = useT();

  const ExtraContent = () => (
    <div className={`text-center mt-4`}>
      <p className="mb-2">{t("Don't have a company email?")}</p>
      <Button
        id="signupGoWithoutCompanyEmail"
        className="mt-1 px-10"
        onClick={() => {
          setCurrentPage("canteenId");
        }}
        type="primary"
        shape="soft"
        dataCy="btn-login-canteen-id"
      >
        {t("Login with your Canteen ID")}
      </Button>
    </div>
  );

  const appName = supplier?.name || supplier?.appName;

  return (
    <PageWrapper
      title={t("Where will you use {name}?", {
        name: appName ? appName : "Kanpla",
      })}
      subtitle={t("Select the location best suited for your canteen")}
      hideButton
      hideAnonymousAuth
      extraContent={<ExtraContent />}
    >
      <div className="flex justify-center w-full gap-x-6 pt-4">
        <ChooseCard
          title={t("Educational")}
          icon={faSchool}
          onClick={() => setCurrentPage("salesplace")}
          dataCy="signup-school-btn"
        />
        <ChooseCard
          title={t("Workplace")}
          icon={faBuildings}
          onClick={() => {
            setOnlyCompanySignup(true);
            setCurrentPage("email");
          }}
          dataCy="signup-company-btn"
        />
      </div>
    </PageWrapper>
  );
};

export default SchoolOrCompany;
