import {
  faBellSlash,
  faMessageSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";

interface Props {
  type: "popup" | "message";
}

const EmptyView = (props: Props) => {
  const { type } = props;
  const t = useT();

  const isMessage = type === "message";

  return (
    <div className="flex flex-col w-full h-full justify-center items-center mt-2">
      <p className="w-full text-text-disabled text-xs px-4 pb-2 text-center">
        <FontAwesomeIcon
          icon={isMessage ? faBellSlash : faMessageSlash}
          size="sm"
          className="mr-2"
        />
        {isMessage
          ? t("You don't have any recent notifications.")
          : t("You don't have any recent popup.")}
      </p>
    </div>
  );
};

export default EmptyView;
