import { atom, useAtom, useAtomValue } from "jotai";
import { isEmpty } from "lodash";
import { useContainer } from "unstated-next";

import {
  calculateProductPrice,
  getAllowanceMaxAmount,
  getAllowancesForProduct,
  isAllowanceOverForProduct,
} from "@kanpla/system";
import {
  AllowanceUsage,
  CombinedOfferItem,
  CustomOrderContent,
  OrderLines,
} from "@kanpla/types";

import { OrderingContext, initialAllowanceUsagesAtom } from "../../context";

export const allowanceUsageAtom = atom<AllowanceUsage[]>([]);

type UpdateWithProductType = {
  amount: number;
  product: CombinedOfferItem;
  /** Variants */
  options?: CustomOrderContent["optionChoices"];
  useDefaultAllowance?: boolean;
};

/**
 * Source of truth for allowances in the current module and school. (manages the products in the basket)
 */
export const useAllowance = () => {
  const [allowanceUsages, setAllowanceUsages] = useAtom(allowanceUsageAtom);
  const initialAllowanceUsages = useAtomValue(initialAllowanceUsagesAtom);

  const { module, items } = useContainer(OrderingContext);

  // Get the max allowance for a specific product
  const getMaxAllowanceForProduct = (product: CombinedOfferItem) => {
    const allowances = getAllowancesForProduct({
      productId: product.id,
      allowanceUsages,
    });

    const productPrice = calculateProductPrice({ product, module });

    return getAllowanceMaxAmount({
      productPrice,
      allowanceUsages: allowances.filter((a) => !a?.discountId),
    });
  };

  // Check if a target product should be disabled based on the current allowances
  const shouldDisableProduct = (product: CombinedOfferItem) =>
    isAllowanceOverForProduct({
      product,
      allowanceUsages: allowanceUsages.filter((a) => !a?.discountId),
      module,
    });

  // Update the allowance usages based on a specific product (e.g. adding a new product to the basket)
  const updateFromProduct = ({
    amount,
    product,
    useDefaultAllowance = false,
    options = {},
  }: UpdateWithProductType) => {
    const productPrice = calculateProductPrice({ product, module, options });

    const allowancesForProduct = getAllowancesForProduct({
      productId: product.id,
      allowanceUsages,
    });

    if (isEmpty(allowancesForProduct)) return;

    setAllowanceUsages((prev) => {
      const productAllowanceIds = allowancesForProduct.map((a) => a?.id);
      const allowances = useDefaultAllowance ? initialAllowanceUsages : prev;

      const mapped = allowances.map((allowance) => {
        if (allowance.isAllowanceOver) return allowance;
        if (!productAllowanceIds.includes(allowance?.id)) return allowance;

        if (allowance?.unitPrice) {
          const diff = allowance?.unitPrice.amount - productPrice * amount;

          return {
            ...allowance,
            isAllowanceOver: diff <= 0,
            unitPrice: {
              ...allowance?.unitPrice,
              amount: diff,
            },
          };
        }

        if (allowance?.numberOfProducts) {
          const diff = allowance.numberOfProducts.amount - amount;

          return {
            ...allowance,
            isAllowanceOver: diff <= 0,
            numberOfProducts: {
              ...allowance?.numberOfProducts,
              amount: diff,
            },
          };
        }

        return allowance;
      });

      return mapped;
    });
  };

  // Update the allowance usages when editing items in the basket
  const updateFromBasket = (basket: OrderLines) => {
    if (isEmpty(basket)) {
      return setAllowanceUsages(initialAllowanceUsages);
    }

    basket.forEach((orderLine) => {
      const { amount, productId, options } = orderLine;

      const product = items.find((item) => item.productId === productId);
      if (!product) return;

      updateFromProduct({
        amount,
        product,
        useDefaultAllowance: true,
        options,
      });
    });
  };

  return {
    allowanceUsages,
    setAllowanceUsages,
    updateFromProduct,
    getMaxAllowanceForProduct,
    shouldDisableProduct,
    updateFromBasket,
  };
};
