import { useEffect, useState } from "react";
// import { getDeveloperVariables } from "@kanpla/system";

interface Props {
  defaultValue?: string | null;
}

export const maskedURLs: { [url: string]: string } = {
  "app.madklubben.dk": "506.kanpla.dk",
  "app.eaty.nu": "506.kanpla.dk",
  "mono-frontend-git-five-dev-kanpla.vercel.app": "506.kanpla.dk",
  "app.foodbycoor.dk": "coor.kanpla.dk",
  "app.kokkeneskoekken.dk": "kokkeneskoekken.kanpla.dk",
  "app.cheval-blanc.dk": "chevalblanc.kanpla.dk",
  "app.dugurdkantiner.no": "dugurd.kanpla.dk",
  "app.blueapplecatering.co.uk": "blueapple.kanpla.dk",
  "app.normacatering.com": "norma.kanpla.dk",
  "app.thomasfranks.com": "thomasfranks.kanpla.dk",
  "app.the-finch.kanpla.io": "thefinch.kanpla.dk",
  "thefinch.kanpla.io": "thefinch.kanpla.dk",
  "app.genuinedining.com": "genuinedining.kanpla.dk",
  "app.food.ability.no": "ability.kanpla.dk",
  "foodcompany.kanpla.dk": "thefoodcompany.kanpla.dk",
  "app.impactfood.co.uk": "ifg.kanpla.dk",
  "app.torvekoekken.dk": "torvekoekken.kanpla.dk",
  "app.meyers.dk": "meyers.kanpla.dk",
};

export const getLocationHostname = (processeableHostname?: string): string => {
  if (processeableHostname)
    return maskedURLs[processeableHostname] || processeableHostname;

  if (typeof window === "undefined") return "app.kanpla.dk";

  const fromWindow = window.location.hostname;
  const fromStorage = window?.sessionStorage?.getItem("kanplaLocationHostname");

  // const supplierUrl = getDeveloperVariables()?.supplierUrl;

  const url =
    // maskedURLs[supplierUrl || fromWindow] ||
    // supplierUrl ||
    maskedURLs[fromWindow] || fromStorage || fromWindow;

  return url;
};

export const useLocationHostname = ({ defaultValue = "kanpla.dk" }: Props) => {
  const [hostname, setHostname] = useState(
    typeof window === "undefined"
      ? defaultValue
      : window?.sessionStorage?.getItem("kanplaLocationHostname")
  );

  const url = getLocationHostname();
  useEffect(() => {
    setHostname(url);
  }, [url]);

  return hostname;
};
