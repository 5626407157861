import { SelectTypes } from "@kanpla/types";
import classNames from "classnames";
import React from "react";

const Option: SelectTypes.Option = ({
  innerProps,
  innerRef,
  children,
  isSelected,
  isDisabled,
  isFocused,
  setIsHovered,
}: SelectTypes.OptionProps) => {
  const classes = classNames(
    "w-full py-2 px-3 truncate transition-all ease-in-out first:rounded-t-md last:rounded-b-md",
    isDisabled
      ? "cursor-not-allowed text-text-disabled select-none"
      : "text-text-primary cursor-pointer active:bg-primary-light",
    {
      "font-semibold !bg-primary-light !text-primary-contrast":
        isSelected && !isDisabled,
    },
    { "hover:bg-secondary-main": isFocused && !isSelected && !isDisabled }
  );

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classes}
      onMouseEnter={() => setIsHovered?.(false)}
      onMouseLeave={() => setIsHovered?.(false)}
    >
      {children}
    </div>
  );
};

export default Option;
