import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, useT } from "@kanpla/system";
import { Module, _FrontendModule } from "@kanpla/types";
import { Input, Popper, ProductSettingsHeader } from "@kanpla/ui";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

interface Props {
  value?: string;
  onChange?: (newReference: string) => void;
  defaultReference?: string;
  module: Module | _FrontendModule | null;
  hideHeader?: boolean;
  noExtraMargin?: boolean;
}

interface AvailableReferencesProps {
  refs: Array<string>;
  onSet: (newRef: string) => void;
}

const AvailableReferences = ({ refs, onSet }: AvailableReferencesProps) => {
  const [open, setOpen] = useState<boolean>(false);

  if (!refs || isEmpty(refs)) return null;

  return (
    <Popper
      open={open}
      setOpen={setOpen}
      actionElement={
        <button className="bg-gray-300 rounded-md px-2 py-0.5" type="button">
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      }
      withPadding={false}
      className="py-1 pb-2"
      lockOnOpen
      margin={1}
    >
      <>
        <h3 className="text-important !font-semibold mb-2 px-3 pt-2">
          <T _str="Add a predefined reference" />
        </h3>
        <ul>
          {refs.map((ref, key) => (
            <li
              key={`invoice-refs-${ref}-${key}`}
              className={classNames("border-secondary-main", {
                "border-b": key !== refs.length - 1,
              })}
            >
              <button
                className="p-2 px-3 w-full text-left hover:bg-background-secondary focus:bg-background-secondary transition-colors"
                onClick={() => {
                  onSet(ref);
                  setOpen(false);
                }}
                type="button"
              >
                {ref}
              </button>
            </li>
          ))}
        </ul>
      </>
    </Popper>
  );
};

export const InvoiceReference = ({
  defaultReference,
  module,
  hideHeader = false,
  noExtraMargin = false,
  value,
  onChange = () => null,
}: Props) => {
  const t = useT();

  const availableReferences =
    module?.plugins?.invoiceReference?.availableReferences;

  const title =
    module?.plugins?.invoiceReference?.title ||
    t("Reference to company acquisition");

  const description =
    module?.plugins?.invoiceReference?.description ||
    t(
      "The reference is automatically added to your order. You can always update your default reference or manually change it in the order."
    );

  const finalRequired = module?.plugins?.invoiceReference?.required ?? true;

  return (
    <div>
      {!hideHeader && (
        <ProductSettingsHeader
          title={title}
          subtitle={description}
          icon={faAddressCard}
          noBg
          noExtraMargin={noExtraMargin}
        />
      )}

      <InvoiceReferenceField
        value={value}
        onChange={onChange}
        availableReferences={availableReferences}
        defaultReference={defaultReference}
        required={finalRequired}
        noMargin={noExtraMargin}
      />
    </div>
  );
};

interface InvoiceReferenceFieldProps {
  onChange?: (newReference: string) => void;
  value?: string;
  availableReferences: string[];
  defaultReference: string;
  required?: boolean;
  noMargin?: boolean;
}

const InvoiceReferenceField = ({
  value,
  onChange,
  availableReferences,
  defaultReference,
  required,
  noMargin = false,
}: InvoiceReferenceFieldProps) => {
  const t = useT();

  const update = useCallback(
    (v: string) => {
      onChange(v);
    },
    [onChange]
  );

  useEffect(() => {
    if (defaultReference && defaultReference !== "" && !value && required) {
      return update(defaultReference);
    }
  }, [defaultReference, required, update, value]);

  return (
    <div className="relative mt-2">
      <Input
        className={classNames({ "mb-4": !noMargin })}
        placeholder={t("Add a reference")}
        value={value}
        onChange={(e) => update(e.target.value)}
        icon={
          <AvailableReferences
            refs={availableReferences}
            onSet={(d) => update(d)}
          />
        }
        iconRight
      />
    </div>
  );
};
