import { OrderLine, PriceExcludingVat, PriceIncludingVat } from "@kanpla/types";
import { round, sumBy } from "lodash";
import { calculateConfigTotal } from "../orders/calculateConfigTotal";

/**
 * calculateOrderVatBreakdown calculates all VAT properties for an order.
 * Use it in places where you need to display a full price breakdown
 */

export interface OrderInvoiceTotalResponse {
  withoutVat: PriceExcludingVat;
  vat: number;
  withVat: PriceIncludingVat;
}

type OrderLineForVat = Pick<
  OrderLine,
  "options" | "vatRate" | "amount" | "unitPrice"
>;

export const calculateOrderVatBreakdown = (
  orderLines: OrderLineForVat[] = [],
  /** If `decimalPrice` is selected, we want to return the value in decimals, not as unit price */
  decimalPrice?: boolean
): OrderInvoiceTotalResponse => {
  const mapped = orderLines.map((orderLine) => {
    const { options = {}, vatRate, amount, unitPrice = 0 } = orderLine;

    const totalConfigPrice = calculateConfigTotal(options);

    const withoutVat =
      (amount || 0) * ((unitPrice || 0) + (totalConfigPrice || 0));
    const withVat = withoutVat + withoutVat * (vatRate ?? 0.25);
    const vat = withoutVat * (vatRate ?? 0.25);

    if (decimalPrice)
      return {
        withoutVat: withoutVat / 100,
        withVat: withVat / 100,
        vat: vat / 100,
      };

    return {
      withoutVat,
      withVat,
      vat,
    };
  });

  return {
    withoutVat: round(sumBy(mapped, "withoutVat"), 2),
    vat: round(sumBy(mapped, "vat"), 2),
    withVat: round(sumBy(mapped, "withVat"), 2),
  };
};
