import { useT } from "@kanpla/system";
import { Spinner } from "@kanpla/ui";
import { useRouter } from "next/router";
import { useEffect } from "react";
import PageWrapper from "../../PageWrapper";
import { useSchool } from "../../lib/useSchool";

const Completed = () => {
  const t = useT();
  const router = useRouter();

  const { hasKanplaGo } = useSchool();

  const continueLink = hasKanplaGo
    ? "/app"
    : "/app?showIntroductionCarousel=true";

  // Go to the app immediately
  useEffect(() => {
    router.push(continueLink);
    // eslint-disable-next-line react-hooks-unreliable-deps/reference-deps
  }, [continueLink]);

  return (
    <PageWrapper hideButton title={t("Awesome - You're good to go!")}>
      <div className="w-full h-full flex justify-center items-center text-primary-main">
        <Spinner size="medium" useCurrentColor />
      </div>
    </PageWrapper>
  );
};

export default Completed;
